import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Promise } from 'bluebird';

import { getSyncLoading } from 'src/Redux/SyncLoading/Selectors';
import { getSync } from 'src/Redux/Sync/Selectors';
import { syncLoadingActions } from 'src/Redux/SyncLoading/Slice';
import { syncActions } from 'src/Redux/Sync/Slice';

import Toast from 'src/Components/Toast';
import Offline from 'src/Components/Offline';
import Modal from 'src/Components/Modal';

import {
  confirmRunSync,
  syncSuccessMsg,
  cxpSuccessMsg,
  cancelSync,
  syncEndWithErrorMsg,
  syncWithErrorMsg,
} from 'src/Utils/constants';

import {
  endUpdateEvent,
  insertCxpsMetaData,
  insertDocumentsMetaData,
  insertInfosUserMetaData,
  insertPresentationsMetaData,
  insertSheetsMetaData,
  insertTemplatesMetaData,
  loadFilesData,
  loadCommonFiles,
  startUpdateEvent,
  steps,
} from './syncUtilities';

import styles from './Sync.module.scss';
import backIcon from 'src/Assets/detail/fleche-back.svg';
import loaderIcon from 'src/Assets/loader_blue.gif';
import { getAuthState } from 'src/Redux/Auth/Selectors';

function Synchronization() {
  const [confirmGoBack, setConfirmGoBack] = useState<boolean>(false);

  const syncLoading = useSelector(getSyncLoading);
  const User = useSelector(getAuthState);
  const sync = useSelector(getSync);
  const dispatch = useDispatch();

  useEffect(() => {
    if (syncLoading && (syncLoading.showSync || User.compteChanged)) {
      startSync();
    } else {
      dispatch(
        syncLoadingActions.setShowAlertSync({
          showAlertSync: true,
        })
      );
    }
    return () => {
      startSync();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncLoading.showSync]);

  /**
   * launch sync
   */
  const startSync = async () => {
    try {
      dispatch(
        syncLoadingActions.setLoading({
          loading: true,
        })
      );
      const metaDataPromises = [
        startUpdateEvent(),
        insertCxpsMetaData(),
        insertInfosUserMetaData(),
        insertPresentationsMetaData(),
        insertDocumentsMetaData(),
        insertTemplatesMetaData(),
        insertSheetsMetaData(),
      ];
      let counter = 0;
      Promise.each(metaDataPromises, () => {
        return new Promise(resolve => {
          counter++;
          steps(counter, metaDataPromises.length);
          resolve(true);
        });
      })
        .then(_ => {
          loadFilesData()
            .then(_ => {
              loadCommonFiles().then(_ => {
                endUpdateEvent()
                  .then(_ => {
                    console.log('sync done');
                    dispatch(
                      syncLoadingActions.setLoading({
                        loading: false,
                      })
                    );
                    dispatch(
                      syncLoadingActions.setMessageSuccess({
                        messageSuccess: true,
                      })
                    );
                  })
                  .catch(() => {
                    console.log('endUpdate catch');

                    dispatch(
                      syncLoadingActions.setLoading({
                        loading: false,
                      })
                    );
                    dispatch(syncActions.syncEndWithError({ syncEndWithError: true }));
                    dispatch(
                      syncLoadingActions.endSync({
                        value: 100,
                      })
                    );
                  });
              });
            })
            .catch(error => {
              console.log('Error in files download:', error);
              dispatch(
                syncLoadingActions.setLoading({
                  loading: false,
                })
              );
              dispatch(syncActions.syncEndWithError({ syncEndWithError: true }));
              dispatch(
                syncLoadingActions.endSync({
                  value: 100,
                })
              );
            });
        })
        .catch(error => {
          console.log('Error in Meta data ', error);
          dispatch(syncActions.syncEndWithError({ syncEndWithError: true }));
          dispatch(
            syncLoadingActions.setLoading({
              loading: false,
            })
          );
          dispatch(
            syncLoadingActions.endSync({
              value: 100,
            })
          );
        });
    } catch (error) {
      dispatch(
        syncLoadingActions.setLoading({
          loading: false,
        })
      );
      console.log(error);
    }
  };

  /**
   * On end sync show message success or error
   */
  function renderMessage() {
    if (syncLoading && syncLoading.messageSuccess) {
      setTimeout(() => {
        dispatch(
          syncLoadingActions.setMessageSuccess({
            messageSuccess: false,
          })
        );
        window.location.href = '/';
      }, 5000);
      return sync.syncEndWithError ? (
        <Toast cta={false} message={`${syncEndWithErrorMsg}`} />
      ) : (
        <Toast cta={false} message={`${syncSuccessMsg}`} />
      );
    }
    return null;
  }
  /**
   * On add cxp show message success
   */
  function renderMessageCxp() {
    if (syncLoading && syncLoading.addedCxpMessage) {
      setTimeout(() => {
        dispatch(
          syncLoadingActions.setAddedCxp({
            addedCxpMessage: false,
          })
        );
      }, 5000);
      return <Toast cta={false} message={`${cxpSuccessMsg}`} />;
    }
    return null;
  }
  /**
   * Render error message
   */
  function renderMessageError() {
    if (sync && sync.syncEndWithError) {
      return (
        <Toast
          cta={true}
          message={`${syncWithErrorMsg}`}
          handleCancel={() => (window.location.href = '/')}
          handleGoSync={() => {
            dispatch(
              syncLoadingActions.setShowSync({
                showSync: true,
              })
            );
            dispatch(
              syncLoadingActions.setShowAlertSync({
                showAlertSync: false,
              })
            );
          }}
        />
      );
    }
    return null;
  }
  /**
   * When user access sync comp from url
   * Render Alert Confirm sync
   */
  function renderAlertConfirmSync() {
    return (
      <Modal onClose={() => (window.location.href = '/')}>
        <div className={styles['alert__sync']}>
          <p
            className={styles['alert__message']}
            dangerouslySetInnerHTML={{
              __html: confirmRunSync,
            }}
          ></p>
          <div className={styles['alert__cta']}>
            <button
              className={styles['alert__cta__link']}
              onClick={() => (window.location.href = '/')}
            >
              non
            </button>
            <button
              className={styles['alert__cta__link']}
              onClick={() => {
                dispatch(
                  syncLoadingActions.setShowSync({
                    showSync: true,
                  })
                );
                dispatch(
                  syncLoadingActions.setShowAlertSync({
                    showAlertSync: false,
                  })
                );
              }}
            >
              oui
            </button>
          </div>
        </div>
      </Modal>
    );
  }
  /**
   * Disable sync comp
   * Mode offline
   */
  if (!window.navigator.onLine) {
    return <Offline />;
  }
  /**
   * When user click on the button back
   * render alert confirm go Back
   */
  function renderModalConfirmBack() {
    return (
      <Modal onClose={() => setConfirmGoBack(false)}>
        <div className={styles['alert__sync']}>
          <p
            className={styles['alert__message']}
            dangerouslySetInnerHTML={{
              __html: cancelSync,
            }}
          ></p>
          <div className={styles['alert__cta']}>
            <button className={styles['alert__cta__link']} onClick={() => setConfirmGoBack(false)}>
              non
            </button>
            <button
              className={styles['alert__cta__link']}
              onClick={() => (window.location.href = '/')}
            >
              oui
            </button>
          </div>
        </div>
      </Modal>
    );
  }
  return (
    <div className={styles['sync']}>
      {
        <React.Fragment>
          <ul className={styles['button__left']}>
            <li className={styles['back']}>
              <button onClick={_ => setConfirmGoBack(true)}>
                <img src={backIcon} alt="back" />
              </button>
            </li>
          </ul>
        </React.Fragment>
      }
      <div className={styles['loader']}>
        {syncLoading.loading && (
          <img
            src={loaderIcon}
            alt="loader"
            style={{ width: '35px', display: 'inline-block', margin: '0 auto ' }}
          />
        )}
        <div className="indicator-percentage">
          <div className="indicator-percentage__background">
            <div
              className="indicator-percentage__amount"
              style={{ width: `${syncLoading.value}%` }}
            ></div>
          </div>
          <span className="indicator-percentage__percentage">
            {`${syncLoading.value}%`}&nbsp;&nbsp;
            {syncLoading.loading && syncLoading.step === 2 && (
              <React.Fragment>
                ({syncLoading.NumberFilesSuccess} / {syncLoading.numberFilesToDownload})
              </React.Fragment>
            )}
            {syncLoading.loading && syncLoading.step === 3 && (
              <React.Fragment>
                ({syncLoading.NumberFilesSuccess} / {syncLoading.numberFilesToDownload})
              </React.Fragment>
            )}
          </span>
        </div>
        {syncLoading.loading && syncLoading.step === 1 && (
          <p>phase 1: mise à jour des contenus en cours…</p>
        )}
        {syncLoading.loading && syncLoading.step === 2 && (
          <p>phase 2: mise à jour des fichiers en cours…</p>
        )}
        {syncLoading.loading && syncLoading.step === 3 && (
          <p>phase 3: mise à jour des fichiers communs…</p>
        )}
      </div>
      {confirmGoBack && renderModalConfirmBack()}
      {renderMessageCxp()}
      {renderMessage()}
      {!syncLoading && renderMessageError()}
      {syncLoading && syncLoading.showAlertSync && renderAlertConfirmSync()}
    </div>
  );
}

export default Synchronization;
