/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { Endpoint } from 'src/Utils/constants';
import { serviceOptions } from 'src/Services/API';

import Modal from 'src/Components/Modal';

import { IShare } from './Share.Types';

import closeIcon from 'src/Assets/documents/close.svg';
import styles from './Share.module.scss';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { getAuthState } from 'src/Redux/Auth/Selectors';

function Share({ handleCancelShareDocForum, doc }: IShare) {
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState<string[]>([]);
  const [sujet, setSujet] = useState('');
  const [message, setMessage] = useState('');
  const [isEmailInValide, setIsEmailInVaiden] = useState(false);
  const [isEmailNeeded, setIsEmailNeeded] = useState(false);
  const [successEmailSend, setSuccessEmailSend] = useState(false);
  const [errorSendMail, setErrorSendMail] = useState(false);
  const refObjet = useRef<HTMLInputElement | null>(null);
  const refMessage = useRef<HTMLTextAreaElement | null>(null);
  const refEmail = useRef<HTMLInputElement | null>(null);
  const User = useSelector(getAuthState);

  const onChangeSujetInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSujet(e.target.value.trim());
  };
  const onChangeMessageInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value.trim());
  };
  function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const onAddEmail = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      const target = e.target as HTMLInputElement;
      if (validateEmail(target.value)) {
        setIsEmailInVaiden(false);
        setEmail('');
        setIsEmailNeeded(false);
        setEmails([...emails, target.value]);
      } else {
        setIsEmailInVaiden(true);
      }
    }
  };
  const handleOnChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const deleteEmail = (item: string) => {
    const newList = emails.filter(i => i !== item);
    setEmails(newList);
  };

  const handleSendEmail = () => {
    let sendEmail = false;
    const FieldEmail = [];
    if (
      // emails.length === 0 &&
      refEmail &&
      refEmail.current &&
      validateEmail(refEmail.current.value)
    ) {
      sendEmail = true;
      FieldEmail.push(refEmail.current.value);
    }
    if (emails.length !== 0 || sendEmail) {
      console.log(`ReactGA:Partage Doc:(${User?.agence}):`, doc?.title);
      ReactGA.event('Partage Doc', {
        docName: doc?.title,
        brand: User?.brand?.name,
        region: User?.region,
        zone: User?.zone,
        agence: User?.agence,
      });
      serviceOptions.axios
        ?.post(`${Endpoint.shareFile}`, {
          emails: [...emails, ...FieldEmail],
          uid: doc?.file.uid,
          subject: sujet ? sujet : ' ',
          message: message ? message : ' ',
        })
        .then(_ => {
          // const { data } = res;
          setEmails([]);
          setEmail('');
          if (refObjet && refObjet.current) {
            refObjet.current.value = '';
          }
          if (refMessage && refMessage.current) {
            refMessage.current.value = '';
          }

          if (refEmail && refEmail.current) {
            refEmail.current.value = '';
          }
          setSuccessEmailSend(true);
          setInterval(() => {
            setSuccessEmailSend(false);
          }, 5000);
        })
        .catch(_ => {
          setErrorSendMail(true);
          setInterval(() => {
            setErrorSendMail(false);
          }, 5000);
        });
    } else {
      setIsEmailNeeded(true);
    }
  };

  return (
    <Modal onClose={handleCancelShareDocForum}>
      <div className={styles['doc__share']}>
        <div className={styles['doc__title']}>
          <span className={styles['doc__title__name']}>envoyer par email</span>
          <img src={closeIcon} alt="close" onClick={handleCancelShareDocForum} />
        </div>
        {successEmailSend && <div className={styles['succesSend']}>email envoyé avec succès</div>}
        {errorSendMail && <div className={styles['errorSend']}>email n'est pas envoyé !</div>}

        <div className={styles['doc__block']}>
          <label className={styles['title-input']} htmlFor="email">
            email :{' '}
          </label>
          <div className={styles['inputWithIcon']}>
            <input
              ref={refEmail}
              id="email"
              type="text"
              onKeyDown={e => onAddEmail(e)}
              placeholder="adresse email"
              className={styles['inputSearch']}
              value={email}
              onChange={e => handleOnChangeEmail(e)}
            />
          </div>
          {isEmailInValide ? (
            <span className={styles['erreur']}>
              format d'email invalide<br></br>
            </span>
          ) : null}
          {isEmailNeeded ? (
            <span className={styles['erreur']}>
              email manquant<br></br>
            </span>
          ) : null}
          <div className={styles['block_emails']}>
            {emails.map((item, index) => (
              <div className={styles['item']} key={index}>
                <div className={styles['item-delete']}>
                  <div className={styles['item-text']}>{item}</div>
                  <div>
                    <img
                      onClick={() => deleteEmail(item)}
                      className={styles['icon-item-delete']}
                      src={closeIcon}
                      alt="suprimer"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles['doc__block']}>
          <label className={styles['title-input']} htmlFor="subject">
            objet :{' '}
          </label>
          <div className={styles['inputWithIcon']}>
            <input
              ref={refObjet}
              id="subject"
              type="text"
              onChange={e => onChangeSujetInput(e)}
              placeholder="objet"
              className={styles['inputSearch']}
            />
          </div>
        </div>

        <div className={styles['doc__block']}>
          <label className={styles['title-input']} htmlFor="message">
            message :{' '}
          </label>
          <div className={styles['inputWithIcon']}>
            <textarea
              ref={refMessage}
              id="message"
              rows={3}
              placeholder="message ..."
              className={styles['inputSearch']}
              onChange={e => onChangeMessageInput(e)}
            ></textarea>
          </div>
        </div>
        <span className={styles['name-file']}>joindre : {doc?.title}</span>

        <button className="button button--s" onClick={handleSendEmail}>
          envoyer
        </button>
      </div>
    </Modal>
  );
}

export default Share;
