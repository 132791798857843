import { FeatureSwitchManager } from '../../../Services/API/FeatureSwitch';

interface PrezRecette2Prod {
  cxpTitle: string;
  prezTitle: string;
  recetteId: string;
  prodId: string;
}

interface PrezRecette2Recette {
  cxpTitle: string;
  prezTitle: string;
  recetteId: string;
  recetteId2: string;
}

export const prezRecette2Recette: PrezRecette2Recette[] = [
  {
    cxpTitle: 'Generaliste',
    prezTitle: 'decouverte',
    recetteId: '5a55f1addbc9a10087b08dcd',
    recetteId2: '5a55ee39dbc9a10087b08dbe',
  },
];

export const prezRecette2Prod: PrezRecette2Prod[] = [
  {
    cxpTitle: 'Generaliste',
    prezTitle: 'decouverte',
    recetteId: '5a3bdad22f9f9e0081f0809b',
    prodId: '5a3bdad22f9f9e0081f0809b',
  },

  {
    cxpTitle: 'Generaliste',
    prezTitle: 'recrutement',
    recetteId: '5a3bdaf72f9f9e0081f0809d',
    prodId: '5a3bdaf72f9f9e0081f0809d',
  },

  {
    cxpTitle: 'Generaliste',
    prezTitle: 'interim',
    recetteId: '5a3bdadd2f9f9e0081f0809c',
    prodId: '5a3bdadd2f9f9e0081f0809c',
  },

  {
    cxpTitle: 'Generaliste',
    prezTitle: 'sourcing',
    recetteId: '5b7c0e8ce20c38038c108f9d',
    prodId: '5b8d01478bf66b021f6ae4ef',
  },

  {
    cxpTitle: 'Generaliste',
    prezTitle: 'prevention',
    recetteId: '5aeb06a015f95d03802524b5',
    prodId: '5bf68a48243a5546854f0492',
  },

  {
    cxpTitle: 'Generaliste',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Generaliste',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f6bd593ba2d03aaafcd20',
  },

  {
    cxpTitle: 'Banque Assurances',
    prezTitle: 'decouverte',
    recetteId: '5a44f706e6e66f0081dcc324',
    prodId: '5a44f7ac2f9f9e0081f08107',
  },

  {
    cxpTitle: 'Banque Assurances',
    prezTitle: 'recrutement',
    recetteId: '5a44f723e6e66f0081dcc326',
    prodId: '5a44f7c62f9f9e0081f08109',
  },

  {
    cxpTitle: 'Banque Assurances',
    prezTitle: 'interim',
    recetteId: '5a44f715e6e66f0081dcc325',
    prodId: '5a44f7ba2f9f9e0081f08108',
  },

  {
    cxpTitle: 'Banque Assurances',
    prezTitle: 'sourcing',
    recetteId: '5b881123c6d8a103aa6e1fcf',
    prodId: '5b8cf52a8bf66b021f6ae453',
  },

  {
    cxpTitle: 'Banque Assurances',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Banque Assurances',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f77d993ba2d03aaafcd22',
  },

  {
    cxpTitle: 'Finition',
    prezTitle: 'decouverte',
    recetteId: '5a55f043dbc9a10087b08dc6',
    prodId: '5a539c6c2f9f9e0081f08256',
  },

  {
    cxpTitle: 'Finition',
    prezTitle: 'recrutement',
    recetteId: '5a55f053dbc9a10087b08dc8',
    prodId: '5a539c7e2f9f9e0081f08258',
  },

  {
    cxpTitle: 'Finition',
    prezTitle: 'interim',
    recetteId: '5a55f04bdbc9a10087b08dc7',
    prodId: '5a539c752f9f9e0081f08257',
  },

  {
    cxpTitle: 'Finition',
    prezTitle: 'sourcing',
    recetteId: '5b89454729bcd503874346e7',
    prodId: '5b8d00298bf66b021f6ae4e0',
  },

  {
    cxpTitle: 'Finition',
    prezTitle: 'prevention',
    recetteId: '5aeb07a415f95d03802524bb',
    prodId: '5bf68a93243a5546854f0494',
  },

  {
    cxpTitle: 'Finition',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Finition',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f780a93ba2d03aaafcd24',
  },

  {
    cxpTitle: 'Electricite Chauffage Climatisation',
    prezTitle: 'decouverte',
    recetteId: '5a55f139dbc9a10087b08dc9',
    prodId: '5a539bd22f9f9e0081f08253',
  },

  {
    cxpTitle: 'Electricite Chauffage Climatisation',
    prezTitle: 'recrutement',
    recetteId: '5a55f149dbc9a10087b08dcb',
    prodId: '5a539be32f9f9e0081f08255',
  },

  {
    cxpTitle: 'Electricite Chauffage Climatisation',
    prezTitle: 'interim',
    recetteId: '5a55f141dbc9a10087b08dca',
    prodId: '5a539bda2f9f9e0081f08254',
  },

  {
    cxpTitle: 'Electricite Chauffage Climatisation',
    prezTitle: 'sourcing',
    recetteId: '5b881bdfc6d8a103aa6e202f',
    prodId: '5b8cfd128bf66b021f6ae4c0',
  },

  {
    cxpTitle: 'Electricite Chauffage Climatisation',
    prezTitle: 'prevention',
    recetteId: '',
    prodId: '5bf68a93243a5546854f0494',
  },

  {
    cxpTitle: 'Electricite Chauffage Climatisation',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Electricite Chauffage Climatisation',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '',
  },

  {
    cxpTitle: 'Electricite Chauffage Climatisation',
    prezTitle: 'prevention ',
    recetteId: '5aeb089615f95d03802524bd',
    prodId: '5e0f783b93ba2d03aaafcd26',
  },

  {
    cxpTitle: 'Transport et Logistique',
    prezTitle: 'decouverte',
    recetteId: '5a218b045b6d15006ce34d32',
    prodId: '5a218b045b6d15006ce34d32',
  },

  {
    cxpTitle: 'Transport et Logistique',
    prezTitle: 'recrutement',
    recetteId: '5a218e415b6d15006ce34d47',
    prodId: '5a218e415b6d15006ce34d47',
  },

  {
    cxpTitle: 'Transport et Logistique',
    prezTitle: 'interim',
    recetteId: '5a217ff54b1b970058440c39',
    prodId: '5a217ff54b1b970058440c39',
  },

  {
    cxpTitle: 'Transport et Logistique',
    prezTitle: 'sourcing',
    recetteId: '5b8946d029bcd503874346f7',
    prodId: '5b8d2e848bf66b021f6ae572',
  },

  {
    cxpTitle: 'Transport et Logistique',
    prezTitle: 'prevention',
    recetteId: '5aeb095a15f95d03802524bf',
    prodId: '5bf68abf243a5546854f0499',
  },

  {
    cxpTitle: 'Transport et Logistique',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Transport et Logistique',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f786193ba2d03aaafcd28',
  },

  {
    cxpTitle: 'Metallurgie Chaudronnerie Soudure',
    prezTitle: 'decouverte',
    recetteId: '5a55ee88dbc9a10087b08dc0',
    prodId: '5a5489642f9f9e0081f08261',
  },

  {
    cxpTitle: 'Metallurgie Chaudronnerie Soudure',
    prezTitle: 'recrutement',
    recetteId: '5a55ee99dbc9a10087b08dc2',
    prodId: '5a5489872f9f9e0081f08263',
  },

  {
    cxpTitle: 'Metallurgie Chaudronnerie Soudure',
    prezTitle: 'interim',
    recetteId: '5a55ee90dbc9a10087b08dc1',
    prodId: '5a54897f2f9f9e0081f08262',
  },

  {
    cxpTitle: 'Metallurgie Chaudronnerie Soudure',
    prezTitle: 'sourcing',
    recetteId: '5b8945d829bcd503874346ed',
    prodId: '5b8d05608bf66b021f6ae518',
  },

  {
    cxpTitle: 'Metallurgie Chaudronnerie Soudure',
    prezTitle: 'prevention',
    recetteId: '5aeb09ef15f95d03802524c1',
    prodId: '5c893c9435d0d578291ab5d7',
  },

  {
    cxpTitle: 'Metallurgie Chaudronnerie Soudure',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Metallurgie Chaudronnerie Soudure',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f788c93ba2d03aaafcd2a',
  },

  {
    cxpTitle: 'Gros Oeuvre Travaux Publics',
    prezTitle: 'decouverte',
    recetteId: '5a55ef93dbc9a10087b08dc3',
    prodId: '5a539cfe2f9f9e0081f08259',
  },

  {
    cxpTitle: 'Gros Oeuvre Travaux Publics',
    prezTitle: 'recrutement',
    recetteId: '5a55efa3dbc9a10087b08dc5',
    prodId: '5a539d152f9f9e0081f0825b',
  },

  {
    cxpTitle: 'Gros Oeuvre Travaux Publics',
    prezTitle: 'interim',
    recetteId: '5a55ef9bdbc9a10087b08dc4',
    prodId: '5a539d0b2f9f9e0081f0825a',
  },

  {
    cxpTitle: 'Gros Oeuvre Travaux Publics',
    prezTitle: 'sourcing',
    recetteId: '5b89458229bcd503874346e9',
    prodId: '5b8d033a8bf66b021f6ae4fd',
  },

  {
    cxpTitle: 'Gros Oeuvre Travaux Publics',
    prezTitle: 'prevention',
    recetteId: '5aeb0a4a15f95d03802524c3',
    prodId: '5bf68a93243a5546854f0494',
  },

  {
    cxpTitle: 'Gros Oeuvre Travaux Publics',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Gros Oeuvre Travaux Publics',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f78b293ba2d03aaafcd2c',
  },

  {
    cxpTitle: 'Batiment Travaux Publics',
    prezTitle: 'decouverte',
    recetteId: '5a3bdc7d2f9f9e0081f080a1',
    prodId: '5a3bdc7d2f9f9e0081f080a1',
  },

  {
    cxpTitle: 'Batiment Travaux Publics',
    prezTitle: 'recrutement',
    recetteId: '5a3bdc9f2f9f9e0081f080a3',
    prodId: '5a3bdc9f2f9f9e0081f080a3',
  },

  {
    cxpTitle: 'Batiment Travaux Publics',
    prezTitle: 'interim',
    recetteId: '5a3bdc902f9f9e0081f080a2',
    prodId: '5a3bdc902f9f9e0081f080a2',
  },

  {
    cxpTitle: 'Batiment Travaux Publics',
    prezTitle: 'sourcing',
    recetteId: '5b88124bc6d8a103aa6e1fe3',
    prodId: '5b8cf6bf8bf66b021f6ae46e',
  },

  {
    cxpTitle: 'Batiment Travaux Publics',
    prezTitle: 'prevention',
    recetteId: '5ad5ecc8a8333303884c52ca',
    prodId: '5bf68a93243a5546854f0494',
  },

  {
    cxpTitle: 'Batiment Travaux Publics',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Batiment Travaux Publics',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f78e293ba2d03aaafcd2e',
  },

  {
    cxpTitle: 'Compta Gestion Paie',
    prezTitle: 'decouverte',
    recetteId: '5a55ebb9dbc9a10087b08db7',
    prodId: '5a53990a2f9f9e0081f0824d',
  },

  {
    cxpTitle: 'Compta Gestion Paie',
    prezTitle: 'recrutement',
    recetteId: '5a55ebfddbc9a10087b08db9',
    prodId: '5a5399222f9f9e0081f0824f',
  },

  {
    cxpTitle: 'Compta Gestion Paie',
    prezTitle: 'interim',
    recetteId: '5a55ebf2dbc9a10087b08db8',
    prodId: '5a5399152f9f9e0081f0824e',
  },

  {
    cxpTitle: 'Compta Gestion Paie',
    prezTitle: 'sourcing',
    recetteId: '5b88140dc6d8a103aa6e200e',
    prodId: '5b8cfb7d8bf66b021f6ae4ab',
  },

  {
    cxpTitle: 'Compta Gestion Paie',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Compta Gestion Paie',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f790c93ba2d03aaafcd30',
  },

  {
    cxpTitle: 'Relation Client',
    prezTitle: 'decouverte',
    recetteId: '5a55ecaedbc9a10087b08dba',
    prodId: '5a548a0c2f9f9e0081f08264',
  },

  {
    cxpTitle: 'Relation Client',
    prezTitle: 'recrutement',
    recetteId: '5a55ecbedbc9a10087b08dbc',
    prodId: '5a548a202f9f9e0081f08266',
  },

  {
    cxpTitle: 'Relation Client',
    prezTitle: 'interim',
    recetteId: '5a55f1addbc9a10087b08dcd',
    prodId: '5a548a162f9f9e0081f08265',
  },

  {
    cxpTitle: 'Relation Client',
    prezTitle: 'sourcing',
    recetteId: '5b89466029bcd503874346f1',
    prodId: '5b8d25648bf66b021f6ae543',
  },

  {
    cxpTitle: 'Relation Client',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Relation Client',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f793493ba2d03aaafcd32',
  },

  {
    cxpTitle: 'Metiers de lassistanat',
    prezTitle: 'decouverte',
    recetteId: '5a55e9cedbc9a10087b08daa',
    prodId: '5a539af82f9f9e0081f08250',
  },

  {
    cxpTitle: 'Metiers de lassistanat',
    prezTitle: 'recrutement',
    recetteId: '5a55e9e5dbc9a10087b08dac',
    prodId: '5a539b172f9f9e0081f08252',
  },

  {
    cxpTitle: 'Metiers de lassistanat',
    prezTitle: 'interim',
    recetteId: '5a55e9d9dbc9a10087b08dab',
    prodId: '5a539b0b2f9f9e0081f08251',
  },

  {
    cxpTitle: 'Metiers de lassistanat',
    prezTitle: 'sourcing',
    recetteId: '5b87fec5c6d8a103aa6e1f68',
    prodId: '5b8cf07b8bf66b021f6ae41c',
  },

  {
    cxpTitle: 'Metiers de lassistanat',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Metiers de lassistanat',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f795d93ba2d03aaafcd34',
  },

  {
    cxpTitle: 'Tertiaire',
    prezTitle: 'decouverte',
    recetteId: '5a3bdb092f9f9e0081f0809e',
    prodId: '5a3bdb092f9f9e0081f0809e',
  },

  {
    cxpTitle: 'Tertiaire',
    prezTitle: 'recrutement',
    recetteId: '5a3bdb242f9f9e0081f080a0',
    prodId: '5a3bdb242f9f9e0081f080a0',
  },

  {
    cxpTitle: 'Tertiaire',
    prezTitle: 'interim',
    recetteId: '5a3bdb132f9f9e0081f0809f',
    prodId: '5a3bdb132f9f9e0081f0809f',
  },

  {
    cxpTitle: 'Tertiaire',
    prezTitle: 'sourcing',
    recetteId: '5b89469029bcd503874346f3',
    prodId: '5b8d26a48bf66b021f6ae551',
  },

  {
    cxpTitle: 'Tertiaire',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Tertiaire',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f798293ba2d03aaafcd36',
  },

  {
    cxpTitle: 'Logistique',
    prezTitle: 'decouverte',
    recetteId: '5a44cf92e6e66f0081dcc321',
    prodId: '5a44d08a2f9f9e0081f08104',
  },

  {
    cxpTitle: 'Logistique',
    prezTitle: 'recrutement',
    recetteId: '5a44cfcde6e66f0081dcc323',
    prodId: '5a44d0cf2f9f9e0081f08106',
  },

  {
    cxpTitle: 'Logistique',
    prezTitle: 'interim',
    recetteId: '5a44cfa9e6e66f0081dcc322',
    prodId: '5a44d0ba2f9f9e0081f08105',
  },

  {
    cxpTitle: 'Logistique',
    prezTitle: 'sourcing',
    recetteId: '5b8945b129bcd503874346eb',
    prodId: '5bf72146243a5546854f0654',
  },

  {
    cxpTitle: 'Logistique',
    prezTitle: 'prevention',
    recetteId: '5aeb0c9c15f95d03802524cd',
    prodId: '5bf68abf243a5546854f0499',
  },

  {
    cxpTitle: 'Logistique',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Logistique',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f79aa93ba2d03aaafcd38',
  },

  {
    cxpTitle: 'Transport ',
    prezTitle: 'decouverte',
    recetteId: '5a44ae7de6e66f0081dcc320',
    prodId: '5a44affe2f9f9e0081f08101',
  },

  {
    cxpTitle: 'Transport ',
    prezTitle: 'recrutement',
    recetteId: '5a44ae49e6e66f0081dcc31e',
    prodId: '5a44b0302f9f9e0081f08103',
  },

  {
    cxpTitle: 'Transport ',
    prezTitle: 'interim',
    recetteId: '5a44ae66e6e66f0081dcc31f',
    prodId: '5a44b0172f9f9e0081f08102',
  },

  {
    cxpTitle: 'Transport ',
    prezTitle: 'sourcing',
    recetteId: '5b8946af29bcd503874346f5',
    prodId: '5b8d2ca78bf66b021f6ae561',
  },

  {
    cxpTitle: 'Transport ',
    prezTitle: 'prevention',
    recetteId: '5aeb0d2c15f95d03802524cf',
    prodId: '5c893ad235d0d578291ab5d5',
  },

  {
    cxpTitle: 'Transport ',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Transport ',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f79cf93ba2d03aaafcd3a',
  },

  {
    cxpTitle: 'Commerce',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  { cxpTitle: 'Commerce', prezTitle: 'groupe', recetteId: '5df9f9ad6936cc0613b036a6', prodId: '' },

  {
    cxpTitle: 'Mecanique Maintenance Electricite Industrielle',
    prezTitle: 'decouverte',
    recetteId: '5a55ee30dbc9a10087b08dbd',
    prodId: '5a548b4e2f9f9e0081f08267',
  },

  {
    cxpTitle: 'Mecanique Maintenance Electricite Industrielle',
    prezTitle: 'recrutement',
    recetteId: '5a55ee45dbc9a10087b08dbf',
    prodId: '5a548b612f9f9e0081f08269',
  },

  {
    cxpTitle: 'Mecanique Maintenance Electricite Industrielle',
    prezTitle: 'interim',
    recetteId: '5a55ee39dbc9a10087b08dbe',
    prodId: '5a548b572f9f9e0081f08268',
  },

  {
    cxpTitle: 'Mecanique Maintenance Electricite Industrielle',
    prezTitle: 'sourcing',
    recetteId: '5b89460129bcd503874346ef',
    prodId: '5b8d234a8bf66b021f6ae535',
  },

  {
    cxpTitle: 'Mecanique Maintenance Electricite Industrielle',
    prezTitle: 'prevention',
    recetteId: '5aeb0db515f95d03802524d1',
    prodId: '5bf68aff243a5546854f04a0',
  },

  {
    cxpTitle: 'Mecanique Maintenance Electricite Industrielle',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Mecanique Maintenance Electricite Industrielle',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f7a0793ba2d03aaafcd3c',
  },

  {
    cxpTitle: 'Electricite Mecanique Metallurgie',
    prezTitle: 'decouverte',
    recetteId: '5a2199705b6d15006ce34da7',
    prodId: '5a2199705b6d15006ce34da7',
  },

  {
    cxpTitle: 'Electricite Mecanique Metallurgie',
    prezTitle: 'recrutement',
    recetteId: '5a219bd85b6d15006ce34dd3',
    prodId: '5a219bd85b6d15006ce34dd3',
  },

  {
    cxpTitle: 'Electricite Mecanique Metallurgie',
    prezTitle: 'interim',
    recetteId: '5a2198205b6d15006ce34d87',
    prodId: '5a2198205b6d15006ce34d87',
  },

  {
    cxpTitle: 'Electricite Mecanique Metallurgie',
    prezTitle: 'sourcing',
    recetteId: '5b8944a629bcd503874346e5',
    prodId: '5b8cfeeb8bf66b021f6ae4d1',
  },

  {
    cxpTitle: 'Electricite Mecanique Metallurgie',
    prezTitle: 'prevention',
    recetteId: '5c7950d909f13706fe1e6242',
    prodId: '5bf68aff243a5546854f04a0',
  },

  {
    cxpTitle: 'Electricite Mecanique Metallurgie',
    prezTitle: 'outils',
    recetteId: '5d4a7f58f50ac8065aecf419',
    prodId: '5d8da43f0754de3ed6db0a13',
  },

  {
    cxpTitle: 'Electricite Mecanique Metallurgie',
    prezTitle: 'groupe',
    recetteId: '5df9f9ad6936cc0613b036a6',
    prodId: '5e0f7a2b93ba2d03aaafcd3e',
  },

  { cxpTitle: 'Test', prezTitle: 'prevention', recetteId: '61829667f75ed1075a6ec69d', prodId: '' },

  { cxpTitle: 'Test', prezTitle: 'outils', recetteId: '6166ac6ca8b51142d524e332', prodId: '' },

  { cxpTitle: 'Test', prezTitle: 'groupe', recetteId: '5df9f9ad6936cc0613b036a6', prodId: '' },
];

export const getFinalPrezId = (prezId: string) => {
  if (FeatureSwitchManager.isPordEnv) {
    const results = prezRecette2Prod.filter(
      item => item.recetteId.normalize().toLowerCase() === prezId.normalize().toLowerCase()
    );
    if (results && results.length > 0) {
      return results[0].prodId;
    } else {
      return prezId;
    }
  } else {
    const results = prezRecette2Recette.filter(
      item => item.recetteId.normalize().toLowerCase() === prezId.normalize().toLowerCase()
    );
    if (results && results.length > 0) {
      return results[0].recetteId2;
    } else {
      return prezId;
    }
  }
};

export default getFinalPrezId;
