import React from 'react';
import { IToast } from './Toast.types';

import styles from './Toast.module.scss';

function Toast({ message, cta, handleCancel, handleGoSync }: IToast) {
  return (
    <div className={styles['toast']} data-testid="toast">
      <p
        className={styles['toast__message']}
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      ></p>
      {cta && (
        <div className={styles['toast__cta']}>
          <button className={styles['toast__cta__link']} onClick={handleCancel}>
            pas maintenant
          </button>
          <button className={styles['toast__cta__link']} onClick={handleGoSync}>
            relancer
          </button>
        </div>
      )}
    </div>
  );
}

export default Toast;
