/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/no-explicit-any*/
/* eslint-disable @typescript-eslint/no-unused-vars*/

import {
  Checkbox,
  DropDown,
  Modal,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { Check, Eye } from '@randstad-lean-mobile-factory/react-components-ui-shared';

// sans la ligne suivante, TypeScript error :Object is possibly 'undefined'.
// import '@randstad-lean-mobile-factory/react-form-fields';

import React, { useEffect, useReducer, useRef, useState } from 'react';
import _ from 'lodash';

import { saveAs } from 'file-saver';
import { Promise } from 'bluebird';

import styles from './AdminBO.module.scss';
import { serviceOptions } from 'src/Services/API';

import { db } from 'src/db';
import { FileInput, IFileData, IFileTarget } from '../FileInput';

import { ICxp, ISlide, IPresentation, ISSlide } from 'src/db/Types';
import { ISlideToDownload, ISlideToUpload, PropsComponent } from './AdminBO.types';
import { csv, util } from './AdminBO.component.ext';
import { MessageInfo } from '../MessageInfo';
import { TextInput } from '../TextInput';
import { FeatureSwitchManager } from 'src/Services/API/FeatureSwitch';

enum StateComponent {
  DEFAULT,
  MATCHING,
  VALIDATION,
  PASSWORD,
  UPLOADING_INPROGRESS,
  UPLOADING_RESPONSE,
  MESSAGE,
  ERROR,
}

const MATCHING_MODE_BY_SLIDE = 'par slide title';
const MATCHING_MODE_BY_INDEX = 'par index';
const MATCHING_MODE_BY_USER_INPUT = 'recette 2 prod';

const URL_SLIDES_DOWNLAOD = '/public/api/web/slides/download/';
const URL_SLIDES_UPLOAD = '/public/api/web/slides/upload';
const URL_LOG_DOWNLOAD = '/public/api/web/slides/downloadlog';

const AdminBO = () => {
  const [selectedFiles, setSelectedFiles] = useState<IFileData[]>([]);
  const [filesTargets, setFilesTargets] = useState<IFileTarget[]>([]);
  const [uploadingInProgress, setUploadingInProgress] = useState(false);

  const [selectedCxp, setSelectedCxp] = useState<ICxp>();
  const [cxpsList, setCxpsList] = useState<ICxp[]>();

  const [selectedPresentation, setSelectedPresentation] = useState<IPresentation>();
  const [presentationsList, setPresentationsList] = useState<IPresentation[]>();

  const [selectedSlide, setSelectedSlide] = useState<ISlide>();
  const [slidesList, setSlidesList] = useState<ISlide[]>();

  const [matchingMode, setMatchingMode] = useState<string | undefined>(MATCHING_MODE_BY_USER_INPUT);

  const [updateSlideTitle, setUpdateSlideTitle] = useState(true);

  const [keyInput, setKeyInput] = useState(0);

  const [password, setPassword] = useState<string>('');

  const [numberFilesFailed, setNumberFilesFailed] = useState(0);
  const [numberFilesSuccess, setNumberFilesSuccess] = useState(0);

  const [displayMessage, setDisplayMessage] = useState(false);
  const [displayPassword, setDisplayPassword] = useState(false);
  const [displayErrorOrInfo, setDisplayErrorOrInfo] = useState(false);

  const [canUploadSlides, setCanUploadSlides] = useState(false);

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  //state & message
  const [stateComponent, setStateComponent] = useState<StateComponent>(StateComponent.DEFAULT);

  //const [messageIcon, setMessageIcon] = useState<React.ReactElement>(<IconMesssageInfo />);
  const [displayMessageContent, setDisplayMessageContent] = useState<React.ReactNode>();
  const [displayMessageTitle, setDisplayMessageTitle] = useState('');
  const [displayMessageTitleDefaultButton, setDisplayMessageTitleDefaultButton] = useState('');

  useEffect(() => {
    db.cexps?.toArray().then(data => {
      setCxpsList(data);
    });
    if (FeatureSwitchManager.isAdminBackofficeDebugEnabled) {
      util.testUnitaireCheck();
    }
  }, []);

  let modeDownload = '';
  let slidesToDownload: ISlideToDownload[] = [];

  const displayMatchingMessage = (title: string, slides: ISlideToUpload[]) => {
    setStateComponent(StateComponent.MATCHING);
    setDisplayMessageContent(uploadMatchingMessageJSX(slides));
    setDisplayMessageTitle(title);
    setDisplayMessageTitleDefaultButton('ok');
    //setMessageIcon(<IconMesssageInfo />);
    setDisplayMessage(true);
    setDisplayErrorOrInfo(false);
  };

  const displayValidationMessage = (title: string, files: IFileData[]) => {
    setStateComponent(StateComponent.VALIDATION);
    setDisplayMessageContent(validationMessageJSX(files));
    setDisplayMessageTitle(title);
    setDisplayMessageTitleDefaultButton('uploader');
    setDisplayMessage(true);
    setDisplayErrorOrInfo(false);
  };

  const displayPasswordForm = () => {
    setStateComponent(StateComponent.PASSWORD);
    setDisplayMessageTitle('entrer le code de vérification');
    setDisplayMessageTitleDefaultButton('Ok');
    setDisplayPassword(true);
    setDisplayErrorOrInfo(false);
  };

  const displayUploadResponseMessage = (title: string, data: any[]) => {
    setStateComponent(StateComponent.UPLOADING_RESPONSE);
    setDisplayMessageContent(uploadResponseMessageJSX(data));
    setDisplayMessageTitle(title);
    setDisplayMessageTitleDefaultButton('Ok');
    setDisplayMessage(true);
    setDisplayErrorOrInfo(false);
  };

  const displayMessageUploadInProgress = (message: string) => {
    setStateComponent(StateComponent.UPLOADING_INPROGRESS);
    setDisplayMessageContent(<>{message}</>);
    setDisplayMessageTitle('info');
    setDisplayErrorOrInfo(true);
    console.log(message);
  };

  const displayMessageError = (message: string) => {
    setStateComponent(StateComponent.ERROR);
    setDisplayMessageContent(<>{message}</>);
    setDisplayMessageTitle('Erreur');
    setDisplayErrorOrInfo(true);
    console.log(message);
  };

  const initSlideToDownloadQueue = () => {
    slidesToDownload = [];
  };
  const addSlideToDownloadQueue = (
    cxp: ICxp,
    presentation: IPresentation,
    slide: ISlide,
    index: number
  ) => {
    const obj: ISlideToDownload = {
      cxp: cxp,
      presentation: presentation,
      slide: slide,
      index: index,
      slideCompleteTitle: 'not defined',
    };
    slidesToDownload.push(obj);
  };

  //<br />
  const uploadMatchingMessageJSX = (slides: ISlideToUpload[]) => {
    const LineNode = (slide: ISlideToUpload, index: number) => {
      if (slide.matchingError) {
        return (
          <li style={{ color: 'red' }} key={slide.file.name}>
            [{index}]:{slide.file.name} {'===>  No match( ne sera pas uploader): '}
            {slide.matchingError}
          </li>
        );
      } else {
        return (
          <li key={slide.file.name}>
            [{index}]:{slide.file.name}
            {'===>'}
            {slide.target?.slideCompleteTitle}
          </li>
        );
      }
    };
    return <>{slides?.map((slide, index) => LineNode(slide, index))}</>;
  };

  //<br />
  const validationMessageJSX = (filesData: IFileData[]) => {
    const LineNode = (fileData: IFileData, index: number) => {
      if (!fileData.target) {
        return (
          <li style={{ color: 'red' }} key={fileData.file.name}>
            [{index}]:{fileData.file.name} {'===>  No match( ne sera pas uploader): '}
          </li>
        );
      } else {
        return (
          <li key={fileData.file.name}>
            [{index}]:{fileData.file.name}
            {'===>'}
            {fileData.target?.slideCompleteTitle}
          </li>
        );
      }
    };
    return <>{filesData?.map((fileData, index) => LineNode(fileData, index))}</>;
  };

  const uploadResponseMessageJSX = (data: any[]) => {
    const LineNode = (item: any, index: number) => {
      return <li key={'' + index}>{item}</li>;
    };
    return <>{data?.map((item, index) => LineNode(item, index))}</>;
  };

  //https://stackoverflow.com/questions/43780402/how-can-i-compare-2-strings-with-accents
  const matchSourceWithTarget_BySlide = (
    sources: ISlideToUpload[],
    targets: ISlideToDownload[]
  ) => {
    const slidesValideToUpload: ISlideToUpload[] = [];
    for (let i = 0; i < sources.length; i++) {
      sources[i].target = undefined;
      const listCxps = targets.filter(
        item => item.cxp.name.normalize().toLowerCase() === sources[i].cxpName?.toLowerCase()
      );
      if (listCxps && listCxps.length > 0) {
        const listPrezs = listCxps.filter(
          item =>
            item.presentation.title.normalize().toLowerCase() ===
            sources[i].presnetationTitle?.toLowerCase()
        );
        if (listPrezs && listPrezs.length > 0) {
          const listSlides = listPrezs.filter(
            item =>
              item.slide.title.normalize().toLowerCase() === sources[i].slideTitle?.toLowerCase()
          );
          if (listSlides && listSlides.length > 0) {
            sources[i].target = listSlides[0];
            slidesValideToUpload.push(sources[i]);
          } else {
            sources[i].matchingError = `aucun slide ne correspond à ${sources[i].slideTitle}`;
          }
        } else {
          sources[
            i
          ].matchingError = `aucune presentation ne correspond à ${sources[i].presnetationTitle}`;
        }
      } else {
        sources[i].matchingError = `aucun cxp ne correspond à ${sources[i].cxpName}`;
      }
    }
    return slidesValideToUpload;
  };

  const matchSourceWithTarget_ByIndex = (
    sources: ISlideToUpload[],
    targets: ISlideToDownload[]
  ) => {
    const slidesValideToUpload: ISlideToUpload[] = [];
    const lengthTargets = targets.length;
    for (let i = 0; i < sources.length; i++) {
      if (i < lengthTargets) {
        sources[i].target = targets[i];
        slidesValideToUpload.push(sources[i]);
      } else {
        sources[i].target = undefined;
        sources[i].matchingError = 'no index';
      }
    }

    return slidesValideToUpload;
  };

  const matchSourceWithTarget_ByFile = (sources: ISlideToUpload[], targets: ISlideToDownload[]) => {
    const slidesValideToUpload: ISlideToUpload[] = [];

    for (let i = 0; i < sources.length; i++) {
      const slideSource = sources[i];
      util.computeSlideTitleForRecetteEnv(slideSource);
    }

    for (let i = 0; i < targets.length; i++) {
      const slideTarget = targets[i];
      util.computeSlideTitleForProdEnv(slideTarget);
    }

    for (let i = 0; i < sources.length; i++) {
      sources[i].target = undefined;
      const listCxps = targets.filter(
        item => item.cxp.name.normalize().toLowerCase() === sources[i].cxpName?.toLowerCase()
      );
      if (listCxps && listCxps.length > 0) {
        const listPrezs = listCxps.filter(
          item =>
            item.presentation.title.normalize().toLowerCase() ===
            sources[i].presnetationTitle?.toLowerCase()
        );
        if (listPrezs && listPrezs.length > 0) {
          const listSlides = listPrezs.filter(
            item => item.slideTitleEnvNormalized === sources[i].slideTitleEnvNormalized
          );
          if (listSlides && listSlides.length > 0) {
            sources[i].target = listSlides[0];
            slidesValideToUpload.push(sources[i]);
          } else {
            sources[i].matchingError = `aucun slide ne correspond à ${sources[i].slideTitle}`;
          }
        } else {
          sources[
            i
          ].matchingError = `aucune presentation ne correspond à ${sources[i].presnetationTitle}`;
        }
      } else {
        sources[i].matchingError = `aucun cxp ne correspond à ${sources[i].cxpName}`;
      }
    }
    return slidesValideToUpload;
  };

  const computeTarget = async () => {
    const computeSlideCompleteTitle = (
      cxpName: string,
      presentationTitle: string,
      slideTitle: string
    ) => {
      return cxpName.substr(0, 3) + '@' + presentationTitle.substr(0, 1) + '@' + slideTitle;
    };
    const slide2Downloads: ISlideToDownload[] = [];
    let jsonObj: any = {};
    if (selectedCxp === undefined) {
      return [undefined, slide2Downloads];
    } else {
      let presentations = [];
      if (selectedPresentation) {
        presentations.push(selectedPresentation);
      } else {
        presentations = await util.getPresentationsOfCxp(selectedCxp);
      }

      const prezs = [];
      for (let i = 0; i < presentations.length; i++) {
        let slides: ISlide[] = [];
        if (selectedSlide) {
          slides.push(selectedSlide);
        } else {
          slides = await util.getSlidesOfPresentation(selectedCxp, presentations[i]);
        }

        const slidesObj = [];
        for (let j = 0; j < slides?.length; j++) {
          const slideJson = {
            id: slides[j]._id,
            title: slides[j].title,
          };

          slidesObj.push(slideJson);
          slide2Downloads.push({
            cxp: selectedCxp,
            presentation: presentations[i],
            slide: slides[j],
            index: j,
            slideCompleteTitle: computeSlideCompleteTitle(
              selectedCxp.name,
              presentations[i].title,
              slides[j].title
            ),
          });
        }
        const prezJsonObj = {
          id: presentations[i]._id,
          name: presentations[i].title,
          slides: slidesObj,
        };
        prezs.push(prezJsonObj);
      }
      jsonObj = {
        id: selectedCxp?._id,
        name: selectedCxp?.name,
        presentations: prezs,
      };
    }
    return [jsonObj, slide2Downloads];
  };

  const onSelectedCxp = async (cxp: ICxp | undefined) => {
    setSelectedCxp(cxp);
    const presentations = await util.getPresentationsOfCxp(cxp);
    setPresentationsList(presentations);
    setSelectedPresentation(undefined);
    setCanUploadSlides(false);
  };

  const onSelectedPresentation = async (presentation: IPresentation | undefined) => {
    setSelectedPresentation(presentation);
    const slides = await util.getSlidesOfPresentation(selectedCxp, presentation);
    setSlidesList(slides);
    setSelectedSlide(undefined);
    setCanUploadSlides(false);
  };

  const onSelectedSlide = async (slide: ISlide | undefined) => {
    setSelectedSlide(slide);
    setCanUploadSlides(false);
  };

  const onSelectedFiles = (files: IFileData[]) => {
    setSelectedFiles(files);
  };

  const onUpdateSlideTitle = (value: boolean) => {
    setUpdateSlideTitle(value);
  };

  const onGetPrezStructure = async () => {
    const DumpPresentationOfCxp = async (cxp: ICxp) => {
      const presentations = await util.getPresentationsOfCxp(cxp);
      if (presentations) {
        for (let i = 0; i < presentations.length; i++) {
          const prez = presentations[i];
          console.log(`${cxp.name},${prez.title},${prez._id}`);
        }
      }
    };

    console.log('----------------------------------------------------');
    console.log('cxp,prez title, prezId');
    const cexps: Array<ICxp> | undefined = await db.cexps?.toArray();
    if (cexps) {
      for (let i = 0; i < cexps.length; i++) {
        const cxp = cexps[i];
        await DumpPresentationOfCxp(cxp);
      }
    }

    console.log('----------------------------------------------------');
  };

  const onDownloadLog = () => {
    return serviceOptions.axios
      ?.get(URL_LOG_DOWNLOAD, { responseType: 'blob' })
      .then(response => {
        console.log(`download log ==> with succes`);
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        saveAs(blob, 'puzzle_log.log');
        return;
      })
      .catch(err => {
        console.log(`downloadlog ==> with error: ${err}`);
      });
  };

  const downloadFile = (uid: string, filename: string) => {
    serviceOptions.axios
      ?.get(URL_SLIDES_DOWNLAOD + uid, { responseType: 'blob' })
      .then(response => {
        console.log(`download ${filename} ==> with succes`);
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        saveAs(blob, filename);
      })
      .catch(err => {
        console.log(`download ${filename} ==> with error: ${err}`);
      });
  };

  const downloadCxps = async () => {
    const cexps: Array<ICxp> | undefined = await db.cexps?.toArray();
    if (cexps) {
      for (let i = 0; i < cexps.length; i++) {
        const cxp = cexps[i];
        await downloadPresentationOfCxp(cxp);
      }
    }
    return cexps;
  };

  const downloadPresentationOfCxp = async (cxp: ICxp) => {
    const presentations = await util.getPresentationsOfCxp(cxp);
    if (presentations) {
      for (let i = 0; i < presentations.length; i++) {
        const prez = presentations[i];
        await downloadPresentation(cxp, prez);
      }
    }
  };

  const downloadPresentation = async (cxp: ICxp, presentation: IPresentation) => {
    if (presentation) {
      const slides2 = await util.getSlidesOfPresentation(cxp, presentation);

      const files: any[] = [];
      if (slides2) {
        for (let i = 0; i < slides2.length; i++) {
          const zipFile = slides2[i].zipFile;
          files.push(zipFile);
          const fileName: string =
            cxp.name + '@' + presentation.title + '@' + i + '@' + zipFile.filename;
          if (modeDownload === 'csv') {
            csv.csvAdd(cxp, presentation, slides2[i]);
          } else {
            addSlideToDownloadQueue(cxp, presentation, slides2[i], i);
          }
        }
      }
      return files;
    }
  };

  async function downloadSlides(slides2D: ISlideToDownload[]) {
    const convertNmber2String = (x: number | undefined) => {
      if (x === undefined) {
        return '';
      }
      if (x < 10) {
        return '0' + x;
      } else {
        return '' + x;
      }
    };
    const downloadFileBird = (slide2D: ISlideToDownload | undefined) => {
      const filename: string =
        slide2D?.cxp.name +
        '@' +
        slide2D?.presentation.title +
        '@' +
        convertNmber2String(slide2D?.index) +
        '@' +
        slide2D?.slide.title +
        '@' +
        slide2D?.slide.zipFile.filename;
      const uid: string | undefined = slide2D?.slide.zipFile.uid;
      return serviceOptions.axios
        ?.get(URL_SLIDES_DOWNLAOD + uid, { responseType: 'blob' })
        .then(response => {
          console.log(`download ${filename} ==> with succes`);
          const blob = new Blob([response.data], { type: 'application/octet-stream' });
          saveAs(blob, filename);
          return;
        })
        .catch(err => {
          console.log(`download ${filename} ==> with error: ${err}`);
        });
    };

    // 8.2 download files
    //let counter = 0;
    //const countSlidesToDownload = slidesToDownload.length;
    console.log('slidesToDownload.length==>', slides2D.length);
    if (slides2D === undefined) {
      return;
    }
    if (slides2D.length === 0) {
      return;
    }
    await Promise.map(
      slides2D,
      function (slide2D: ISlideToDownload | undefined, index, length) {
        //steps(counter, length);
        return new Promise(resolve => {
          downloadFileBird(slide2D)
            ?.then(file => {
              //console.log('Load File: ', file);m
              //if (file && _.isObject(file)) {
              //  store.dispatch(syncLoadingActions.numberFilesSuccess());
              //  counter++;
              //}

              const nb = numberFilesSuccess + 1;

              setNumberFilesSuccess(numberFilesSuccess + 1);

              console.log('downloadFileBird succes', numberFilesSuccess);
              resolve(true);
            })
            .catch(error => {
              resolve(true);
              //store.dispatch(syncLoadingActions.numberFilesFailed());
              console.log('Load file error:', error);
            });
        });
      },
      { concurrency: 1 }
    );
  }

  const downloads = async (mode: string) => {
    modeDownload = mode;
    const [objJson, slides] = await computeTarget();

    if (modeDownload === 'csv') {
      console.log('mode download csv');
      csv.csvInit();
    } else {
      console.log('mode download file');
      initSlideToDownloadQueue();
    }

    if (selectedCxp === undefined) {
      await downloadCxps();
    } else {
      if (selectedPresentation === undefined) {
        await downloadPresentationOfCxp(selectedCxp);
      } else {
        await downloadPresentation(selectedCxp, selectedPresentation);
      }
    }

    if (modeDownload === 'csv') {
      csv.csvClose();
    } else {
      await downloadSlides(slidesToDownload);
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  //----- phase1_matching
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  const phase1_matching = async () => {
    console.log('############ phase1_matching:########', selectedFiles);
    //compute SlideToUpload
    const _slidesToUpload: ISlideToUpload[] = [];
    const _filesTargtes: IFileTarget[] = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const item = util.convertFileName2SlideToUpload(selectedFiles[i].file);
      _slidesToUpload.push(item);
    }
    console.log('slideToUploads==>', _slidesToUpload);

    const [objJson, allSelectedSlides] = await computeTarget();
    console.log('allSelectedSlides==>', allSelectedSlides);

    let slidesValidesToUpload = [];

    console.log('Matching mode....:', matchingMode);

    if (matchingMode === MATCHING_MODE_BY_INDEX) {
      slidesValidesToUpload = matchSourceWithTarget_ByIndex(_slidesToUpload, allSelectedSlides);
    } else if (matchingMode === MATCHING_MODE_BY_USER_INPUT) {
      slidesValidesToUpload = matchSourceWithTarget_ByFile(_slidesToUpload, allSelectedSlides);
    } else {
      slidesValidesToUpload = matchSourceWithTarget_BySlide(_slidesToUpload, allSelectedSlides);
    }

    displayMatchingMessage(
      `matching(${matchingMode}).....  ${slidesValidesToUpload.length} valides/ ${_slidesToUpload.length}`,
      _slidesToUpload
    );

    //------------------------------------------
    //for new fonction dropdown
    for (let i = 0; i < allSelectedSlides.length; i++) {
      _filesTargtes.push({
        slideId: allSelectedSlides[i].slide._id,
        slideTitle: allSelectedSlides[i].slide.title,
        slideCompleteTitle: allSelectedSlides[i].slideCompleteTitle,
      });
    }
    console.log('_filesTargtes', _filesTargtes);
    setFilesTargets(_filesTargtes);

    for (let i = 0; i < _slidesToUpload.length; i++) {
      const _s2u = _slidesToUpload[i];
      if (_s2u.target) {
        selectedFiles[i].target = {
          slideId: _s2u.target.slide._id,
          slideTitle: _s2u.target.slide.title,
          slideCompleteTitle: _s2u.target.slideCompleteTitle,
        };
      } else {
        selectedFiles[i].target = undefined;
      }
    }

    //ça permet de initialiser le commonent FileInput
    const x = keyInput + 1;
    setKeyInput(x);

    setSelectedFiles(selectedFiles);

    setCanUploadSlides(true);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  //----- phase2_validation
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  const phase2_validation = async () => {
    console.log('############ phase2_validation :########', selectedFiles);
    displayValidationMessage(`validation..... `, selectedFiles);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  //----- phase3_authentification
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  const phase3_authentification = async () => {
    console.log('############ phase3_authentification:########');
    displayPasswordForm();
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  //----- phase4_upload
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  const phase4_upload = async (_password: string) => {
    console.log('############ phase4_upload ########', selectedFiles);
    const formData = new FormData();
    const files: File[] = [];
    const slidesTargetID: (string | undefined)[] = [];
    const slidesTargetTitle: (string | undefined)[] = [];
    formData.append('password', _password);

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].target) {
        slidesTargetID.push(selectedFiles[i].target?.slideId);
        const slide2upload = util.convertFileName2SlideToUpload(selectedFiles[i].file);
        if (updateSlideTitle && slide2upload.slideTitle) {
          slidesTargetTitle.push(slide2upload.slideTitle);
        } else {
          slidesTargetTitle.push(undefined);
        }

        files.push(selectedFiles[i].file);
      }
    }

    const str: string = JSON.stringify(slidesTargetID);
    formData.append('slides', str);

    const strTitle: string = JSON.stringify(slidesTargetTitle);
    formData.append('titles', strTitle);
    console.log(strTitle);

    // eslint-disable-next-line
    files?.map(item => {
      formData.append('file', item);
    });

    displayMessageUploadInProgress('uploading in progress');

    setUploadingInProgress(true);

    new Promise((resolve, reject) => {
      serviceOptions.axios
        ?.post(URL_SLIDES_UPLOAD, formData)
        .then(res => {
          setUploadingInProgress(false);
          resolve(res);
          console.log(res);
          displayUploadResponseMessage(res?.data?.message, res?.data?.data);
        })
        .catch(error => {
          setUploadingInProgress(false);
          if (error?.response?.data?.message) {
            displayMessageError(error?.response?.data?.message);
          } else {
            displayMessageError("erreur dans l'upload des slides");
          }

          reject({
            code: error?.response?.data?.code !== undefined ? error?.response?.data?.code : 0,
            message: error?.response?.data?.message,
            error: error,
          });
        });
    });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  //----- componenent final
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {
        <Modal
          title={'Upload/Downlaod Slides'}
          //trigger={<Eye />}
          icon={<></>}
          //ref={ref}
          defaultOpen={true}
          contentStyle={{
            maxWidth: '80rem',
            marginBottom: '40rem',
          }}
        >
          <WithLightTitle title="choisir le centre d'expert">
            <DropDown
              canBeReset={true}
              placeholder="choisir cxps..."
              items={cxpsList ?? []}
              selectedItem={selectedCxp}
              onSelectItem={item => {
                onSelectedCxp(item);
              }}
              keyValueExtractor={item => {
                return { key: item._id, value: item.name };
              }}
            />
          </WithLightTitle>

          <div className={styles.blockRowSpaceBetween}>
            <WithLightTitle title="choisir la presentation">
              <DropDown
                canBeReset={true}
                placeholder="choisir une présentation..."
                items={presentationsList ?? []}
                selectedItem={selectedPresentation}
                onSelectItem={item => {
                  onSelectedPresentation(item);
                }}
                keyValueExtractor={item => {
                  return { key: item._id, value: item.title };
                }}
              />
            </WithLightTitle>

            <WithLightTitle title="choisir une slide">
              <DropDown
                canBeReset={true}
                placeholder="choisir une slide..."
                items={slidesList ?? []}
                selectedItem={selectedSlide}
                onSelectItem={item => {
                  onSelectedSlide(item);
                }}
                keyValueExtractor={item => {
                  return { key: item._id, value: item.title };
                }}
              />
            </WithLightTitle>
          </div>

          <div style={{ marginTop: '1rem' }} />

          <WithLightTitle title="choisir les slides (.zip)">
            <FileInput
              key={keyInput}
              accept={'.zip'}
              multiFiles={true}
              titleNode={<div>choisir des slide (.zip) à uploader</div>}
              fileTargets={filesTargets}
              filesDataInput={selectedFiles}
              onChangeData={files => {
                onSelectedFiles(files);
              }}
              onFileTargetChange={(fileName, item) => {
                console.log(fileName);
              }}
            />
          </WithLightTitle>

          <div className={styles.blockRowSpaceBetween}>
            <div className={styles.blockWithBorder}>
              <div className={styles.titleBlock}>Downlaod</div>
              <div className={styles.blockRowSpaceBetweenWithMargin}>
                <div>
                  <Button.Secondary
                    onClick={() => {
                      downloads('csv');
                    }}
                  >
                    download csv
                  </Button.Secondary>
                </div>
                <div>
                  <Button.Secondary
                    style={{ marginLeft: '1rem' }}
                    onClick={() => {
                      downloads('file');
                    }}
                  >
                    download slides
                  </Button.Secondary>
                </div>
              </div>
            </div>

            <div className={styles.blockWithBorder}>
              <div className={styles.titleBlock}>Matching</div>
              <div className={styles.blockRowSpaceBetweenWithMargin}>
                <>
                  <DropDown
                    items={[
                      MATCHING_MODE_BY_SLIDE,
                      MATCHING_MODE_BY_INDEX,
                      MATCHING_MODE_BY_USER_INPUT,
                    ]}
                    keyValueExtractor={(searchResult: string) => ({
                      key: searchResult,
                      value: searchResult,
                    })}
                    canBeReset={true}
                    placeholder="matching mode"
                    selectedItem={matchingMode}
                    onSelectItem={item => setMatchingMode(item)}
                  />
                </>

                <Button style={{ marginLeft: '1rem' }} onClick={() => phase1_matching()}>
                  Matching
                </Button>
              </div>
            </div>

            <div className={styles.blockWithBorder}>
              <div className={styles.titleBlock}>Upload</div>
              <div className={styles.blockRowSpaceBetweenWithMargin}>
                <div style={{ marginTop: '1rem' }}>
                  <Checkbox
                    label={'update slide title'}
                    checked={updateSlideTitle}
                    onChange={event => onUpdateSlideTitle(event.target.checked)}
                  />
                </div>
                <Button
                  style={{ marginLeft: '1rem' }}
                  disabled={!canUploadSlides}
                  onClick={() => phase2_validation()}
                >
                  Upload
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.blockWithBorder}>
            <div className={styles.titleBlock}>Upload</div>
            <div className={styles.blockRowSpaceBetweenWithMargin}>
              <Button style={{ marginLeft: '1rem' }} onClick={() => onGetPrezStructure()}>
                Prez Structure
              </Button>
            </div>

            <div className={styles.blockRowSpaceBetweenWithMargin}>
              <Button style={{ marginLeft: '1rem' }} onClick={() => onDownloadLog()}>
                Download log
              </Button>
            </div>
          </div>
        </Modal>
      }

      {displayErrorOrInfo && (
        <MessageInfo
          //trigger={<Eye />}
          title={displayMessageTitle}
          message={displayMessageContent}
          titleDefaultButton={displayMessageTitleDefaultButton}
          waitingMode={uploadingInProgress}
          icon={<></>}
          defaultOpen={true}
          contentStyle={{
            maxWidth: '35rem',
          }}
          onClose={() => {
            setDisplayErrorOrInfo(false);
          }}
          onClickAction={() => {
            setDisplayErrorOrInfo(false);
            setStateComponent(StateComponent.DEFAULT);
          }}
        ></MessageInfo>
      )}
      {displayMessage && (
        <MessageInfo
          title={displayMessageTitle}
          message={displayMessageContent}
          titleDefaultButton={displayMessageTitleDefaultButton}
          waitingMode={uploadingInProgress}
          icon={<></>}
          defaultOpen={true}
          contentStyle={{
            maxWidth: '80rem',
          }}
          onClose={() => {
            setDisplayMessage(false);
          }}
          onClickAction={() => {
            setDisplayMessage(false);
            if (stateComponent === StateComponent.VALIDATION) {
              phase3_authentification();
            } else {
              setStateComponent(StateComponent.DEFAULT);
            }
          }}
        ></MessageInfo>
      )}

      {displayPassword && (
        <TextInput
          title={displayMessageTitle}
          titleDefaultButton={displayMessageTitleDefaultButton}
          icon={<></>}
          defaultOpen={true}
          contentStyle={{
            maxWidth: '40rem',
          }}
          onClose={() => {
            setDisplayPassword(false);
          }}
          onClickAction={(pass: string) => {
            setDisplayPassword(false);
            setPassword(pass);
            phase4_upload(pass);
          }}
        ></TextInput>
      )}
    </>
  );
};

export default AdminBO;
