import React from 'react';
import styles from './Video.module.scss';

interface Iprops {
  source: string;
}
function Video({ source }: Iprops) {
  return (
    <div className={styles['video__wrapper']} data-testid="video">
      <video controls controlsList="nodownload" disablePictureInPicture={true}>
        <source src={`${source}`} type="video/mp4" />
      </video>
    </div>
  );
}

export default Video;
