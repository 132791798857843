import { createSelector } from 'reselect';
import { RootState } from 'src/Redux/RootReducer';
import { ficheAgenceState } from './Types';

export const getFicheAgence = (state: RootState): ficheAgenceState => state.ficheAgence;

export const getTemplates = createSelector(
  [getFicheAgence],
  (FicheAgence: ficheAgenceState) => FicheAgence.templates
);

export const getSheets = createSelector(
  [getFicheAgence],
  (FicheAgence: ficheAgenceState) => FicheAgence.sheets
);
export const getLoading = createSelector(
  [getFicheAgence],
  (FicheAgence: ficheAgenceState) => FicheAgence.loading
);
export const getLoadingPrev = createSelector(
  [getFicheAgence],
  (FicheAgence: ficheAgenceState) => FicheAgence.loadingPrev
);
