import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICxp } from 'src/db/Types';

const initialState: ICxp = {
  _id: '',
  isDefault: false,
  name: '',
};

// CXP Slice
export const { reducer: selectedCxpReducer, actions: selectedCxpActions } = createSlice({
  name: 'cxp',
  initialState,
  reducers: {
    setSelectedCxp: (state: ICxp, action: PayloadAction<ICxp>) => {
      if (action.payload && action.payload._id) {
        state._id = action.payload._id;
        state.isDefault = action.payload.isDefault;
        state.name = action.payload.name;
      }
    },
  },
});
export default selectedCxpReducer;
