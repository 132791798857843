import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { serviceOptions } from 'src/Services/API';
import { Endpoint } from 'src/Utils/constants';
import { CxpState, Cxp, IOptionType } from './Types';

const initialState: CxpState = {
  data: [],
  loading: true,
  userCxpNb: 0,
  defaultCxpNb: 0,
  selectCxpNb: 0,
  selectCxpList: [],
};

// Réqueperation de la liste des CXPs
export const loadCxp = createAsyncThunk('cxp/loadCxp', async () => {
  return await serviceOptions.axios?.get(`${Endpoint.cxps}`).then(res => res.data);
});

// Action for CXp
// export const selectedCxp = createAction('cxp/selected_cxp');

// CXP Slice
export const { reducer: cxpReducer, actions: cxpActions } = createSlice({
  name: 'cxp',
  initialState,
  reducers: {
    setUserCxpNb: (state: CxpState, action: PayloadAction<{ nb: number }>) => {
      state.userCxpNb = action.payload.nb;
    },
    setDefaultCxpNb: (state: CxpState, action: PayloadAction<{ nb: number }>) => {
      state.defaultCxpNb = action.payload.nb;
    },
    setSelectCxpNb: (state: CxpState, action: PayloadAction<{ nb: number }>) => {
      state.selectCxpNb = action.payload.nb;
    },
    setSelectCxpList: (state: CxpState, action: PayloadAction<IOptionType[]>) => {
      state.selectCxpList = action.payload;
    },
  },
  extraReducers: {
    [loadCxp.fulfilled.toString()]: (state: CxpState, action: PayloadAction<Cxp[]>) => {
      const cxps = action.payload;
      state.data = cxps;
      state.loading = false;
      state.userCxpNb = 0;
      state.defaultCxpNb = 0;
      state.selectCxpNb = 0;
    },
    [loadCxp.pending.toString()]: (state: CxpState) => {
      state.loading = true;
    },
    [loadCxp.rejected.toString()]: (state: CxpState) => {
      state.loading = false;
      state.data = [];
      state.userCxpNb = 0;
      state.defaultCxpNb = 0;
      state.selectCxpNb = 0;
    },
  },
});
export default cxpReducer;
