import { createSelector } from 'reselect';
import { RootState } from 'src/Redux/RootReducer';
import { AuthState } from './Types';

export const getAuthState = (state: RootState): AuthState => state.auth;

export const getUserCxp = createSelector([getAuthState], (AuthState: AuthState) => [
  ...AuthState.userCexps,
  ...AuthState.defaultCexps,
]);
export const getInfos = createSelector([getAuthState], (AuthState: AuthState) => AuthState);
