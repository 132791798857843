import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import history from 'src/Utils/history';
import { db } from 'src/db';

import { getSelectedCxp } from 'src/Redux/SelectedCxp/Selectors';
import PresentationDetail from './PresentationDetail.component';
import { RouteComponentProps } from 'react-router-dom';
import { IFile, ISlide, IPresentation, NewISlideFile } from 'src/db/Types';
import { routeParams } from './PresentationDetail.types';
import Loading from 'src/Components/Loading';
import { getAuthState } from 'src/Redux/Auth/Selectors';
import { db_blob2Base64 } from 'src/Utils/common';

const PresentationDetailMemo = React.memo(PresentationDetail);

const EnhancedPresentationDetail = ({ match }: RouteComponentProps<routeParams>) => {
  const [loading, setLoading] = useState(true);
  const [presentation, setPresentation] = useState<IPresentation | undefined>();
  const [slides, setSlides] = useState<(ISlide | IFile)[]>([]);
  const [countSlides, setCountSlides] = useState<number>(0);
  const selectedCxp = useSelector(getSelectedCxp);
  const user = useSelector(getAuthState);

  useEffect(() => {
    loadPresentation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * requperation de la presentation en question
   */
  const loadPresentation = async () => {
    const presentation: IPresentation | undefined = await db.presentations
      ?.where('_id')
      .equals(match.params.id)
      .first();

    if (presentation) {
      setPresentation(presentation);
      const slides2: (ISlide | IFile)[] | undefined = await db.slides
        ?.where('_id')
        .anyOf(presentation.slides)
        .toArray();

      let slides: (ISlide | IFile)[] = [];

      if (slides2) {
        for (let i = 0; i < presentation.slides.length; i++) {
          const _id = presentation.slides[i];
          for (let index = 0; index < slides2.length; index++) {
            if (_id === slides2[index]._id) {
              slides[i] = slides2[index];
              break;
            }
          }
        }
      }

      if (slides) {
        // add files to slides
        for (let index = 0; index < slides.length; index++) {
          const slide: ISlide = slides[index] as ISlide;
          const file: IFile[] | undefined = await db.files
            ?.where('_id')
            .equals(slide.zipFile._id)
            .toArray();
          if (file && file?.length > 0) {
            slides[index].file = file[0].file;
          }
        }
        // add associative files prez
        const idfiles = _.map(presentation.files, file => file._id);
        const files: IFile[] | undefined = await db.files?.where('_id').anyOf(idfiles).toArray();

        if (files && files.length > 0) {
          slides = [...slides, ...files];
        }
        // find fiche agence diapo and replace values
        if (slides && slides.length > 0) {
          const sheets = await db.sheets?.toArray();
          if (sheets && sheets?.length > 0) {
            _.forEach(slides, (slide, index) => {
              if (slide && 'sheetTemplate' in slide) {
                const idSheet = slide.sheetTemplate;
                const cxp = selectedCxp._id;
                const sheet = sheets.find(
                  sheet => sheet.sheetTemplate === idSheet && sheet.cexp === cxp
                );
                if (sheet && sheet.metas) {
                  if (slides && slides[index] && slides[index].file) {
                    let slideCopy = slides && (slides[index].file as NewISlideFile).slideHTML;
                    _.forEach(sheet.metas, meta => {
                      const key = `_${meta.key}_`;
                      if (slideCopy) {
                        slideCopy = slideCopy && slideCopy.replace(key, meta.value);
                      }
                    });
                    //fix wording issue for all 'voter agence' slides
                    if (slideCopy) {
                      slideCopy =
                        slideCopy && slideCopy.replace(`notre implatation`, `notre implantation`);
                    }
                    if (slides) {
                      (slides[index].file as NewISlideFile).slideHTML = slideCopy as string;
                    }
                  }
                }
              }
            });
          }
        }

        // <md5
        for (let index = 0; index < slides.length; index++) {
          const slide: ISlide = slides[index] as ISlide;
          if (!slide) continue;
          if (!slide.file) continue;

          //case file attached to prez
          if (!slide.file.slideHTML) {
            const filePdf = slide.file;
            if (filePdf && filePdf?.md5s && filePdf?.md5s[0]) {
              const md5 = filePdf?.md5s[0];
              const info = await db.md5file?.where('md5').equals(md5).first();
              //<md5blob
              if (info && info.blob) {
                const content = await db_blob2Base64(info?.blob);
                info.content = content;
                console.log('content of md5 from blob:');
              }
              //>
              if (info?.content) {
                const _slide: IFile = slides[index] as IFile;
                const data = 'data:' + _slide?.mimetype + ';base64,' + info?.content;
                _slide.file = data;
              }
            }
            continue;
          }

          const md5s = slide.file?.md5s ? slide.file?.md5s : [];
          for (let i = 0; i < md5s?.length; i++) {
            const md5 = md5s[i];
            const doc = await db.md5file?.where('md5').equals(md5).first();
            //<md5blob
            if (doc && doc.blob) {
              const content = await db_blob2Base64(doc?.blob);
              doc.content = content;
              console.log('content of md5 from blob:');
            }
            //>

            let slideHTML = (slide.file as NewISlideFile).slideHTML;
            slideHTML = slideHTML.replace(`base64/md5,${md5}`, `base64,${doc?.content}`);
            slide.file.slideHTML = slideHTML;
          }
          //PIVOTS
          for (let p = 0; p < slide.file.pivots.length; p++) {
            const pSlide = slide.file.pivots[p];
            if (!pSlide) continue;
            if (!pSlide.slide) continue;
            const md5s = pSlide.md5s;

            for (let k = 0; k < md5s?.length; k++) {
              const md5 = md5s[k];
              const doc = await db.md5file?.where('md5').equals(md5).first();

              //<md5blob
              if (doc && doc.blob) {
                const content = await db_blob2Base64(doc?.blob);
                doc.content = content;
                console.log('content of md5 from blob:');
              }
              //>

              if (!doc?.content) {
                console.log('content of md5 not found:', md5);
              } else {
                let slideHTML = pSlide.slide;
                slideHTML = slideHTML.replace(`base64/md5,${md5}`, `base64,${doc?.content}`);
                pSlide.slide = slideHTML;
              }
            }
          }
        }
        // md5>

        setSlides(slides);
        setCountSlides(slides.length);
        setLoading(false);
      }
    } else {
      // presentation not found
      history.push('/');
    }
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <PresentationDetailMemo
      user={user}
      presentation={presentation}
      slides={slides}
      countSlides={countSlides}
    />
  );
};

export default EnhancedPresentationDetail;
