import React, { useState } from 'react';
import styles from './Dropdown.module.scss';
import { IDropDown, IItemDropdown } from './Dropdown.types';

const Dropdown = ({ title, list, selectedItem, onchange }: IDropDown) => {
  const [isListOpen, setIsListOpen] = useState<boolean>(false);

  function selectItem(item: IItemDropdown) {
    setIsListOpen(false);
    onchange(item);
  }
  function toggleList() {
    setIsListOpen(!isListOpen);
  }
  return (
    <div className={styles['dd-wrapper']}>
      <button
        data-testid="btn-dropdown"
        type="button"
        className={`${styles['dd-header']} ${isListOpen ? styles['dd-header-open'] : ''} `}
        onClick={toggleList}
      >
        <div className={styles['dd-header-title']}>
          <span>{selectedItem ? selectedItem.label : title}</span>
        </div>
      </button>
      {isListOpen && (
        <div role="list" className={styles['dd-list']} data-testid="dropdown-options">
          {list.map(item => (
            <button
              type="button"
              className={`${styles['dd-list-item']} ${
                selectedItem && item.id === selectedItem.id ? styles['dd-list-item-active'] : ''
              } `}
              key={item.id}
              onClick={() => selectItem(item)}
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
