import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import styles from './PreviewAgence.module.scss';
import { IPreviewProps } from '../FicheAgence.types';
import { ErrorMsg } from 'src/Utils/constants';
import loaderIcon from 'src/Assets/loader_blue.gif';
import Modal from 'src/Components/Modal';

const PreviewAgence: React.FC<IPreviewProps> = ({
  slide,
  handleClose,
  dataForm,
  template,
  loadingPrev,
}) => {
  const [dataSlide, setDataSlide] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (slide && template && dataForm) {
      prepareSlide();
    }
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm, slide, template]);

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  function formatNameVariable(name: string, i: number) {
    return name.replace(' ', '_').concat(i.toString());
  }
  function removeTagHeader(slideHtml: string) {
    return slideHtml.toString().replace(/<header(.|\n)*?<\/header>/g, ' ');
  }

  function prepareSlide() {
    setLoading(true);
    const slideHtml = slide?.file?.slideHTML;
    if (slideHtml) {
      let newSlideHtml = slideHtml;
      _.forEach(template?.keys, (key, index) => {
        const name = formatNameVariable(key.name, index).toString();
        if (dataForm[name]) {
          const value = dataForm[name].replace('\n', '<br />');
          if (value) {
            newSlideHtml = newSlideHtml.replace(`_${key.name}_`, value);
          } else {
            newSlideHtml = newSlideHtml.replace(`_${key.name}_`, '');
          }
        }
      });
      newSlideHtml = removeTagHeader(newSlideHtml);
      setDataSlide(newSlideHtml);
    }
    setLoading(false);
  }

  return (
    <Modal onClose={() => handleClose()}>
      <div className={styles['preview_agence_inner']}>
        <div
          className={styles['close-corner']}
          onClick={() => handleClose()}
          data-testid="closePreview"
        ></div>
        {loadingPrev ? (
          <div className={styles['loadingForm']}>
            <img
              src={loaderIcon}
              alt="loader"
              style={{ width: '35px', display: 'inline-block', margin: '0 auto ' }}
            />
          </div>
        ) : (
          <React.Fragment>
            {template && dataSlide && !loading ? (
              <React.Fragment>
                <iframe
                  frameBorder="0"
                  className={styles['iframe']}
                  title="iframe"
                  srcDoc={dataSlide}
                />
              </React.Fragment>
            ) : (
              <div className={styles['preview_agence_error']}>{ErrorMsg}</div>
            )}
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
};

export default PreviewAgence;
