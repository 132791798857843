import React from 'react';
import history from 'src/Utils/history';

import styles from './Offline.module.scss';

function Offline() {
  return (
    <div className={styles['error_wrapper']}>
      <div className={styles['offline']}>
        <span>la consultation de cet écran n'est pas possible hors connexion</span>
        <button className="button button--m" onClick={() => history.push('/')}>
          retour à la homepage
        </button>
      </div>
    </div>
  );
}

export default Offline;
