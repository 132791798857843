/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';

import { useKey } from 'src/Utils/keybordnav';
import moment from 'src/Utils/moment';

import Video from 'src/Components/Video';
import Image from 'src/Components/Image';
import Pdf from 'src/Components/Pdf';

import { IPreviewDoc } from './PreviewDoc.Types';
import { slideNotFoundMsg } from 'src/Utils/constants';

import styles from './PreviewDoc.module.scss';

import shareWhite from 'src/Assets/preview/shareWhite.svg';
import arrowRightwhite from 'src/Assets/detail/arrowRightwhite.svg';
import closeWhite from 'src/Assets/documents/closeWhite.svg';
import Share from 'src/Containers/Home/Documents/Share/index';
import Tooltip from 'src/Components/Tooltip';
import ReactGA from 'react-ga4';

function PreviewDoc({ user, handleClose, documents, loading, idDoc }: IPreviewDoc) {
  const [currentDocIndex, setCurrentDocIndex] = useState(0);
  const refButtonNext = useRef<HTMLButtonElement | null>(null);
  const refButtonPrev = useRef<HTMLButtonElement | null>(null);
  const [isShare, setIsShare] = useState<boolean>(false);

  useEffect(() => {
    if (idDoc && documents.length > 0) {
      const doc = documents.findIndex(doc => doc._id === idDoc);
      if (doc !== -1) {
        setCurrentDocIndex(doc);
        console.log(`ReactGA:Selection Doc:(${user?.agence}):`, documents[doc]?.title);
        ReactGA.event('Selection Doc', {
          docName: documents[doc]?.title,
          brand: user?.brand?.name,
          region: user?.region,
          zone: user?.zone,
          agence: user?.agence,
        });
      }
    }
  }, [idDoc, documents]);

  useKey('ArrowRight', () => {
    if (_.has(documents[currentDocIndex].file, 'mimetype')) {
      if (documents[currentDocIndex].file.mimetype === 'application/pdf') {
        return false;
      }
      handleNext();
    } else {
      handleNext();
    }
  });

  useKey('ArrowLeft', () => {
    if (_.has(documents[currentDocIndex].file, 'mimetype')) {
      if (documents[currentDocIndex].file.mimetype === 'application/pdf') {
        return false;
      }
      handlePrev();
    } else {
      handlePrev();
    }
  });

  useKey('Escape', () => {
    handleClose();
  });
  function handleNext() {
    if (documents) {
      if (currentDocIndex !== documents.length - 1) {
        console.log(
          `ReactGA:Selection Doc:(${user?.agence}):`,
          documents[currentDocIndex + 1]?.title
        );
        ReactGA.event('Selection Doc', {
          docName: documents[currentDocIndex + 1]?.title,
          brand: user?.brand?.name,
          region: user?.region,
          zone: user?.zone,
          agence: user?.agence,
        });
        setCurrentDocIndex(currentDocIndex + 1);
      }
    }
  }
  function handlePrev() {
    if (currentDocIndex !== 0) {
      console.log(
        `ReactGA:Selection Doc:(${user?.agence}):`,
        documents[currentDocIndex - 1]?.title
      );
      ReactGA.event('Selection Doc', {
        docName: documents[currentDocIndex - 1]?.title,
        brand: user?.brand?.name,
        region: user?.region,
        zone: user?.zone,
        agence: user?.agence,
      });
      setCurrentDocIndex(currentDocIndex - 1);
    }
  }

  function renderHeader() {
    return (
      <div className={styles['header_prez']}>
        <div className={styles['header_prez_left']}>
          <ul>
            <li>
              <button onClick={() => handleClose()} data-testid="closePreview">
                <img src={closeWhite} alt="close" />
              </button>
            </li>
          </ul>
          <h2>
            {documents && documents[currentDocIndex] ? documents[currentDocIndex].title : null}{' '}
            <span>
              màj :{' '}
              {documents &&
                documents[currentDocIndex] &&
                moment(documents[currentDocIndex].file?.updatedAt).format('LL')}
            </span>
          </h2>
        </div>
        <div className={styles['header_prez_right']}>
          <ul className={styles['chevron']}>
            <li className={styles['left']}>
              <button
                ref={refButtonPrev}
                onClick={() => handlePrev()}
                disabled={currentDocIndex === 0}
              >
                {currentDocIndex !== 0 ? (
                  <Tooltip
                    content={documents ? documents[currentDocIndex - 1]?.title : ' '}
                    direction="left"
                  >
                    <img src={arrowRightwhite} alt="arrow" />
                  </Tooltip>
                ) : (
                  <img src={arrowRightwhite} alt="arrow" />
                )}
              </button>
            </li>
            <li className={styles['right']}>
              <button
                ref={refButtonNext}
                onClick={() => handleNext()}
                disabled={documents && currentDocIndex === documents.length - 1}
              >
                {currentDocIndex !== documents.length - 1 ? (
                  <Tooltip
                    content={documents ? documents[currentDocIndex + 1]?.title : ' '}
                    direction="left"
                  >
                    <img src={arrowRightwhite} alt="arrow" />
                  </Tooltip>
                ) : (
                  <img src={arrowRightwhite} alt="arrow" />
                )}
              </button>
            </li>
          </ul>
          <ul>
            {documents &&
            documents[currentDocIndex]?.shareable &&
            documents[currentDocIndex]?.file?.mimetype.indexOf('video') === -1 ? (
              <li>
                <button
                  onClick={() => {
                    setIsShare(true);
                  }}
                >
                  <Tooltip content="partager" direction="bottom">
                    <img src={shareWhite} alt="share" />
                  </Tooltip>
                </button>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    );
  }
  return (
    <React.Fragment>
      {isShare && (
        <Share
          doc={documents[currentDocIndex] ? documents[currentDocIndex] : undefined}
          handleCancelShareDocForum={() => setIsShare(false)}
        />
      )}
      <div className={`${styles['preview']}`} data-testid="preview">
        {renderHeader()}
        <div className={styles['content']}>
          {!loading ? (
            <React.Fragment>
              {documents && documents[currentDocIndex] && documents[currentDocIndex].file ? (
                <React.Fragment>
                  {!loading ? (
                    <React.Fragment>
                      {documents[currentDocIndex] &&
                        documents[currentDocIndex].file?.mimetype.indexOf('pdf') !== -1 && (
                          <Pdf source={`${documents[currentDocIndex].file?.file}`} />
                        )}
                      {documents[currentDocIndex] &&
                        documents[currentDocIndex].file?.mimetype.indexOf('video') !== -1 && (
                          <Video source={`${documents[currentDocIndex].file?.file}`} />
                        )}
                      {documents[currentDocIndex] &&
                        documents[currentDocIndex].file?.mimetype.indexOf('image') !== -1 && (
                          <Image source={`${documents[currentDocIndex].file?.file}`} />
                        )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className={styles['fileNotFound']}>{slideNotFoundMsg}</div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className={styles['fileNotFound']}>{slideNotFoundMsg}</div>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
}

export default PreviewDoc;
