import React from 'react';

import { Auth0Provider } from './auth0-wrapper';
import history from 'src/Utils/history';
import { removeToken } from 'src/Utils/auth';

const onRedirectCallback = () => {
  removeToken();
  history.push('/');
  // Nothing to do here, redirection logic is handled by the Callback component
};

const Provider = (props: { children: React.ReactElement }) => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}
    client_id={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
    redirect_uri={process.env.REACT_APP_AUTH0_REDIRECT_URI || ''}
    onRedirectCallback={onRedirectCallback}
    cacheLocation={'localstorage'}
    useRefreshTokens={true}
  >
    {props.children}
  </Auth0Provider>
);

export default Provider;
