import { AxiosError, AxiosRequestConfig } from 'axios';
import { logout } from 'src/Providers/Auth0';
import { getToken } from 'src/Utils/auth';
import history from 'src/Utils/history';

export async function requestInterceptor(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  const tokenResult = await getToken();
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `${tokenResult}`,
    },
  };
}

export async function errorInterceptor(error: AxiosError): Promise<AxiosError> {
  const { response, request, message, config } = error;
  console.log(config);
  if (response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(response.data);
    console.log(response.status);
    console.log(response.headers);
    // eslint-disable-next-line no-magic-numbers
    if (config && response.status === 401) {
      logout();
      history.push('/');
    }
  } else if (request) {
    // The request was made but no response was received
    // `request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', message);
  }

  // Either throw or return a rejected promise, will resolve in then otherwise
  return Promise.reject(error);
}
