/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/no-explicit-any*/
/* eslint-disable @typescript-eslint/no-unused-vars*/
import _ from 'lodash';
import { ICxp, ISlide, IPresentation, ISSlide } from 'src/db/Types';
import { ISlideToDownload, ISlideToUpload } from './../AdminBO/AdminBO.types';
import { db } from 'src/db';

interface IRecette2Prod {
  recette: string;
  prod: string;
}

const recette2Prod: IRecette2Prod[] = [
  { recette: 'la sécurité au travail, un enjeu majeur', prod: 'la sécurité un enjeu majeur' },
  { recette: 'savoir faire randstad', prod: 'tech & touch' },
  { recette: 'une expérience RH enrichie', prod: 'tech & touch' },
  { recette: 'Youplan - relation tripartite', prod: 'youplan' },
  { recette: 'la roue de la sécurité', prod: 'la roue de la prévention' },
  { recette: 'étude de poste', prod: 'etude de poste' },
  { recette: 'le diagnostic sécurité', prod: 'diagnostic sécurité' },
  { recette: 'fiche accueil', prod: "fiche d'accueil au poste" },
  { recette: 'livret sécurité', prod: "livret d'accueil sécurité" },
  { recette: 'synthèse déroulement de missions', prod: 'synthèse déroulement mission' },
  { recette: 'groupe _ chaque besoin un service', prod: 'groupe : chaque besoin un service' },
  { recette: 'votre agence', prod: 'votre agence rh' },
  { recette: 'évaluer - outils', prod: 'évaluer-outils' },
  // "livret sécurité" ==> pas de corespoandance  (prevention)
];

const convertFileName2SlideToUpload = (file: File) => {
  const fileName = file.name;
  const names = fileName.split('@');

  const cxpIdx = 0;
  const prezIdx = 1;
  const indexIdx = 2;
  const slideIdx = 3;
  const fileNameIdx = 4;

  const slideToUpload: ISlideToUpload = {
    cxpName: names.length === fileNameIdx + 1 ? names[cxpIdx].normalize() : undefined,
    presnetationTitle: names.length === fileNameIdx + 1 ? names[prezIdx].normalize() : undefined,
    slideTitle: names.length === fileNameIdx + 1 ? names[slideIdx].normalize() : undefined,
    index: names.length === fileNameIdx + 1 ? parseInt(names[indexIdx], 10) : undefined,
    fileName: names.length === fileNameIdx + 1 ? names[fileNameIdx].normalize() : fileName,
    file: file,
  };
  return slideToUpload;
};

export const getPresentationsOfCxp = async (cxp: ICxp | undefined) => {
  // Filtrer presentations by cexp
  const allPresentations = await db.presentations?.toArray();
  const listPrezFiltred: IPresentation[] = _.filter(allPresentations, prez => {
    return _.some(prez.cexps, cxp);
  });

  const list = [
    'découverte',
    'recrutement',
    'intérim',
    'sourcing',
    'prévention',
    'outils',
    'groupe',
  ];
  let listOrdered: IPresentation[] = [];
  const listOrdered2: IPresentation[] = [];
  _.forEach(listPrezFiltred, item => {
    if (_.includes(list, item.title.toLowerCase())) {
      listOrdered[_.indexOf(list, item.title.toLowerCase())] = item;
    } else {
      listOrdered2.push(item);
    }
  });
  listOrdered = listOrdered.filter(item => item);
  const presentations = [...listOrdered, ...listOrdered2];
  return presentations;
};

export const getSlidesOfPresentation = async (
  cxp: ICxp | undefined,
  presentation: IPresentation | undefined
) => {
  if (presentation) {
    const slides: ISlide[] | undefined = await db.slides
      ?.where('_id')
      .anyOf(presentation.slides)
      .toArray();
    if (!slides) {
      return [];
    } else {
      const slides3: ISlide[] = [];

      if (slides) {
        for (let i = 0; i < presentation.slides.length; i++) {
          const _id = presentation.slides[i];
          for (let index = 0; index < slides.length; index++) {
            if (_id === slides[index]._id) {
              slides3[i] = slides[index];
              break;
            }
          }
        }
      }

      return slides3;
    }
  } else {
    return [];
  }
};

//const csvData: string[] = [];

let csvString = '';
//const csvObject = () => {};

const csvInit = () => {
  csvString = 'cxp,présentation,slide,file,size,uid,updated at\n';
};

const csvAdd = (cxp: ICxp, prez: IPresentation, slide: ISlide) => {
  csvString =
    csvString +
    `${cxp.name},${prez.title},${slide.title},${slide.zipFile.filename},${slide.zipFile.size},${slide.zipFile.uid},${slide.updatedAt}\n`;
};

const csvClose = () => {
  console.log('csv file  ----  debut');
  console.log(csvString);
  console.log('csv file  ----  end');
};

//
const normalizeEnvSlideTitle = (title: string | undefined) => {
  if (!title) {
    return undefined;
  }
  const titleTrimed = title.trim().normalize().toLowerCase();
  const words = titleTrimed.split(' ');

  if (words.length <= 1) {
    return titleTrimed;
  } else {
    const lastWord = words[words.length - 1];
    if (lastWord.length <= 4 && lastWord !== 'rh') {
      //remove the last word
      const lastIndex = titleTrimed.lastIndexOf(' ');
      const result = titleTrimed.substring(0, lastIndex);
      return result.trim();
    } else {
      return titleTrimed;
    }
  }
};

const computeSlideTitleForRecetteEnv = (slideToUpload: ISlideToUpload) => {
  slideToUpload.slideTitleEnvNormalized = normalizeEnvSlideTitle(slideToUpload.slideTitle);

  const results = recette2Prod.filter(
    item => item.recette.normalize().toLowerCase() === slideToUpload.slideTitleEnvNormalized
  );
  if (results && results.length > 0) {
    slideToUpload.slideTitleEnvNormalized = results[0].prod.normalize().toLowerCase();
  }
};

const computeSlideTitleForProdEnv = (slidesToDownload: ISlideToDownload) => {
  slidesToDownload.slideTitleEnvNormalized = normalizeEnvSlideTitle(slidesToDownload.slide.title);
};

let _testUnit_nb_failed = 0;
let _testUnit_nb_pass = 0;
const _testUnit_normalizeEnvSlideTitle = (input: string, output: string) => {
  const outputFct = normalizeEnvSlideTitle(input);
  console.log(
    `normalizeEnvSlideTitle(${input}) = ${outputFct}==>:`,
    outputFct !== output ? 'failed' : 'Pass'
  );
  outputFct !== output ? _testUnit_nb_failed++ : _testUnit_nb_pass++;
};

const _testunit_computeSlideTitleForRecetteEnv = (input: string, output: string) => {
  const slideToUpload: ISlideToUpload = {
    file: new File(['zzz', 'zzz'], 'empty.txt'),
    fileName: 'empty.txt',
    slideTitle: input,
  };
  computeSlideTitleForRecetteEnv(slideToUpload);
  console.log(
    `computeSlideTitleForRecetteEnv(${input})==>:`,
    slideToUpload.slideTitleEnvNormalized !== output ? 'failed' : 'Pass'
  );
  slideToUpload.slideTitleEnvNormalized !== output ? _testUnit_nb_failed++ : _testUnit_nb_pass++;
};

const testUnitaireCheck = () => {
  console.log('---- test unit ----');
  _testUnit_normalizeEnvSlideTitle('text1 text2  ', 'text1 text2');
  _testUnit_normalizeEnvSlideTitle('text1 text2  dd  ', 'text1 text2');
  _testUnit_normalizeEnvSlideTitle('tex       ee', 'tex');
  _testUnit_normalizeEnvSlideTitle('tex', 'tex');
  _testUnit_normalizeEnvSlideTitle('Tex   holla    uu', 'tex   holla');
  _testUnit_normalizeEnvSlideTitle('Votre agence RH', 'votre agence rh');

  _testunit_computeSlideTitleForRecetteEnv(
    'la sécurité au travail, un enjeu majeur  er',
    'la sécurité un enjeu majeur'
  );
  _testunit_computeSlideTitleForRecetteEnv('Youplan - relation tripartite ee', 'youplan');

  const total = _testUnit_nb_failed + _testUnit_nb_pass;
  console.log(`__testunit__: pass   ${_testUnit_nb_pass}/${total}`);
  console.log(`__testunit__: failed ${_testUnit_nb_failed}/${total}`);
};

export const csv = Object.assign(csvInit, { csvInit, csvAdd, csvClose });
export const util = Object.assign(getPresentationsOfCxp, {
  getPresentationsOfCxp,
  getSlidesOfPresentation,
  convertFileName2SlideToUpload,
  computeSlideTitleForRecetteEnv,
  computeSlideTitleForProdEnv,
  testUnitaireCheck,
});
