import { createSelector } from 'reselect';
import { RootState } from 'src/Redux/RootReducer';
import { syncLoading } from './Types';

export const getSyncLoadingState = (state: RootState): syncLoading => state.syncLoading;

export const getSyncLoading = createSelector(
  [getSyncLoadingState],
  (syncLoading: syncLoading) => syncLoading
);

export default getSyncLoadingState;
