import * as _ from 'lodash';
import moment from 'moment';

import { AuthState } from 'src/Redux/Auth/Types';
import { sync } from 'src/Redux/Sync/Types';

import { db } from 'src/db';
import { syncWithErrorMsg, syncMsg } from 'src/Utils/constants';

/**
 * CheckUpdate
 * @param infos
 * @param syncState
 */
export const checkUpdate = (infos: AuthState | undefined, syncState: sync) => {
  return new Promise(resolve => {
    // 1.first sync
    const { syncEndWithError, lastSync } = syncState;
    if (lastSync === '') {
      console.log('check update: lastsync not set yet');
      resolve({
        sync: true,
        msg: syncMsg,
      });
    }
    // 2. backend update
    if (infos && infos.lastContentUpdate !== '' && lastSync !== '') {
      const lastContentUpdate = moment(infos.lastContentUpdate);
      if (lastContentUpdate > moment(lastSync)) {
        console.log('check update: backend update time > lastsync time');
        resolve({
          sync: true,
          msg: syncMsg,
        });
      }
    }
    // 3. sync end with error or canceled
    if (syncEndWithError) {
      console.log('check update: lastsync end with error');
      resolve({
        sync: true,
        msg: syncMsg,
      });
    }
    // 4. NB Required files !== nb download files
    filesToDonwload().then(filesToDonwload => {
      console.log('check update to download: ', filesToDonwload);
      if (filesToDonwload !== 0) {
        resolve({
          sync: true,
          msg: syncWithErrorMsg,
        });
      } else {
        console.log('check update: no update available');
        resolve({
          sync: false,
          msg: '',
        });
      }
    });
  });
};

/**
 * checkUpdateFrequence
 */
export function checkUpdateFrequence() {
  const CHECK_UPDATE_FREQUENCE = parseInt(`${process.env.REACT_APP_CHECK_UPDATE_FREQUENCE}`);
  const now = moment(new Date().toISOString());
  const expireTimeCheck = localStorage.getItem('puzzle-checkUpdate');
  if (expireTimeCheck) {
    const duration = moment.duration(now.diff(moment(expireTimeCheck)));
    if (parseInt(duration.asMinutes().toFixed(0)) > CHECK_UPDATE_FREQUENCE) {
      console.log('check update: check expire time : true');
      localStorage.setItem('puzzle-checkUpdate', new Date().toISOString());
      return true;
    } else {
      console.log('check update: check expire time : false');
      return false;
    }
  } else {
    console.log('check update: set expire time');
    const expireDate = now.add(CHECK_UPDATE_FREQUENCE, 'minutes');
    localStorage.setItem('puzzle-checkUpdate', expireDate.toISOString());
    return true;
  }
}
/**
 * Get Files to download
 */
async function filesToDonwload() {
  // 1. get slides IDs
  const idsFilesSlides: Array<string> = [];
  await db.slides?.each(slide => {
    const file = slide.zipFile;
    idsFilesSlides.push(file._id);
  });

  // 2. get files prez IDs
  const idsFilesPrez: Array<string> = [];
  await db.presentations?.each(slide => {
    const files = slide.files;
    if (files.length > 0) {
      _.forEach(files, file => {
        idsFilesPrez.push(file._id);
      });
    }
  });

  // 3. get files documents IDs
  const idsFilesDocuments: Array<string> = [];
  await db.documents?.each(document => {
    const file = document.file;
    if ('_id' in file) {
      idsFilesDocuments.push(file._id);
    }
  });

  // 4. merge all tables IDs
  const filesToDownloadAll = [...idsFilesPrez, ...idsFilesSlides, ...idsFilesDocuments];

  // 5. get all files in indexeddb
  const filesIDs: Array<string> = [];
  await db.files?.each(item => filesIDs.push(item._id));

  // 6. find file to download
  // TODO delete static filter
  const toDownload = _.difference(filesToDownloadAll, filesIDs).filter(
    item => item !== '600022b3b9c1d606f18debc6'
  );

  return toDownload.length;
}
