export interface IFileTarget {
  slideId: string;
  slideTitle: string;
  slideCompleteTitle: string;
}

export interface IFileData {
  key: string;
  file: File;
  base64Content?: string;
  target?: IFileTarget;
  slideTitle?: string;
}

export interface Props extends React.HTMLProps<HTMLInputElement> {
  titleNode?: React.ReactNode;
  multiFiles?: boolean;
  displayMode?: string;
  fileTargets: IFileTarget[];
  convertToBase64?: boolean;
  filesDataInput: IFileData[];
  onChangeData: (data: IFileData[]) => void;
  onFileTargetChange: (fileName: string, fileTarget: IFileTarget | undefined) => void;
}

export const defaultProps = {
  displayMode: 'LIST',
  multiFiles: false,
  convertToBase64: false,
  filesDataInput: [],
};
