import React from 'react';
import _ from 'lodash';

import { IDocByCategoryComp } from './DocByCategory.Types';

import Modal from 'src/Components/Modal';
import PreviewDoc from '../PreviewDoc';

import { ReactComponent as VideoIcon } from 'src/Assets/detail/play-circle.svg';
import { ReactComponent as PdfIcon } from 'src/Assets/detail/document.svg';
import { ReactComponent as ImageIcon } from 'src/Assets/detail/image.svg';
import closeIcon from 'src/Assets/documents/close.svg';
import searchIcon from 'src/Assets/icons/search.svg';
import HeaderIconDoc from 'src/Assets/documents/HeaderIconDoc.svg';

import styles from './DocByCategory.module.scss';

function DocByCategory({
  isPreview,
  docToPreview,
  handleClosePreview,
  documentsFiltred,
  handleCancelDocByCategory,
  category,
  onChangeSearchInput,
  documentsFiltredNb,
  documentsSearch,
  onClickDoc,
}: IDocByCategoryComp) {
  return (
    <React.Fragment>
      {isPreview && docToPreview ? (
        <PreviewDoc
          handleClose={handleClosePreview}
          idDoc={docToPreview._id}
          listDocs={documentsFiltred}
        />
      ) : (
        <Modal onClose={handleCancelDocByCategory}>
          <div className={styles['doc__sync']}>
            <div className={styles['doc__header']}>
              <img src={HeaderIconDoc} className={styles['icon']} alt="icon" />
              <img
                src={closeIcon}
                alt="close"
                onClick={handleCancelDocByCategory}
                className={styles['close']}
              />
            </div>
            <div className={styles['doc__sync__inner']}>
              <div className={styles['doc__title']}>
                <span className={styles['doc__title__name']}>{category?.name}</span>
              </div>

              <div className={styles['doc__block']}>
                {documentsFiltred.length > 6 && (
                  <div className={styles['inputWithIcon']}>
                    <input
                      type="text"
                      onChange={e => onChangeSearchInput(e)}
                      placeholder="Recherche ..."
                      className={styles['inputSearch']}
                    />
                    <img src={searchIcon} className={styles['inputIcon']} alt="img" />
                  </div>
                )}
                <span className={styles['documentsFiltredNb']}>
                  Liste des documents ({documentsFiltredNb})
                </span>
                <ul>
                  {_.map(documentsSearch, (dc, index) => {
                    return (
                      <li onClick={() => onClickDoc(dc)} key={index}>
                        {dc.file.mimetype.indexOf('video') !== -1 ? (
                          <div>
                            <div className={styles['doc__icon_zone']}>
                              <VideoIcon className={styles['doc__icon']} />
                            </div>
                          </div>
                        ) : null}
                        {dc.file.mimetype.indexOf('image') !== -1 ? (
                          <div>
                            <div className={styles['doc__icon_zone']}>
                              <ImageIcon className={styles['doc__icon']} />
                            </div>
                          </div>
                        ) : null}
                        {dc.file.mimetype.indexOf('pdf') !== -1 ? (
                          <div>
                            <div className={styles['doc__icon_zone']}>
                              <PdfIcon className={styles['doc__icon']} />
                            </div>
                          </div>
                        ) : null}
                        <span className={styles['doc__name']}>{dc.title}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default DocByCategory;
