import { Button, Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { TextInput as TextInputRandstad } from '@randstad-lean-mobile-factory/react-components-core';
import { Modal } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useImperativeHandle, useState } from 'react';
import { PopupActions } from 'reactjs-popup/dist/types';
//import { PointingFingerCut } from '@randstad-lean-mobile-factory/react-components-ui-shared';

//import styles from './TextInput.module.scss';
import { Props, defaultProps } from './TextInput.types';

const TextInput = React.forwardRef<PopupActions, Props>(
  (
    {
      trigger,
      title,
      titleDefaultButton,
      onClickAction,

      defaultOpen,
      waitingMode,
      icon,
      onClose,
      contentStyle,
      ...rest
    }: Props,
    ref
  ) => {
    const innerRef = React.useRef<PopupActions>(null);
    const [text, setText] = useState('');
    useImperativeHandle<PopupActions | null, PopupActions | null>(ref, () => ({
      close: () => innerRef.current?.close(),
      open: () => innerRef.current?.open(),
      toggle: () => innerRef.current?.toggle(),
    }));

    return (
      <Modal
        title={title}
        trigger={trigger}
        defaultOpen={defaultOpen}
        onClose={onClose}
        ref={innerRef}
        {...rest}
        icon={icon}
        contentStyle={{
          position: 'relative',
          outline: 'none',
          border: 'none',
          borderRadius: '8px',
          padding: 0,
          width: '100%',
          maxWidth: '45rem',
          ...contentStyle,
        }}
        footerActionsRight={[
          <>
            {!waitingMode && (
              <Button
                onClick={() => {
                  onClickAction(text);

                  innerRef.current?.close();
                }}
              >
                {titleDefaultButton}
              </Button>
            )}

            {waitingMode && <Loader heightInRem={4} />}
          </>,
        ]}
      >
        <TextInputRandstad
          value={text}
          onChange={event => setText((event.target as HTMLTextAreaElement).value)}
        />
      </Modal>
    );
  }
);
TextInput.defaultProps = defaultProps;
export default TextInput;
