import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { syncState, sync } from './Types';

const initialState: syncState = {
  lastSync: '',
  lastCheckTime: '',
  syncEndWithError: false,
};

export const { reducer: syncReducer, actions: syncActions } = createSlice({
  name: 'sync',
  initialState,
  reducers: {
    setlastCheckTime: (state: syncState, action: PayloadAction<sync>) => {
      if (action.payload.lastCheckTime) {
        state.lastCheckTime = action.payload.lastCheckTime;
      }
    },
    syncEndWithError: (state: syncState, action: PayloadAction<sync>) => {
      if (action.payload.syncEndWithError) {
        state.syncEndWithError = action.payload.syncEndWithError;
      }
    },
    setLastSync: (state: syncState, action: PayloadAction<sync>) => {
      if (action.payload.lastSync) {
        state.lastSync = action.payload.lastSync;
        state.syncEndWithError = false;
      }
    },
  },
});
export default syncReducer;
