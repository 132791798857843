/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/no-explicit-any*/
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useRef, useEffect } from 'react';
import * as _ from 'lodash';
import { Gestures } from 'react-gesture-handler';
import history from 'src/Utils/history';
import Iframe from './Iframe';
import { useKey } from 'src/Utils/keybordnav';

import { IFile, ISlide } from 'src/db/Types';

import Video from 'src/Components/Video';
import Image from 'src/Components/Image';
import Pdf from 'src/Components/Pdf';

import styles from './PresentationDetail.module.scss';
import backBlue from 'src/Assets/detail/backBlue.svg';
import backIcon from 'src/Assets/detail/fleche-back.svg';
import homewhite from 'src/Assets/detail/home-white.svg';
import previewwhite from 'src/Assets/detail/preview-white.svg';
import arrowRightwhite from 'src/Assets/detail/arrowRightwhite.svg';
import { ReactComponent as VideoIcon } from 'src/Assets/detail/play-circle.svg';
import { ReactComponent as PdfIcon } from 'src/Assets/detail/document.svg';
import { ReactComponent as ImageIcon } from 'src/Assets/detail/image.svg';
import { slideThumbNotFoundMsg } from 'src/Utils/constants';
import { IPrezDetailProps } from './PresentationDetail.types';
import ReactGA from 'react-ga4';

const PresentationDetail = ({ user, presentation, slides, countSlides }: IPrezDetailProps) => {
  const [modeList, setModeList] = useState(false);
  const [currentslide, setCureentslide] = useState<number>(0);
  const [isSlide, setIsSlide] = useState<boolean>(true);
  const [isChbSlide, setIsChbSlide] = useState<boolean>(false);
  const refButtonNext = useRef<HTMLButtonElement | null>(null);
  const refButtonPrev = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    console.log(`ReactGA:Selection Prez:(${user?.agence}):`, presentation?.title);
    ReactGA.event('Selection Prez', {
      prezName: presentation?.title,
      prezId: presentation?._id,
      brand: user?.brand?.name,
      region: user?.region,
      zone: user?.zone,
      agence: user?.agence,
    });
  }, []);

  /**
   * recognizers
   */
  const recognizers = {
    Swipe: {
      events: {
        swipeleft: () => {
          if (!isChbSlide && refButtonNext && refButtonNext.current) {
            refButtonNext.current.click();
          }
        },
        swiperight: () => {
          if (!isChbSlide && refButtonPrev && refButtonPrev.current) {
            refButtonPrev.current.click();
          }
        },
      },
    },
    Pinch: {
      events: {
        pinch: () => {
          setModeList(!modeList);
        },
      },
    },
  };
  /**
   *
   * @param event
   * Navigation entre les slides par clavier
   */
  useKey('ArrowRight', () => {
    if (_.has(slides[currentslide], 'mimetype')) {
      const file = slides[currentslide] as IFile;
      if (file.mimetype === 'application/pdf') {
        return false;
      }
      handleNext();
    } else {
      handleNext();
    }
  });

  useKey('ArrowLeft', () => {
    if (_.has(slides[currentslide], 'mimetype')) {
      const file = slides[currentslide] as IFile;
      if (file.mimetype === 'application/pdf') {
        return false;
      }
      handlePrev();
    } else {
      handlePrev();
    }
  });

  useKey('Enter', () => {
    if (modeList) {
      setModeList(false);
    }
  });
  /**
   * Navigation entre les différents slides
   */
  function handleNext() {
    if (!isChbSlide && currentslide !== countSlides - 1) {
      setCureentslide(currentslide + 1);
    }
  }
  function handlePrev() {
    if (!isChbSlide && currentslide !== 0) {
      setCureentslide(currentslide - 1);
    }
  }

  function goToSlide(idx: any) {
    if (idx < countSlides && idx >= 0) {
      setCureentslide(idx);
    } else {
      console.log('goToSlide: idx out of bound:' + idx);
    }
  }

  function renderModeList() {
    return (
      <React.Fragment>
        <ul className={styles['button__left']}>
          {modeList ? (
            <React.Fragment>
              <li className={styles['back']}>
                <button onClick={() => setModeList(!modeList)}>
                  <img src={backBlue} alt="back" />
                </button>
              </li>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <li className={styles['back']}>
                <button
                  onClick={e => {
                    e.preventDefault();
                    history.push('/');
                  }}
                >
                  <img src={backIcon} alt="back" />
                </button>
              </li>
            </React.Fragment>
          )}
        </ul>
        <h1 className={styles['current__prez']}>{presentation?.title}</h1>
        <div className={styles['mode__list']}>
          {slides.map((slide: ISlide | IFile, index: number) => {
            if (_.has(slide, 'mimetype')) {
              const file = slide as IFile;
              return (
                <div
                  className={`${styles['mode__list__item_media']} ${styles['mode__list__item']} ${
                    index === currentslide ? styles['mode__list__current__item'] : ''
                  }`}
                  key={index}
                >
                  {index === currentslide && <div className={styles['label-active']}>active</div>}
                  <div className={styles['mode__list__icon']}>
                    {slide &&
                      file.filename &&
                      file.filename.toLowerCase().indexOf('mp4') !== -1 && (
                        <VideoIcon style={{ fill: '#fff', width: '60px' }} />
                      )}
                    {((file &&
                      file.filename &&
                      file.filename.toLowerCase().indexOf('jpg') !== -1) ||
                      (file &&
                        file.filename &&
                        file.filename.toLowerCase().indexOf('png') !== -1)) && (
                      <ImageIcon style={{ fill: '#fff', width: '60px' }} />
                    )}
                    {file && file.filename && file.filename.toLowerCase().indexOf('pdf') !== -1 && (
                      <PdfIcon style={{ fill: '#fff', width: '60px' }} />
                    )}
                  </div>
                  <div className={styles['mode__list__item_title']}>{file.filename}</div>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setCureentslide(index);
                      setModeList(!modeList);
                    }}
                    className={styles['mode__list__item_link']}
                  ></a>
                </div>
              );
            } else {
              slide = slide as ISlide;
              return (
                <div
                  className={`${styles['mode__list__item']} ${
                    index === currentslide ? styles['mode__list__current__item'] : ''
                  }`}
                  key={index}
                >
                  {/* {index === currentslide && <div className={styles['label-active']}>active</div>} */}
                  {slide && slide.file && slide.file.thumbnail && (
                    <div
                      className={styles['mode__list__image']}
                      style={{ backgroundImage: `url(${slide.file.thumbnail})` }}
                    ></div>
                  )}
                  {slide && (!slide.file || !slide.file.thumbnail) && (
                    <div className={styles['mode__list__image__default']}>
                      {slideThumbNotFoundMsg}
                    </div>
                  )}
                  <div className={styles['mode__list__item_title']}>{slide.title}</div>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setCureentslide(index);
                      setModeList(!modeList);
                    }}
                    className={styles['mode__list__item_link']}
                  ></a>
                </div>
              );
            }
          })}
        </div>
      </React.Fragment>
    );
  }
  const setIsSlideCallback = (value: boolean) => {
    setIsSlide(value);
  };
  const setIsChbSlideCallback = (value: boolean) => {
    setIsChbSlide(value);
  };
  function previewFiles(filePrev: IFile) {
    console.log('filePrev', filePrev);
    if (filePrev.file) {
      if (filePrev.mimetype === 'video/mp4') {
        return (
          <Gestures recognizers={recognizers}>
            <div className={styles['video_prez']}>
              <Video source={filePrev.file} />
            </div>
          </Gestures>
        );
      } else if (
        filePrev.mimetype === 'image/png' ||
        filePrev.mimetype === 'image/jpg' ||
        filePrev.mimetype === 'image/jpeg'
      ) {
        return (
          <Gestures recognizers={recognizers}>
            <Image source={filePrev.file} />
          </Gestures>
        );
      } else if (filePrev.mimetype === 'application/pdf') {
        return (
          <div className={styles['pdf_prez']}>
            <Pdf source={filePrev.file} />
          </div>
        );
      }
      return '';
    }
  }

  function renderHeader() {
    return (
      <div className={styles['header_prez']}>
        <div className={styles['header_prez_left']}>
          <ul>
            <li>
              <button
                onClick={() => {
                  history.push('/');
                }}
                data-testid="back"
              >
                <img src={homewhite} alt="home" />
              </button>
            </li>
          </ul>
          <h2>{(slides[currentslide] as ISlide).title}</h2>
        </div>
        <div className={styles['header_prez_right']}>
          <ul className={styles['chevron']}>
            <li className={styles['left']}>
              <button
                ref={refButtonPrev}
                onClick={() => handlePrev()}
                disabled={currentslide === 0 || isChbSlide === true}
                data-testid="navPrev"
              >
                <img src={arrowRightwhite} alt="arrow" />
              </button>
            </li>
            <li className={styles['right']}>
              <button
                ref={refButtonNext}
                onClick={() => handleNext()}
                disabled={currentslide === countSlides - 1 || isChbSlide === true}
                data-testid="navNext"
              >
                <img src={arrowRightwhite} alt="arrow" />
              </button>
            </li>
          </ul>
          <ul>
            <li>
              <button onClick={() => setModeList(!modeList)} data-testid="modePreviewList">
                <img src={previewwhite} alt="prev" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
  function renderDefaultMode() {
    return (
      <React.Fragment>
        {<React.Fragment>{slides.length > 0 && renderHeader()}</React.Fragment>}

        {slides.length > 0 && !_.has(slides[currentslide], 'zipFile') ? (
          previewFiles(slides[currentslide] as IFile)
        ) : (
          <Gestures recognizers={recognizers}>
            <Iframe
              user={user}
              slide={slides[currentslide] as ISlide}
              setIsSlide={setIsSlideCallback}
              isSlide={isSlide}
              setIsChbSlide={setIsChbSlideCallback}
              isChbSlide={isChbSlide}
              handleNext={() => handleNext()}
              handlePrev={() => handlePrev()}
              handlePinch={() => setModeList(!modeList)}
              goToSlide={goToSlide}
            />
          </Gestures>
        )}
      </React.Fragment>
    );
  }
  return (
    <div className={styles['prez__detail']} data-testid="prezDetail">
      {modeList ? renderModeList() : renderDefaultMode()}
    </div>
  );
};

export default PresentationDetail;
