/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { logout } from 'src/Providers/Auth0';

import { removeToken } from 'src/Utils/auth';

import ToastUpdate from 'src/Components/ToastUpdate';
import Modal from 'src/Components/Modal';

import { AuthState } from 'src/Redux/Auth/Types';
import { checkUpdate } from 'src/Utils/checkUpdate';
import { fetchInfosUser } from 'src/Redux/Auth/Slice';
import { syncLoadingActions } from 'src/Redux/SyncLoading/Slice';
import { getSyncLoading } from 'src/Redux/SyncLoading/Selectors';
import { getSync } from 'src/Redux/Sync/Selectors';

import { cexpLink, noSyncMsg, syncLink } from 'src/Utils/constants';
import history from 'src/Utils/history';

import settingsIcon from 'src/Assets/navbar/settings-icon.svg';
import appsIcon from 'src/Assets/navbar/apps-icon.svg';
import helpNotifIcon from 'src/Assets/navbar/help-notif.svg';
import refreshIcon from 'src/Assets/navbar/refresh.svg';
import addIcon from 'src/Assets/home/add-icon.svg';
import customScreenIcon from 'src/Assets/home/custom-screen.svg';
import aideitApp from 'src/Assets/apps/aideit.svg';
import edpApp from 'src/Assets/apps/edp.svg';
import flashApp from 'src/Assets/apps/flash.svg';
import lapigeApp from 'src/Assets/apps/lapige.svg';
import puzzleApp from 'src/Assets/apps/puzzle.svg';
import recruteliveApp from 'src/Assets/apps/recrutelive.svg';
import logoRandstad from 'src/Assets/navbar/logo-with-text.svg';

import styles from './Navbar.module.scss';

export interface Iprops {
  infos: AuthState | undefined;
}
function Navbar({ infos }: Iprops) {
  const [settingsMenu, setsettingsMenu] = useState(false);
  const [appsMenu, setAppsMenu] = useState(false);
  const [activeItemMenu, setActiveItemMenu] = useState('');
  const [checkUpdateState, setCheckUpdateState] = useState<{ sync: boolean; msg: string }>({
    sync: false,
    msg: '',
  });
  const sync = useSelector(getSync);
  const syncLoading = useSelector(getSyncLoading);
  const dispatch = useDispatch();

  const navbarMenuRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    dispatch(fetchInfosUser());
  }, [dispatch]);

  useEffect(() => {
    callCheckUpdate();
    function callCheckUpdate() {
      checkUpdate(infos, sync).then(resultCheck => {
        const checkResult = resultCheck as {
          sync: boolean;
          msg: string;
        };
        if (checkResult && checkResult.sync) {
          setCheckUpdateState(checkResult);
        }
      });
    }
    return () => {
      callCheckUpdate();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickEvent);
    return () => {
      document.addEventListener('click', clickEvent);
      document.removeEventListener('keydown', keyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickEvent = useCallback(e => {
    if (
      navbarMenuRef !== null &&
      navbarMenuRef.current &&
      navbarMenuRef.current.contains(e.target)
    ) {
      return;
    } else {
      setsettingsMenu(false);
      setAppsMenu(false);
      setActiveItemMenu('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keyPress = useCallback(e => {
    if (e.key === 'Escape') {
      setsettingsMenu(false);
      setAppsMenu(false);
      setActiveItemMenu('');
    }
  }, []);
  /**
   * Click profile
   */
  const handleClickProfil = () => {
    setsettingsMenu(!settingsMenu);
    setAppsMenu(false);
    activeItemMenu === 'profile' ? setActiveItemMenu('') : setActiveItemMenu('profile');
  };
  const handleClickApps = () => {
    setAppsMenu(!appsMenu);
    setsettingsMenu(false);
    activeItemMenu === 'apps' ? setActiveItemMenu('') : setActiveItemMenu('apps');
  };
  function setActiveIncactive(name: string) {
    if (activeItemMenu === '') {
      return '';
    }
    return activeItemMenu === name ? styles['active'] : styles['inactive'];
  }
  /**
   * Afficher un toast pour informer l'utlisateur si il y une maj
   */
  function renderToastUpdate() {
    if (syncLoading && !syncLoading.showSync) {
      if (checkUpdateState.sync) {
        return <ToastUpdate message={checkUpdateState.msg} goToSync={handleGoSync} />;
      }
    }
    return null;
  }
  /**
   * show alert confirm before start sync
   */
  function handleClickSync() {
    dispatch(
      syncLoadingActions.setShowAlertSync({
        showAlertSync: true,
      })
    );
  }

  /**
   * Render Alert sync comp
   */
  function handleCancelSync() {
    dispatch(
      syncLoadingActions.setShowAlertSync({
        showSync: false,
      })
    );
    dispatch(
      syncLoadingActions.setShowSync({
        showAlertSync: false,
      })
    );
  }
  function handleGoSync() {
    dispatch(
      syncLoadingActions.setShowSync({
        showSync: true,
      })
    );
    dispatch(
      syncLoadingActions.setShowAlertSync({
        showAlertSync: false,
      })
    );
    history.push(syncLink);
  }
  function renderAlertSync() {
    let message = noSyncMsg;
    if (checkUpdateState.sync) {
      message = checkUpdateState.msg;
    }
    return (
      <Modal onClose={() => handleCancelSync()}>
        <div className={styles['alert__sync']}>
          <p
            className={styles['alert__message']}
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          ></p>
          <div className={styles['alert__cta']}>
            <button className={styles['alert__cta__link']} onClick={handleCancelSync}>
              non
            </button>
            <button className={styles['alert__cta__link']} onClick={handleGoSync}>
              oui
            </button>
          </div>
        </div>
      </Modal>
    );
  }
  // show CXP  popup
  const handleShowCxp = () => {
    history.push(cexpLink);
  };

  return (
    <React.Fragment>
      <section className={styles['navbar']}>
        <a href={'/'} className={styles['logo']}>
          <img src={logoRandstad} alt="logo" /> <span>v0.31</span>
        </a>
        <ul className={styles['menu']} ref={navbarMenuRef}>
          <li>
            <button className={setActiveIncactive('sync')} onClick={() => handleClickSync()}>
              {checkUpdateState && checkUpdateState.sync && (
                <span className={styles['notif']}></span>
              )}
              <img src={refreshIcon} alt="Sync" />
            </button>
          </li>
          <li>
            <button className={setActiveIncactive('help')}>
              <span className={styles['notif']}></span>
              <img src={helpNotifIcon} alt="help" />
            </button>
          </li>
          <li>
            <button onClick={() => handleClickApps()} className={setActiveIncactive('apps')}>
              <img src={appsIcon} alt="apps" />
              {appsMenu && (
                <div className={`${styles['sub-menu-apps']}`}>
                  <div className={styles['item-apps']}>
                    <div className={styles['img-apps']}>
                      <img src={edpApp} alt="edpApp" />
                    </div>
                    <span>edp</span>
                    <a href="#" className={styles['link-apps']}></a>
                  </div>
                  <div className={styles['item-apps']}>
                    <div className={styles['img-apps']}>
                      <img src={flashApp} alt="flashApp" />
                    </div>
                    <span>flash</span>
                    {process.env.REACT_APP_ENV === 'recette' ? (
                      <a
                        href="https://recette.flash.grouperandstad.fr/"
                        target={'_blank'}
                        rel="noreferrer"
                        className={styles['link-apps']}
                      ></a>
                    ) : null}
                    {process.env.REACT_APP_ENV === 'development' ? (
                      <a
                        href="https://recette.flash.grouperandstad.fr/"
                        target={'_blank'}
                        rel="noreferrer"
                        className={styles['link-apps']}
                      ></a>
                    ) : null}
                    {process.env.REACT_APP_ENV === 'production' ? (
                      <a
                        href="https://flash.grouperandstad.fr/"
                        target={'_blank'}
                        rel="noreferrer"
                        className={styles['link-apps']}
                      ></a>
                    ) : null}
                  </div>
                  <div className={styles['item-apps']}>
                    <div className={styles['img-apps']}>
                      <img src={recruteliveApp} alt="recruteliveApp" />
                    </div>
                    <span>recrute'live</span>
                    <a href="#" className={styles['link-apps']}></a>
                  </div>
                  <div className={styles['item-apps']}>
                    <div className={styles['img-apps']}>
                      <img src={puzzleApp} alt="puzzleApp" />
                    </div>
                    <span>puzzle</span>
                    <a href="#" className={styles['link-apps']}></a>
                  </div>
                  <div className={styles['item-apps']}>
                    <div className={styles['img-apps']}>
                      <img src={lapigeApp} alt="lapigeApp" />
                    </div>
                    <span>la pige</span>
                    {process.env.REACT_APP_ENV === 'recette' ? (
                      <a
                        href="https://recette.pige.grouperandstad.fr/"
                        target={'_blank'}
                        rel="noreferrer"
                        className={styles['link-apps']}
                      ></a>
                    ) : null}
                    {process.env.REACT_APP_ENV === 'production' ? (
                      <a
                        href="http://pige.grouperandstad.fr/"
                        target={'_blank'}
                        rel="noreferrer"
                        className={styles['link-apps']}
                      ></a>
                    ) : null}
                    {process.env.REACT_APP_ENV === 'development' ? (
                      <a
                        href="https://recette.pige.grouperandstad.fr/"
                        target={'_blank'}
                        rel="noreferrer"
                        className={styles['link-apps']}
                      ></a>
                    ) : null}
                  </div>
                  <div className={styles['item-apps']}>
                    <div className={styles['img-apps']}>
                      <img src={aideitApp} alt="aideitApp" />
                    </div>
                    <span>aide it</span>
                    <a href="#" className={styles['link-apps']}></a>
                  </div>
                </div>
              )}
            </button>
          </li>
          <li>
            <button onClick={() => handleClickProfil()} className={setActiveIncactive('profile')}>
              <img src={settingsIcon} alt="user" />
              {settingsMenu && (
                <div className={styles['sub-menu']}>
                  <div className={styles['user-infos-menu']}>
                    <div className={styles['icon-name']}>
                      {infos && infos.firstName && infos.firstName?.split(' ')[1].charAt(0)}
                      {infos && infos.firstName && infos.firstName?.split(' ')[0].charAt(0)}
                    </div>
                    <div className={styles['block__user__infos']}>
                      <div className={styles['user-name']}>
                        {infos && infos.firstName && infos.firstName?.split(' ')[1].trim()}{' '}
                        {infos && infos.firstName && infos.firstName?.split(' ')[0]}
                      </div>
                      <div className={styles['user-email']}>
                        {infos && infos.firstName && infos.email}
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <button onClick={() => handleShowCxp()}>
                        <img src={addIcon} alt="add" /> gérer les centres experts
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          history.push('/fiche-agence');
                        }}
                      >
                        <img src={customScreenIcon} alt="add" /> personnalisation écrans
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={async () => {
                          await logout();
                          removeToken();
                        }}
                        className="button button--s"
                      >
                        déconnexion
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </button>
          </li>
        </ul>
      </section>
      {renderToastUpdate()}

      {syncLoading && syncLoading.showAlertSync && renderAlertSync()}
    </React.Fragment>
  );
}

export default Navbar;
