import React from 'react';
import { IImage } from './Image.types';

import styles from './Image.module.scss';

function Image({ source }: IImage) {
  return (
    <div className={styles['image__wrapper']}>
      <img style={{ maxHeight: '90vh' }} src={source} alt="image1" />
    </div>
  );
}

export default Image;
