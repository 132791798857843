import { Endpoint } from './constants';
import { getTokenSilently } from 'src/Providers/Auth0';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export const getToken = () => {
  return new Promise(resolve => {
    const token: string | null = localStorage.getItem('puzzle-token');
    if (token) {
      const { exp } = jwtDecode<JwtPayload>(token);
      if (exp && exp < (new Date().getTime() + 1) / 1000) {
        getTokenSilently().then(tokenAuth0 => {
          loadPuzzleToken(tokenAuth0).then(puzzleToken => {
            resolve(puzzleToken);
          });
        });
      } else {
        resolve(token);
      }
    } else {
      getTokenSilently().then(tokenAuth0 => {
        loadPuzzleToken(tokenAuth0).then(puzzleToken => {
          resolve(puzzleToken);
        });
      });
    }
  });
};
// Ajouter puzzle token
export const setToken = (token: string) => {
  localStorage.setItem('puzzle-token', token);
};
// Supprimer puzzle token
export const removeToken = () => {
  localStorage.removeItem('puzzle-token');
};

/**
 *  Récuperation + sauvgarde token puzzle
 */
export const loadPuzzleToken = (token: string) => {
  return new Promise((resolve, reject) => {
    const myInit = {
      method: 'post',
      body: JSON.stringify({}),
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    fetch(`${process.env.REACT_APP_API_URL}${Endpoint.auth}`, myInit).then(res => {
      if (res.status === 401) {
        reject();
      } else {
        res.json().then(({ token }) => {
          setToken(token);
          resolve(token);
        });
      }
    });
  });
};
