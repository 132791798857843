/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars*/
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import PreviewDoc from './PreviewDoc.component';
import { IPreviewDocEnhanced } from './PreviewDoc.Types';

import { db } from 'src/db';
import { IFile, IDocument, NewISlideFile } from 'src/db/Types';
import { useSelector } from 'react-redux';
import { getAuthState } from 'src/Redux/Auth/Selectors';
import { db_blob2Base64 } from 'src/Utils/common';

const MemoPreviewDoc = React.memo(PreviewDoc);

const EnhancedPreviewDoc: React.FC<IPreviewDocEnhanced> = ({ handleClose, idDoc, listDocs }) => {
  const [documents, setDocuments] = useState<Array<IDocument>>([]);
  const [loading, setLoading] = useState(true);
  const user = useSelector(getAuthState);

  const blob2Base64 = (blob: Blob): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result?.toString());
      reader.onerror = error => reject(error);
    });
  };

  useEffect(() => {
    getFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDoc, listDocs]);
  const getFiles = async () => {
    const IdsDocs = listDocs.map(doc => doc._id);
    const docs = await db.documents?.where('_id').anyOf(IdsDocs).toArray();
    if (docs) {
      for (let index = 0; index < docs.length; index++) {
        const file = await db.files?.where('_id').equals(docs[index].file._id).first();
        //<md5
        if (file) {
          const tmp: any = file!.file;
          const newISlideFile: NewISlideFile = tmp as NewISlideFile;
          if (newISlideFile && newISlideFile.md5s && newISlideFile.md5s[0]) {
            const info = await db.md5file?.where('md5').equals(newISlideFile.md5s[0]).first();
            //*old version
            //<md5blob
            if (info && info.blob) {
              const content = await db_blob2Base64(info?.blob);
              info.content = content;
              console.log('content of md5 from blob:');
            }
            //>
            if (info?.content) {
              const data = 'data:' + file?.mimetype + ';base64,' + info?.content;
              file!.file = data as string;
              console.log('doc use md5 content');
            }
            //end old version */

            /*new version md5blob
         
            const content = await db_blob2Base64(info?.blob);

            if (content) {
              //content = content.replace('data:application/octet-stream;base64,', '');
              const data = 'data:' + file?.mimetype + ';base64,' + content;
              file!.file = data as string;
              console.log('doc use md5 content with blob');
            }
            end new version */
          }
        }
        //md5>>
        docs[index].file = file as IFile;
      }
      setDocuments(docs);
    }
    setLoading(false);
  };
  return (
    <MemoPreviewDoc
      user={user}
      handleClose={handleClose}
      documents={documents}
      loading={loading}
      idDoc={idDoc}
    />
  );
};

export default EnhancedPreviewDoc;
