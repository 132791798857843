import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';

import { loadCxp } from 'src/Redux/Cxp/Slice';
import getCxpState, { getCxpList } from 'src/Redux/Cxp/Selectors';
import { getUserCxp, getAuthState } from 'src/Redux/Auth/Selectors';
import { fetchInfosUser } from 'src/Redux/Auth/Slice';
import { getSelectCxpList } from 'src/Redux/Cxp/Selectors';
import { syncLoadingActions } from 'src/Redux/SyncLoading/Slice';
import { cxpActions } from 'src/Redux/Cxp/Slice';
import { ICxp } from 'src/db/Types';

import history from 'src/Utils/history';

import { updateCexpsUser } from 'src/Containers/Sync/api';

import CentresExperts from './CentresExperts.component';

import { syncLink } from 'src/Utils/constants';
import { IData } from './CentresExperts.types';

interface IOptionType {
  label: string;
  value: string;
}

const MemoCentresExperts = React.memo(CentresExperts);

const EnhancedCentresExperts: React.FC = () => {
  const [cexpsDefaultTickets, setCexpsDefaultTickets] = useState<IData[]>([]);
  const [userCexpsTickets, setUserCexpsTickets] = useState<IData[]>([]);

  const dispatch = useDispatch();

  const cxpState = useSelector(getCxpState);
  const cxps = useSelector(getCxpList);
  const userCxp = useSelector(getUserCxp);
  const infos = useSelector(getAuthState);

  const selectCxpList = useSelector(getSelectCxpList);

  useEffect(() => {
    if (cxps.length > 0) {
      formatDataCexps();
    }
    return () => {
      formatDataCexps();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cxps, infos, userCxp]);

  useEffect(() => {
    dispatch(fetchInfosUser());
    dispatch(loadCxp());
  }, [dispatch]);

  function storeUserCexps(cexps: Array<string>) {
    updateCexpsUser(cexps)?.then(res => {
      console.log(res);
      dispatch(
        syncLoadingActions.setShowAlertSync({
          showAlertSync: false,
        })
      );
      dispatch(
        syncLoadingActions.setShowSync({
          showSync: true,
        })
      );
      dispatch(
        syncLoadingActions.setAddedCxp({
          addedCxpMessage: true,
        })
      );
      history.push(syncLink);
    });
  }

  const formatDataCexps = () => {
    const datafinal: Array<IData> = [];
    const allDataPreparedSelect: Array<IOptionType> = [];
    const defaultcxp: Array<ICxp> = [];
    const usercxps: Array<ICxp> = [];

    if (cxps.length > 0 && userCxp.length > 0) {
      _.forEach(userCxp, user => {
        // eslint-disable-next-line array-callback-return
        const obj: ICxp | undefined = cxps.find((ucxp: ICxp) => {
          if (ucxp._id === user) {
            // GET Default User CXP
            _.forEach(infos?.defaultCexps, (dc: string) => {
              if (dc === user) {
                defaultcxp.push(ucxp);
              }
            });

            // GET User CXP
            _.forEach(infos?.userCexps, (uc: string) => {
              if (uc === user) {
                usercxps.push(ucxp);
              }
            });

            return ucxp;
          }
        });
        const ob = obj as ICxp;
        if (ob) {
          const result: IData = { id: ob._id, value: ob.name };

          datafinal.push(result);
        }
      });
      //prepare data for tickets
      const formedDefaultCxpForTickets = _.map(defaultcxp, dc => {
        return { id: dc._id, value: dc.name };
      });
      const formedUserCxpForTickets = _.map(usercxps, uc => {
        return { id: uc._id, value: uc.name };
      });
      //prepare data for select
      cxps.forEach(cxp => {
        //console.log('okeey', cxp);
        const dc = defaultcxp.find((ucxp: ICxp) => ucxp._id === cxp._id);
        const userc = usercxps.find((ucxp: ICxp) => ucxp._id === cxp._id);
        if (!dc && !userc) {
          allDataPreparedSelect.push({ label: cxp.name, value: cxp.name });
        }
      });

      setCexpsDefaultTickets(formedDefaultCxpForTickets);
      setUserCexpsTickets(formedUserCxpForTickets);

      dispatch(cxpActions.setSelectCxpList(allDataPreparedSelect));
      dispatch(
        cxpActions.setUserCxpNb({
          nb: formedUserCxpForTickets.length,
        })
      );
      dispatch(
        cxpActions.setDefaultCxpNb({
          nb: formedDefaultCxpForTickets.length,
        })
      );
      dispatch(
        cxpActions.setSelectCxpNb({
          nb: allDataPreparedSelect.length,
        })
      );
    }
  };
  const onChangeSelect = (selectedOption: { value: string; label: string }) => {
    const selected = selectedOption?.value;
    const cxp = cxps.find((ucxp: ICxp) => ucxp.name === selected);

    if (cxp) {
      const deleteCxpFromSelect = _.filter(selectCxpList, uct => uct.value !== cxp.name);
      const addToUserCxpTicket = userCexpsTickets;
      addToUserCxpTicket.push({ id: cxp._id, value: cxp.name });

      dispatch(cxpActions.setSelectCxpList(deleteCxpFromSelect));
      setUserCexpsTickets(addToUserCxpTicket);
      dispatch(
        cxpActions.setUserCxpNb({
          nb: cxpState.userCxpNb + 1,
        })
      );
      dispatch(
        cxpActions.setSelectCxpNb({
          nb: cxpState.selectCxpNb - 1,
        })
      );
    }
  };

  const deleteUserCxp = (id: string) => {
    const cxp = cxps.find((ucxp: ICxp) => ucxp._id === id);
    if (cxp) {
      const deleteCxpFromUsersCxpTickets = _.filter(
        userCexpsTickets,
        uct => uct.value !== cxp.name
      );
      const addToCxpSelect = selectCxpList.map(item => ({
        ...item,
      }));

      addToCxpSelect.push({ label: cxp.name, value: cxp.name });
      dispatch(cxpActions.setSelectCxpList(addToCxpSelect));
      setUserCexpsTickets(deleteCxpFromUsersCxpTickets);
      dispatch(
        cxpActions.setUserCxpNb({
          nb: cxpState.userCxpNb - 1,
        })
      );
      dispatch(
        cxpActions.setSelectCxpNb({
          nb: cxpState.selectCxpNb + 1,
        })
      );
    }
  };
  const handleSave = () => {
    const ucToSend: Array<string> = _.map(userCexpsTickets, uc => {
      return uc.id;
    });
    storeUserCexps(ucToSend);
  };

  return (
    <MemoCentresExperts
      cxpState={cxpState}
      selectCxpList={selectCxpList}
      deleteUserCxp={deleteUserCxp}
      onChangeSelect={onChangeSelect}
      cexpsDefaultTickets={cexpsDefaultTickets}
      userCexpsTickets={userCexpsTickets}
      handleSave={handleSave}
    />
  );
};

export default EnhancedCentresExperts;
