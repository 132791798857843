/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import _ from 'lodash';
import { ISlide } from 'src/db/Types';
import styles from './FicheAgence.module.scss';
import FormAgence from './FormAgence/index';
import PreviewAgence from './PreviewAgence/index';

import { IFicheAgence } from './FicheAgence.types';
import Offline from 'src/Components/Offline';
import loaderIcon from 'src/Assets/loader_blue.gif';

const FicheAgence: React.FC<IFicheAgence> = ({
  templates,
  sheets,
  slide,
  selectedCxp,
  loading,
  loadingPrev,
  storeNewSheet,
}) => {
  const [showPrev, setShowPrev] = useState(false);
  const [dataForm, setDataForm] = useState<any | undefined>(undefined);

  function handleClose() {
    setShowPrev(false);
  }

  function handlePreview(values: any) {
    setDataForm(values);
    setShowPrev(true);
  }

  if (!window.navigator.onLine) {
    return <Offline />;
  }
  return (
    <React.Fragment>
      <div className={styles['wrapper_agence']}>
        {showPrev && (
          <PreviewAgence
            slide={slide && (slide as ISlide)}
            handleClose={handleClose}
            dataForm={dataForm}
            loadingPrev={loadingPrev}
            template={templates && templates[0]}
          />
        )}
        {loading ? (
          <div className={styles['loadingForm']}>
            <img
              src={loaderIcon}
              alt="loader"
              style={{ width: '35px', display: 'inline-block', margin: '0 auto ' }}
            />
          </div>
        ) : (
          <FormAgence
            template={templates && templates[0]}
            sheets={sheets}
            selectedCxp={selectedCxp}
            handlePreview={handlePreview}
            storeNewSheet={storeNewSheet}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default FicheAgence;
