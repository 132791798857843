import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { syncLoadingState, syncLoading } from './Types';

const initialState: syncLoadingState = {
  value: 0,
  loading: false,
  showSync: false,
  showAlertSync: false,
  messageSuccess: false,
  numberFilesToDownload: 0,
  NumberFilesSuccess: 0,
  NumberFilesFailed: 0,
  step: 1,
  addedCxpMessage: false,
};

export const { reducer: syncLoadingReducer, actions: syncLoadingActions } = createSlice({
  name: 'syncLoading',
  initialState,
  reducers: {
    setLoading: (state: syncLoadingState, action: PayloadAction<syncLoading>) => {
      state.loading = action.payload.loading;
      if (!action.payload.loading) {
        state.value = 100;
      }
    },
    setAddedCxp: (state: syncLoadingState, action: PayloadAction<syncLoading>) => {
      state.addedCxpMessage = action.payload.addedCxpMessage;
    },
    setSync: (state: syncLoadingState, action: PayloadAction<syncLoading>) => {
      state.value = action.payload.value;
    },
    endSync: (state: syncLoadingState, action: PayloadAction<syncLoading>) => {
      const { value } = action.payload;
      state.value = value;
      state.loading = false;
    },
    setShowSync: (state: syncLoadingState, action: PayloadAction<syncLoading>) => {
      state.showSync = action.payload.showSync;
    },
    setShowAlertSync: (state: syncLoadingState, action: PayloadAction<syncLoading>) => {
      state.showAlertSync = action.payload.showAlertSync;
    },
    setMessageSuccess: (state: syncLoadingState, action: PayloadAction<syncLoading>) => {
      state.messageSuccess = action.payload.messageSuccess;
    },
    numberFilesToDownload: (state: syncLoadingState, action: PayloadAction<syncLoading>) => {
      state.numberFilesToDownload = action.payload.numberFilesToDownload;
    },
    numberFilesFailed: (state: syncLoadingState) => {
      state.NumberFilesFailed++;
    },
    numberFilesSuccess: (state: syncLoadingState) => {
      state.NumberFilesSuccess++;
    },
    resetNumbersSync: (state: syncLoadingState) => {
      state.NumberFilesSuccess = 0;
      state.numberFilesToDownload = 0;
      state.NumberFilesFailed = 0;
    },
    updateStepSync: (state: syncLoadingState, action: PayloadAction<syncLoading>) => {
      if (action.payload.step) {
        state.step = action.payload.step;
      }
    },
  },
});
export default syncLoadingReducer;
