import { createSelector } from 'reselect';
import { ICxp } from 'src/db/Types';
import { RootState } from 'src/Redux/RootReducer';

export const getSelectedCxpState = (state: RootState): ICxp => state.selectedCxp;

export const getSelectedCxp = createSelector(
  [getSelectedCxpState],
  (selectedCxp: ICxp) => selectedCxp
);

export default getSelectedCxpState;
