import Dexie from 'dexie';
import {
  ICxp,
  IPresentation,
  IUser,
  ISlide,
  ICategory,
  IBindings,
  IDocument,
  IFile,
  ITemplate,
  ISheet,
  IMD5File,
} from './Types';

export class AppDatabase extends Dexie {
  user: Dexie.Table<IUser, number> | undefined;
  cexps: Dexie.Table<ICxp, number> | undefined;
  presentations: Dexie.Table<IPresentation, number> | undefined;
  slides: Dexie.Table<ISlide, number> | undefined;
  categories: Dexie.Table<ICategory, number> | undefined;
  bindings: Dexie.Table<IBindings, number> | undefined;
  documents: Dexie.Table<IDocument, number> | undefined;
  files: Dexie.Table<IFile, number> | undefined;
  templates: Dexie.Table<ITemplate, number> | undefined;
  sheets: Dexie.Table<ISheet, number> | undefined;
  md5file: Dexie.Table<IMD5File, number> | undefined;

  constructor() {
    super('PuzzleDatabase');
    this.version(3).stores({
      user:
        '++id, ssid, email, firstName, lastName, brand, hasForcedBrand, useMinimalCexp, regions, zones, userCexps, defaultCexps',
      cexps: '++id, name, isDefault, _id',
      presentations:
        '++id, _id, updatedAt, createdAt, published, title, ref, description, files, slides, cexps',
      files: '++id, _id, uid, file, mimetype, filename, updatedAt, createdAt, size',
      slides: '++id, _id, title, createdAt, updatedAt, sheetTemplate, thumbnailFile, zipFile',
      categories: '++id, _id, createdAt, updatedAt',
      bindings: '++id, _id, document, cexp, category',
      documents: '++id, _id, createdAt, file, shareable, title, updatedAt',
      templates: '++id, _id, name, keys, createdAt, updatedAt',
      sheets: '++id, _id, sheetTemplate, cexp, metas, createdAt, updatedAt',
      md5file: '++id, md5, content, blob,ref',
    });
  }
}
export const db = new AppDatabase();
