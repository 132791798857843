import { createSelector } from 'reselect';
import { RootState } from 'src/Redux/RootReducer';
import { CxpState } from './Types';

export const getCxpState = (state: RootState): CxpState => state.cxp;

export const getCxpList = createSelector([getCxpState], (CxpState: CxpState) => CxpState.data);

export const getSelectCxpList = createSelector(
  [getCxpState],
  (CxpState: CxpState) => CxpState.selectCxpList
);

export default getCxpState;
