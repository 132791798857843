/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';

import { ICategory, IBindings, ICxp } from 'src/db/Types';

// import doc from 'src/Assets/documents/doc.svg';
import styles from './Documents.module.scss';
import DocByCategory from './DocByCategory';
import { docActions } from 'src/Redux/Doc/Slice';
import { getShowDocByCategory, getCategorieIdSelected } from 'src/Redux/Doc/Selectors';

export interface Iprops {
  categories: ICategory[] | undefined;
  bindings: IBindings[] | undefined;
  selectedCxp: ICxp;
}
function Documents({ categories, bindings, selectedCxp }: Iprops) {
  const [filtredCategories, setFiltredCategories] = useState<ICategory[]>([]);
  /*  const [idCategorySelected, setIdCategorySelected] = useState<string>(''); */
  const idCategorySelected = useSelector(getCategorieIdSelected);

  const showDocByCategory = useSelector(getShowDocByCategory);
  const dispatch = useDispatch();

  useEffect(() => {
    filterCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, selectedCxp, bindings]);

  const filterCategories = () => {
    const filterBindingsByCexp = _.filter(bindings, item => _.includes(selectedCxp._id, item.cexp));
    const getFiltredCategoriesId = _.map(filterBindingsByCexp, item => item.category);

    const catsFiltred = _.filter(categories, item => _.includes(getFiltredCategoriesId, item._id));
    setFiltredCategories(catsFiltred);
  };
  function handleCancelDocByCategory() {
    dispatch(
      docActions.setShowDocByCategory({
        showDocByCategory: false,
      })
    );
  }
  function handleOnClickCategory(id: string) {
    dispatch(
      docActions.setShowDocByCategory({
        showDocByCategory: true,
      })
    );
    dispatch(
      docActions.setIdCategorieSelected({
        idcatselected: id,
      })
    );
  }
  function renderDocByCategory() {
    return (
      <DocByCategory
        idCategory={idCategorySelected}
        cxp={selectedCxp}
        handleCancelDocByCategory={handleCancelDocByCategory}
      />
    );
  }

  return (
    <React.Fragment>
      <div className={styles['title']}>bibliothèque</div>
      <div
        className={`${styles['items']} ${
          filtredCategories.length > 8 ? styles['items-scroll'] : null
        }`}
        data-testid="list-biblio"
      >
        <React.Fragment>
          {filtredCategories.map((item, index) => (
            <div className={styles['item']} key={index}>
              {
                <a
                  href={'#'}
                  className={'button button--m'}
                  onClick={() => handleOnClickCategory(item._id)}
                >
                  {item.name.toLowerCase()}
                </a>
              }
            </div>
          ))}
        </React.Fragment>
      </div>
      {showDocByCategory ? renderDocByCategory() : null}
    </React.Fragment>
  );
}

export default Documents;
