export const Endpoint = {
  auth: '/public/api/auth',
  userInfo: '/public/api/user/info',
  cxps: '/public/api/cxp',
  presentation: '/public/api/presentation',
  doc: '/public/api/document',
  slide: '/public/api/web/slide',
  startSync: '/public/api/user/startUpdate',
  endSync: '/public/api/user/endUpdate',
  loadFile: '/public/api/web/zip/base64',
  loadFiles: '/public/api/web/zip/base64',
  updateCexpsUser: '/public/api/user/cxp',
  loadTemplates: '/public/api/sheet_template',
  addSheet: '/public/api/sheet',
  editSheet: '/public/api/sheet',
  getSheet: '/public/api/sheet',
  shareFile: '/public/api/web/share',
  downloadFileWithBlob: '/public/api/web/zip/blob',
};
export const syncLink = '/synchronisation';
export const cexpLink = '/centres-experts';

export const syncWithErrorMsg =
  "la synchronisation ne s'est pas terminée correctement, voulez-vous la relancer ?";
export const syncMsg = 'des contenus ont été mis à jour, voulez-vous lancer la synchronisation ?';
export const noSyncMsg =
  'tous vos contenus sont déjà à jour, voulez-vous vraiment lancer la synchronisation ?';
export const syncSuccessMsg = 'synchronisation terminée avec succès !';
export const syncEndWithErrorMsg = "la synchronisation n'est pas terminée correctement.";
export const cxpSuccessMsg = 'les centres experts ont été correctement modifiés';
export const ficheAgenceCreatedMsg = 'fiche agence créée avec succès !';
export const ErrorMsg = 'un problème est survenu. veuillez réessayer plus tard.';
export const ErrorMsgCxp = "merci de sélectionner un centre expert sur la page d'accueil.";
// export const ErrorFieldRequiredMsg = 'ce champ est obligatoire';
export const ficheAgenceEditedMsg = 'fiche agence modifiée avec succès !';
export const ficheAgenceUploadPhotoMsg =
  '<span>cliquer ici</span> pour ajouter la photo de votre équipe. <br />.png, .jpg';
export const ficheAgenceFieldPlaceholderMsg = 'saisissez plus de détails';
export const slideNotFoundMsg =
  "ce contenu n'a pas été téléchargé, veuillez lancer la synchronisation.";
export const slideThumbNotFoundMsg =
  "la miniature du contenu n'a pas été téléchargée, veuillez relancer la synchronisation.";
export const placeholderSelect = "Chercher Un Centre d'Expert ...";
export const emptyListCexp = 'aucun résultat correspondant à votre recherche';
export const cancelSync = 'voulez-vous vraiment quitter la synchronisation ?';
export const confirmRunSync = 'voulez-vous vraiment lancer la synchronisation ?';
export const accountChangedMsg =
  'un nouvel utilisateur a été détecté, vous devez effectuer une synchronisation';
