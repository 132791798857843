import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { serviceOptions } from 'src/Services/API';
import { Endpoint } from 'src/Utils/constants';
import { ficheAgenceState } from './Types';

// liste des templates
export const loadTemplates = createAsyncThunk('ficheAgence/loadFicheAgence', async () => {
  return await serviceOptions.axios?.get(`${Endpoint.loadTemplates}`).then(res => {
    return {
      templates: res.data,
    };
  });
});

// liste des sheet
export const loadSheet = createAsyncThunk('sheet/loadSheet', async () => {
  return await serviceOptions.axios?.get(`${Endpoint.getSheet}`).then(res => {
    return {
      sheets: res.data,
    };
  });
});

const initialState: ficheAgenceState = {
  templates: [],
  sheets: [],
  loading: true,
  loadingPrev: false,
};
export const { reducer: ficheAgenceReducer, actions: ficheAgenceActions } = createSlice({
  name: 'ficheAgence',
  initialState,
  reducers: {
    setLoading: (state: ficheAgenceState, action: PayloadAction<{ loading: boolean }>) => {
      state.loading = action.payload.loading;
    },
    setLoadingPrev: (state: ficheAgenceState, action: PayloadAction<{ loadingPrev: boolean }>) => {
      state.loadingPrev = action.payload.loadingPrev;
    },
  },
  extraReducers: {
    [loadTemplates.fulfilled.toString()]: (
      state: ficheAgenceState,
      action: PayloadAction<ficheAgenceState>
    ) => {
      const { templates } = action.payload;
      state.templates = templates;
      state.loading = false;
    },
    [loadSheet.fulfilled.toString()]: (
      state: ficheAgenceState,
      action: PayloadAction<ficheAgenceState>
    ) => {
      const { sheets } = action.payload;
      state.sheets = sheets;
      state.loading = false;
    },
  },
});
export default ficheAgenceReducer;
