/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import Video from 'src/Components/Video';
import Image from 'src/Components/Image';
import Pdf from 'src/Components/Pdf';

import styles from './Preview.module.scss';

import { IPpreview } from './Preview.types';

function Preview({ handleClose, dataPreview }: IPpreview) {
  return (
    <React.Fragment>
      <div className={`${styles['preview']}`} data-testid="preview">
        <div className={styles['content']}>
          {dataPreview.name.indexOf('.pdf') !== -1 && (
            <Pdf source={`data:application/pdf;base64,${dataPreview.file}`} />
          )}
          {dataPreview.name.indexOf('.mp4') !== -1 && (
            <Video source={`data:video/mp4;base64,${dataPreview.file}`} />
          )}
          {(dataPreview.name.indexOf('.png') !== -1 || dataPreview.name.indexOf('.jpg') !== -1) && (
            <Image source={`data:image/png;base64,${dataPreview.file}`} />
          )}
        </div>
        <a
          href="#"
          className={styles['close-corner']}
          onClick={() => handleClose()}
          data-testid="closePreview"
        ></a>
      </div>
    </React.Fragment>
  );
}

export default Preview;
