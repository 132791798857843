import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import RootReducer from './RootReducer';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { RootState } from 'src/Redux/RootReducer';
import { persistMigrate, persistVersion } from './persistMigrations';

const persistedRootReducer = persistReducer<RootState>(
  {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    // Nothing is persisted for the moment
    // See https://github.com/rt2zz/redux-persist#blacklist--whitelist to start persisting
    whitelist: ['selectedCxp', 'sync', 'auth'],
    version: persistVersion,
    migrate: persistMigrate,
  },
  RootReducer
);

const store = configureStore({
  reducer: persistedRootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./RootReducer', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require('./RootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export default store;
