import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocState } from './Types';

const initialState: DocState = {
  idDocumentToPreview: '',
  loading: false,
  showDocByCategory: false,
  idCategorieSelected: '',
  showDocPreview: false,
  showShareDoc: false,
};

export const { reducer: docReducer, actions: docActions } = createSlice({
  name: 'doc',
  initialState,
  reducers: {
    setShowDocByCategory: (
      state: DocState,
      action: PayloadAction<{ showDocByCategory: boolean }>
    ) => {
      state.showDocByCategory = action.payload.showDocByCategory;
    },
    setIdCategorieSelected: (state: DocState, action: PayloadAction<{ idcatselected: string }>) => {
      state.idCategorieSelected = action.payload.idcatselected;
    },
    setShowDocPreview: (state: DocState, action: PayloadAction<{ showDocPreview: boolean }>) => {
      state.showDocPreview = action.payload.showDocPreview;
    },
    setIdDocumentToPreview: (
      state: DocState,
      action: PayloadAction<{ idDocumentToPreview: string }>
    ) => {
      state.idDocumentToPreview = action.payload.idDocumentToPreview;
    },
    setShowShareDoc: (state: DocState, action: PayloadAction<{ showShareDoc: boolean }>) => {
      state.showShareDoc = action.payload.showShareDoc;
    },
  },
});
export default docReducer;
