/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState, useCallback } from 'react';
import * as _ from 'lodash';
import { ISlide, ISSlide } from 'src/db/Types';
import styles from './Iframe.module.scss';
import Preview from 'src/Components/Preview';
import { slideNotFoundMsg } from 'src/Utils/constants';
import history from 'src/Utils/history';
import { db } from 'src/db';
import { getFinalPrezId } from './data';
import { AuthState } from 'src/Redux/Auth/Types';
import { db_blob2Base64 } from 'src/Utils/common';
interface Iprops {
  user: AuthState | undefined;
  slide: ISlide;
  setIsSlide: (slide: boolean) => void;
  isSlide: boolean;
  setIsChbSlide: (slide: boolean) => void;
  isChbSlide: boolean;
  handleNext: () => void;
  handlePrev: () => void;
  handlePinch: () => void;
  goToSlide: (idx: any) => void;
}
function Iframe({
  user,
  slide,
  setIsSlide,
  isSlide,
  setIsChbSlide,
  isChbSlide,
  handleNext,
  handlePrev,
  handlePinch,
  goToSlide,
}: Iprops) {
  const [dataSlide, setDataSlide] = useState('');
  const [dataSubSlide, setDataSubSlide] = useState<ISSlide>();
  const [isSubSlide, setIsSubSlide] = useState(false);

  const [isPreview, setIsPreview] = useState(false);
  const [dataPreview, setDataPreview] = useState({ name: '', file: '' });

  useEffect(() => {
    window.addEventListener('message', listenIframeEventCallback);
    if (slide) {
      injectJSCallback();
    }
    // clean up
    return () => window.removeEventListener('message', listenIframeEventCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide, dataSubSlide, isSlide, isChbSlide]);

  const listenIframeEvent = async (event: any) => {
    if (event.origin !== `${window.location.origin}`) {
      return;
    }
    event.preventDefault();
    const value = event.data;
    if (typeof value !== 'string') {
      return;
    }
    dumpLog('listenIframeEventnpm start: ' + value);
    if (value === 'swipeleft') {
      handleNext();
    }
    if (value === 'swiperight') {
      handlePrev();
    }
    if (value === 'pinch') {
      handlePinch();
    }
    if (value.startsWith('/application/')) {
      goToApplication(value);
      return;
    }

    // go to presentation
    if (value.indexOf('presentation') !== -1 && !isSubSlide) {
      dumpLog(' go to presentation');
      const SplitedData = value.split('/');
      let id = SplitedData[1];
      if (SplitedData.length === 3) {
        id = SplitedData[2];
      }
      const prezId = getFinalPrezId(id);
      console.log(`getFinalPrezId ${id} to ${prezId}`);

      history.push(`/presentation/${prezId}`);
      window.location.reload();
    }

    // go to slide
    if (value.indexOf('slide') !== -1) {
      const SplitedData = value.split('/');
      let id = SplitedData[1];
      if (SplitedData.length === 3) {
        id = SplitedData[2];
      }
      dumpLog(' go to slide:' + id);
      const idd = parseInt(id);
      goToSlide(idd);
      return;
    }

    // slide documents
    if (value.indexOf('documents') !== -1 && !isSubSlide) {
      dumpLog(`slide goto documents:${value}`);
      if (slide && slide.file) {
        const filePdf = slide.file.documents.filter(doc => _.includes(value, doc.name));
        if (filePdf && filePdf.length > 0) {
          let fileContent;

          if (filePdf && filePdf[0]?.md5) {
            const info = await db.md5file?.where('md5').equals(filePdf[0]?.md5).first();
            //<md5blob
            if (info && info.blob) {
              const content = await db_blob2Base64(info?.blob);
              info.content = content;
              console.log('content of md5 from blob:');
            }
            //>
            if (info?.content) {
              fileContent = info?.content;
              dumpLog('use md5 content');
            }
          }
          if (!fileContent) {
            fileContent = filePdf[0].file;
            dumpLog('use file content');
          }
          if (fileContent) {
            setIsPreview(true);
            if (filePdf[0]) {
              setDataPreview({
                name: filePdf[0].name,
                file: fileContent,
              });
            }
          } else {
            console.error(`contenu of ${filePdf[0]?.name} not found`);
          }
        } else {
          console.error(`document ${value} not found`);
        }
      }
    }
    /*
     * Sub slide
     */
    // sub slide
    if (value.indexOf('pivot') !== -1) {
      dumpLog('sub slide');
      const SplitedData = value.split('/');
      if (slide && slide.file && _.has(slide.file, 'pivots')) {
        const pivots = _.filter(slide.file.pivots, function (pivot) {
          return pivot.name === SplitedData[1];
        });
        setIsChbSlide(true);
        setIsSubSlide(true);
        setIsSlide(false);
        setDataSubSlide(_.nth(pivots, 0));
      }
    }
    // close sub slide
    if (value.indexOf('index.html') !== -1 && value.indexOf('pivot') === -1) {
      dumpLog('close sub slide');
      if (isSubSlide) {
        setIsChbSlide(false);
        setIsSubSlide(false);
        setIsSlide(true);
      }
    }
    // sub slide et slide PDF
    if (value.indexOf('documents') !== -1 && isSubSlide) {
      dumpLog(`sub slide et slide PDF,${value}`);
      if (slide && slide.file) {
        const SplitedData = value.split('/');
        const pivots = _.filter(slide.file.pivots, function (pivot) {
          return pivot.name === dataSubSlide?.name;
        });
        const pdfObject = _.nth(pivots, 0)?.documents.filter(doc => doc.name === SplitedData[1]);
        const filePdf = _.nth(pdfObject, 0);
        let fileContent;

        if (filePdf?.md5) {
          const info = await db.md5file?.where('md5').equals(filePdf?.md5).first();
          //<md5blob
          if (info && info.blob) {
            const content = await db_blob2Base64(info?.blob);
            info.content = content;
            console.log('content of md5 from blob:');
          }
          //>
          if (info?.content) {
            fileContent = info?.content;
            dumpLog('use md5 content');
          }
        }
        if (!fileContent) {
          fileContent = filePdf?.file;
          dumpLog('use file content');
        }

        if (fileContent) {
          setIsPreview(true);
          if (filePdf) {
            setDataPreview({
              name: filePdf.name,
              file: fileContent,
            });
          }
        } else {
          console.error(`contenu of ${filePdf?.name} not found`);
        }
      }
    }

    if (value === 'file:///application/rand-satisfaction-prod') {
      goToApplication('/application/rand-satisfaction-prod');
      return;
    }

    if (value === 'etudedeposte') {
      goToApplication('/application/rand-edpV2-prod');
      return;
    }

    if (value.indexOf('file:///application/rand-bigdataweb-prod') !== -1) {
      goToApplication('/application/rand-bigdataweb-prod');
      return;
    }

    // go to url
    if (value.indexOf('http') !== -1) {
      if (value.indexOf('://bigdata.grouperandstad.fr') !== -1) {
        goToApplication('/application/rand-bigdataweb-prod');
        return;
      } else if (value.indexOf('://recrutlive.grouperandstad.fr') !== -1) {
        goToApplication('/application/recrutlive');
        return;
      } else if (value.indexOf('://etudedeposte.grouperandstad.fr') !== -1) {
        goToApplication('/application/rand-edpV2-prod');
        return;
      } else {
        dumpLog(' go to url');
        window.open(value.toString(), '_blank');
        return;
      }
    }
  };
  const listenIframeEventCallback = useCallback(listenIframeEvent, [
    dataSubSlide?.name,
    handleNext,
    handlePinch,
    handlePrev,
    isSubSlide,
    setIsSlide,
    setIsChbSlide,
    slide,
    goToSlide,
  ]);

  function removeTagHeader(slideHtml: string) {
    return slideHtml.toString().replace(/<header(.|\n)*?<\/header>/g, ' ');
  }

  function goToApplication(value: string) {
    let url: string | undefined = '';
    if (value === '/application/APP_DEEPLINK_FLASH') {
      url = process.env.REACT_APP_DEEPLINK_FLASH;
    } else if (value === '/application/rand-edp-prod') {
      url = process.env.REACT_APP_DEEPLINK_ETUDEDEPOSTE;
    } else if (value === '/application/rand-edpV2-prod') {
      url = process.env.REACT_APP_DEEPLINK_ETUDEDEPOSTE;
    } else if (value === '/application/recrutlive') {
      url = process.env.REACT_APP_DEEPLINK_RECRUTLIVE;
    } else if (value === '/application/rand-bigdataweb-prod') {
      url = process.env.REACT_APP_DEEPLINK_BIGDATA;
    } else if (value === '/application/rand-satisfaction-prod') {
      url = process.env.REACT_APP_DEEPLINK_SATISFACTION;
    } else {
      url = '';
    }
    console.log(value + '=>' + url);
    window.open(url);
  }

  function dumpLog(str: string) {
    console.log(str);
  }

  const injectJS = () => {
    if (slide && slide.file) {
      const url = `${window.location.origin}`;
      let slideData: string | undefined = slide.file.slideHTML;
      slideData = removeTagHeader(slideData);
      const staticScript = `
      <script src="https://cdnjs.cloudflare.com/ajax/libs/hammer.js/2.0.8/hammer.min.js" /></script>
      <script>
        window.onload = function () {
          list = document.getElementsByTagName("a");
          for (var i = 0; i < list.length; i++) { 
            list[i].addEventListener("click", function (e) {
              e.preventDefault();
              var link = this.getAttribute("href");
              parent.postMessage(link.toString(),"${url}");
            });
          }
          var hammertime = new Hammer(document.body);
          hammertime.get('pinch').set({ enable: true });
          hammertime.on('swipeleft swiperight pinch', function(ev) {
            console.log('ev', ev.type);
            parent.postMessage(ev.type.toString(),"${url}");
          });
        }
      </script>
      `;

      //cas special for slide : s1_chaque_besoin_service , the href=/slide has generated
      slideData = slideData
        .toString()
        .replace(
          'n.href="/slide/"+t,',
          `n.href="/slide/"+t,n.addEventListener("click",function (e) {e.preventDefault();var link = this.getAttribute("href");parent.postMessage(link.toString(),"${url}");}),`
        );

      slideData = slideData
        .toString()
        .replace(
          'n.href="/slide/"+t : n.href="https://www.side.co/",',
          `n.href="/slide/"+t : n.href="https://www.side.co/",n.addEventListener("click",function (e) {e.preventDefault();var link = this.getAttribute("href");parent.postMessage(link.toString(),"${url}");}),`
        );

      if (isSubSlide) {
        slideData = dataSubSlide?.slide;
        if (slideData) {
          slideData = removeTagHeader(slideData);
        }
      }

      if (slideData) {
        const ArrayHtmlData = slideData.split('<body>');
        const resultMergeData = `${ArrayHtmlData[0]}${staticScript}${ArrayHtmlData[1]}`;

        setDataSlide(resultMergeData);
      }
    }
  };
  const injectJSCallback = useCallback(injectJS, [dataSubSlide?.slide, isSubSlide, slide]);
  const handleClose = () => {
    setIsPreview(false);
  };

  if (isPreview) {
    return <Preview handleClose={handleClose} dataPreview={dataPreview} />;
  }
  return (
    <React.Fragment>
      {slide && !('file' in slide) ? (
        <div className={styles['slideNotFound']}>
          <span
            dangerouslySetInnerHTML={{
              __html: slideNotFoundMsg,
            }}
          ></span>
        </div>
      ) : (
        <div className={styles['iframe__warpper']}>
          <iframe frameBorder="0" className={styles['iframe']} title="iframe" srcDoc={dataSlide} />
        </div>
      )}
    </React.Fragment>
  );
}

export default Iframe;
