import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSheets,
  getTemplates,
  getLoading,
  getLoadingPrev,
  // getFicheAgence,
} from 'src/Redux/FicheAgence/Selectors';
import { loadSheet, loadTemplates, ficheAgenceActions } from 'src/Redux/FicheAgence/Slice';
import { IFile, ISheet, ISlide } from 'src/db/Types';
import FicheAgence from './FicheAgence.component';
import { db } from 'src/db';
import _ from 'lodash';
import { getSelectedCxp } from 'src/Redux/SelectedCxp/Selectors';

const MemoFicheAgence = React.memo(FicheAgence);

const EnhancedFicheAgence: React.FC = () => {
  const [slide, setSlide] = useState<ISlide | IFile | undefined>(undefined);
  const templates = useSelector(getTemplates);
  const sheets = useSelector(getSheets);
  const selectedCxp = useSelector(getSelectedCxp);
  const loading = useSelector(getLoading);
  const loadingPrev = useSelector(getLoadingPrev);
  // const ficheAgence = useSelector(getFicheAgence);

  const dispatch = useDispatch();

  useEffect(() => {
    loadSheetCallback();
    loadTemplatesCallback();
    loadSlideDemo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTemplatesCallback = useCallback(() => {
    dispatch(loadTemplates());
  }, [dispatch]);

  const loadSheetCallback = useCallback(() => {
    dispatch(loadSheet());
  }, [dispatch]);

  const setLoading = useCallback(
    (value: boolean) => {
      dispatch(
        ficheAgenceActions.setLoading({
          loading: value,
        })
      );
    },
    [dispatch]
  );
  const setLoadingPrev = useCallback(
    (value: boolean) => {
      dispatch(
        ficheAgenceActions.setLoadingPrev({
          loadingPrev: value,
        })
      );
    },
    [dispatch]
  );

  async function loadSlideDemo() {
    try {
      setLoadingPrev(true);
      const slidesTable = await db.slides?.toArray();
      if (slidesTable && slidesTable.length > 0) {
        const slides = slidesTable.filter(slide => slide.sheetTemplate !== null);
        const filesIds = slides.map(slide => slide.zipFile._id);
        const files = await db.files?.where('_id').anyOf(filesIds).toArray();
        if (files && files.length > 0) {
          setSlide(files[0]);
        }
      }
      setLoadingPrev(false);
    } catch (error) {
      console.log(error);
    }
  }
  function storeNewSheet(data: ISheet) {
    db.sheets
      ?.where('_id')
      .equals(data._id)
      .modify(data)
      .then(() => {
        dispatch(loadSheet());
      });
  }
  return (
    <MemoFicheAgence
      sheets={sheets}
      templates={templates}
      slide={slide}
      selectedCxp={selectedCxp}
      loading={loading}
      loadingPrev={loadingPrev}
      // ficheAgence={ficheAgence}
      setLoading={setLoading}
      storeNewSheet={storeNewSheet}
    />
  );
};

export default EnhancedFicheAgence;
