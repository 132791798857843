/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState, useCallback } from 'react';
import * as _ from 'lodash';
import puzzleSmall from 'src/Assets/home/puzzle-small-icon.svg';
import styles from './Presentations.module.scss';
import { ICxp, IPresentation } from 'src/db/Types';

export interface Iprops {
  presentations: IPresentation[] | undefined;
  selectedCxp: ICxp;
}
function Presentations({ presentations, selectedCxp }: Iprops) {
  const [listFiletredPrez, setListFiletredPrez] = useState<IPresentation[]>([]);

  useEffect(() => {
    filterPresentationCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCxp, presentations]);

  // Filtrer presentations by cexp
  const filterPresentation = () => {
    const listPrezFiltred: IPresentation[] = _.filter(presentations, prez => {
      return _.some(prez.cexps, selectedCxp);
    });
    const list = [
      'découverte',
      'recrutement',
      'intérim',
      'sourcing',
      'prévention',
      'outils',
      'groupe',
    ];
    let listOrdered: IPresentation[] = [];
    const listOrdered2: IPresentation[] = [];
    _.forEach(listPrezFiltred, item => {
      if (_.includes(list, item.title.toLowerCase())) {
        listOrdered[_.indexOf(list, item.title.toLowerCase())] = item;
      } else {
        listOrdered2.push(item);
      }
    });
    listOrdered = listOrdered.filter(item => item);
    setListFiletredPrez([...listOrdered, ...listOrdered2]);
  };
  const filterPresentationCallback = useCallback(filterPresentation, [presentations, selectedCxp]);

  const renderItemsPresentation = () => {
    return (
      <React.Fragment>
        {_.range(8).map(index => {
          if (listFiletredPrez[index]) {
            return (
              <div className={styles['item']} key={index}>
                <div className={styles['icon']}>
                  <img src={puzzleSmall} alt="puzzle" />
                </div>
                <span>{listFiletredPrez[index].title}</span>
                <a
                  href={`/presentation/${listFiletredPrez[index]._id}`}
                  className={styles['link']}
                ></a>
              </div>
            );
          } else {
            return <div className={`${styles['item']} ${styles['item-empty']}`} key={index}></div>;
          }
        })}
      </React.Fragment>
    );
  };
  return (
    <div className={styles['prez-list']} data-testid="list-prez">
      {renderItemsPresentation()}
    </div>
  );
}

export default Presentations;
