/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Formik, Form, Field } from 'formik';
import history from 'src/Utils/history';
import { IFormAgenceComp } from './FormAgence.types';
import {
  Endpoint,
  ErrorMsg,
  ficheAgenceCreatedMsg,
  ficheAgenceEditedMsg,
  ficheAgenceUploadPhotoMsg,
  ficheAgenceFieldPlaceholderMsg,
  ErrorMsgCxp,
} from 'src/Utils/constants';
import { serviceOptions } from 'src/Services/API';
import { IMeta, ISheet } from 'src/db/Types';
import { toBase64 } from 'src/Utils/ficheAgence';

import styles from './FormAgence.module.scss';
import Toast from 'src/Components/Toast';
import deleteIcon from 'src/Assets/preview/delete-blue.svg';
import closeIcon from 'src/Assets/ficheAgence/close.svg';
import bgImage from 'src/Assets/ficheAgence/ficheAgence.png';
import Modal from 'src/Components/Modal';

interface IMapping {
  [propertyName: string]: string;
}
const FormAgence: React.FC<IFormAgenceComp> = ({
  template,
  sheets,
  selectedCxp,
  handlePreview,
  storeNewSheet,
  bulkAddData,
}) => {
  const refSubmit = useRef<HTMLButtonElement | null>(null);
  const [currentSheet, setCurrentSheet] = useState<ISheet | undefined>(undefined);
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [showPopupConfirmClose, setshowPopupConfirmClose] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    getSheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCxp, sheets, template, message]);

  function getSheet() {
    if (sheets && sheets.length > 0) {
      const sheet: ISheet | undefined = _.find(sheets, sheet => {
        return sheet.cexp === selectedCxp._id;
      });
      setCurrentSheet(sheet);
    }
  }

  function formatNameVariable(name: string, i: number) {
    return name.replace(' ', '_').concat(i.toString());
  }

  function getInitiaState() {
    const ivalues: IMapping = {};
    if (currentSheet) {
      if (template && template.keys) {
        template.keys.forEach((key, i) => {
          let value: string | IMeta = '';
          if (currentSheet && currentSheet.metas) {
            _.forEach(currentSheet.metas, meta => {
              if (meta.key === key.name) {
                value = meta.value.replace('<br />', '\n');
              }
            });
          }
          const name: string = formatNameVariable(key.name, i).toString();
          ivalues[name] = value;
        });
      }
    }
    return ivalues;
  }

  function renderMessages() {
    if (message !== '') {
      setInterval(() => {
        setMessage('');
      }, 6000);
      return <Toast message={message} cta={false} />;
    }
    return;
  }
  const handleSubmit = async (values: any) => {
    try {
      const metas: Array<IMeta> = [];
      if (template && template.keys) {
        _.forEach(template.keys, (meta, i) => {
          const name = formatNameVariable(meta.name, i).toString();
          metas.push({
            key: meta.name,
            value: values[name] ? values[name].replace('\n', '<br />') : '',
            valueType: meta.valueType,
          });
        });
      }
      if (currentSheet) {
        if (template) {
          if (selectedCxp && selectedCxp._id !== '') {
            serviceOptions.axios
              ?.put(`${Endpoint.editSheet}/${currentSheet._id}`, {
                sheetTemplate: template._id,
                cexp: selectedCxp._id,
                metas: metas,
              })
              .then(res => {
                setMessage(ficheAgenceEditedMsg);
                setFormChanged(false);
                const { data } = res;
                if (data) {
                  // edit sheet stored in indexeddb
                  storeNewSheet(data);
                  setCurrentSheet(data);
                  getSheet();
                }
              })
              .catch(error => {
                setMessage(ErrorMsg);
                console.log(error);
              });
          } else {
            setMessage(ErrorMsgCxp);
          }
        }
      } else {
        if (template) {
          if (selectedCxp && selectedCxp._id !== '') {
            serviceOptions.axios
              ?.post(`${Endpoint.addSheet}`, {
                sheetTemplate: template._id,
                cexp: selectedCxp._id,
                metas: metas,
              })
              .then(res => {
                const { data } = res;
                setFormChanged(false);
                const sheet: ISheet | undefined = _.find(data, sheet => {
                  return sheet.cexp === selectedCxp._id;
                });
                if (data) {
                  bulkAddData(data);
                }
                setCurrentSheet(sheet);
                getSheet();
                setMessage(ficheAgenceCreatedMsg);
              })
              .catch(error => {
                setMessage(ErrorMsg);
                console.log(error);
              });
          } else {
            setMessage(ErrorMsgCxp);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className={styles['agence']}>
        {showPopupConfirmClose && (
          <Modal onClose={_ => setshowPopupConfirmClose(false)}>
            <div className={styles['alert__confirmExit']}>
              <p
                className={styles['alert__message']}
                dangerouslySetInnerHTML={{
                  __html: 'Des données ont été modifiées voulez-vous quitter sans sauvegarder?',
                }}
              ></p>
              <div className={styles['alert__cta']}>
                <button className={styles['alert__cta__link']} onClick={() => history.push('/')}>
                  oui
                </button>
                <button
                  className={styles['alert__cta__link']}
                  onClick={() => {
                    refSubmit.current?.click();
                    setshowPopupConfirmClose(false);
                    setFormChanged(false);
                  }}
                >
                  sauvegarder
                </button>
              </div>
            </div>
          </Modal>
        )}
        <div className={styles['content']}>
          <ul className={styles['menu__nav']}>
            <li className={styles['back']}>
              <button
                onClick={_ => {
                  if (formChanged) {
                    setshowPopupConfirmClose(true);
                  } else {
                    history.push('/');
                  }
                }}
              >
                <img src={closeIcon} alt="back" />
              </button>
            </li>
          </ul>
          <h1 className={styles['title']} data-testid="title">
            carte d’identité de l’agence
          </h1>
          <div className={styles['form']}>
            <Formik enableReinitialize initialValues={getInitiaState()} onSubmit={handleSubmit}>
              {({ values, setFieldValue, initialValues }) => (
                <Form>
                  {template &&
                    template.keys.map((key, i) => {
                      const name = formatNameVariable(key.name, i);
                      return (
                        <React.Fragment key={i}>
                          <div className={styles['input_wrap']} key={i}>
                            <label htmlFor={name}>{key.name.toLowerCase()}</label>
                            {key.valueType === 'base64' ? (
                              <React.Fragment>
                                {!values[name] && (
                                  <div className={styles['input_file_wrap']}>
                                    <div
                                      className={styles['input_file_label']}
                                      dangerouslySetInnerHTML={{
                                        __html: ficheAgenceUploadPhotoMsg,
                                      }}
                                    ></div>
                                    <input
                                      id={name}
                                      name={name}
                                      data-testid={name}
                                      type="file"
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        if (!event.target.files) return;
                                        const photo = event.target.files[0];
                                        setFormChanged(true);
                                        if (
                                          (photo && photo.type === 'image/png') ||
                                          photo.type === 'image/jpg' ||
                                          photo.type === 'image/jpeg'
                                        ) {
                                          const nameFiled = formatNameVariable(key.name, i);
                                          toBase64(event.target.files[0]).then(
                                            (base64: unknown) => {
                                              const removePrefix: string = base64 as string;
                                              setFieldValue(
                                                nameFiled.toString(),
                                                removePrefix.split(',')[1]
                                              );
                                            }
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                                {values[name] && (
                                  <div className={styles['previewImage_wrap']}>
                                    <div className={styles['previewImage']}>
                                      <img
                                        src={`data:image/jpeg;base64,${values[name]}`}
                                        alt="agence"
                                      />
                                    </div>
                                    <div className={styles['previewImageDetail']}>
                                      <button
                                        onClick={() => {
                                          const nameFiled = formatNameVariable(key.name, i);
                                          setFieldValue(nameFiled.toString(), '');
                                        }}
                                      >
                                        <img src={deleteIcon} alt="supprimer" /> supprimer
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <Field
                                  id={name}
                                  name={name}
                                  placeholder={ficheAgenceFieldPlaceholderMsg}
                                  component="textarea"
                                  rows="2"
                                  data-testid={name}
                                  maxLength="600"
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const nameFiled = formatNameVariable(key.name, i);
                                    setFieldValue(nameFiled.toString(), event.target.value);
                                    if (initialValues[nameFiled] !== event.target.value) {
                                      setFormChanged(true);
                                    }
                                  }}
                                />
                              </React.Fragment>
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  <div className={styles['button_wrap']}>
                    <button
                      ref={refSubmit}
                      data-testid="save"
                      type="submit"
                      className="button button--s button--filled"
                    >
                      valider
                    </button>
                    <button
                      data-testid="preview"
                      type="button"
                      onClick={() => handlePreview(values)}
                      className="button button--s"
                    >
                      voir l'apercu
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className={styles['sidebar']} style={{ backgroundImage: `url(${bgImage})` }}></div>
      </div>
      {renderMessages()}
    </React.Fragment>
  );
};
export default FormAgence;
