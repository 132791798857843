import React from 'react';
import FormAgence from './FormAgence.component';
import { db } from 'src/db';
import { IFormAgence } from './FormAgence.types';
import { ISheet } from 'src/db/Types';

const MemoFormAgence = React.memo(FormAgence);

const EnhancedFormAgence: React.FC<IFormAgence> = ({
  handlePreview,
  selectedCxp,
  sheets,
  storeNewSheet,
  template,
}) => {
  function bulkAddData(data: Array<ISheet> | undefined) {
    if (data) {
      db.sheets?.bulkAdd(data);
    }
  }

  return (
    <MemoFormAgence
      template={template}
      sheets={sheets}
      selectedCxp={selectedCxp}
      handlePreview={handlePreview}
      storeNewSheet={storeNewSheet}
      bulkAddData={bulkAddData}
    />
  );
};

export default EnhancedFormAgence;
