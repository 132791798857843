import { combineReducers } from '@reduxjs/toolkit';
import { AppDispatch } from './store';
import { authReducer } from './Auth/Slice';
import { cxpReducer } from './Cxp/Slice';
import { presentationReducer } from './Presentation/Slice';
import { selectedCxpReducer } from './SelectedCxp/Slice';
import docReducer from './Doc/Slice';
import syncReducer from './Sync/Slice';
import syncLoadingReducer from './SyncLoading/Slice';
import ficheAgenceReducer from './FicheAgence/Slice';

const rootReducer = combineReducers({
  auth: authReducer,
  cxp: cxpReducer,
  presentation: presentationReducer,
  selectedCxp: selectedCxpReducer,
  doc: docReducer,
  sync: syncReducer,
  syncLoading: syncLoadingReducer,
  ficheAgence: ficheAgenceReducer,
  // insert your own reducers here
  // see https://redux-toolkit.js.org/api/createSlice for documentation
  // see https://redux-toolkit.js.org/api/createAsyncThunk for async actions handling
});

export type RootState = ReturnType<typeof rootReducer>;

export interface ThunkAPI {
  dispatch: AppDispatch;
  state: RootState;
  extra?: unknown;
  rejectValue?: unknown;
}

export default rootReducer;
