import React from 'react';
import { FallbackProps } from './ErrorFallback.types';
import styles from './ErrorFallback.module.scss';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div role="alert" className={styles['error_wrapper']}>
      <div className={styles['error']}>
        <p>un problème est survenu. veuillez réessayer plus tard.</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary} className="button button--s">
          Try again
        </button>
      </div>
    </div>
  );
};
export default ErrorFallback;
