// eslint-disable-next-line @typescript-eslint/no-explicit-any

export const blob2Base64 = (blob: Blob): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result?.toString());
    reader.onerror = error => reject(error);
  });
};

export const db_blob2Base64 = async (blob: Blob): Promise<string> => {
  return blob2Base64(blob).then(content => {
    content = content.replace('data:application/octet-stream;base64,', '');
    return content;
  });
};
