import { createSelector } from 'reselect';
import { RootState } from 'src/Redux/RootReducer';
import { DocState } from './Types';

export const getDocState = (state: RootState): DocState => state.doc;

export const getDocList = createSelector([getDocState], (DocState: DocState) => DocState);
export const getShowDocByCategory = createSelector(
  [getDocState],
  (DocState: DocState) => DocState.showDocByCategory
);
export const getIsPreviewDoc = createSelector(
  [getDocState],
  (DocState: DocState) => DocState.showDocPreview
);
export const getIdDocumentToPreview = createSelector(
  [getDocState],
  (DocState: DocState) => DocState.idDocumentToPreview
);
export const getCategorieIdSelected = createSelector(
  [getDocState],
  (DocState: DocState) => DocState.idCategorieSelected
);

export default getDocState;
