/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import * as _ from 'lodash';

import history from 'src/Utils/history';
import { accountChangedMsg, syncLink } from 'src/Utils/constants';

import Presentations from './Presentations/index';
import Documents from './Documents/index';
import Navbar from 'src/Containers/Navbar';
import Loading from 'src/Components/Loading';
import Dropdown from 'src/Components/Dropdown';
import { IItemDropdown } from 'src/Components/Dropdown/Dropdown.types';
import Modal from 'src/Components/Modal';

import { IPresentation, IBindings, ICategory, ICxp } from 'src/db/Types';
import { AuthState } from 'src/Redux/Auth/Types';

import styles from './Home.module.scss';

interface IHomeProps {
  presentations: IPresentation[] | undefined;
  categories: ICategory[] | undefined;
  bindings: IBindings[] | undefined;
  selectedCxp: ICxp;
  isPreviewDoc: boolean;
  User: AuthState | undefined;
  loading: boolean;
  listCexps: IItemDropdown[];
  onChangeSelectCexp: (item: IItemDropdown) => void;
}
function Home({
  presentations,
  categories,
  bindings,
  selectedCxp,
  isPreviewDoc,
  User,
  loading,
  listCexps,
  onChangeSelectCexp,
}: IHomeProps) {
  if (loading) {
    return <Loading />;
  }

  function showModalSync() {
    return (
      <Modal onClose={() => false}>
        <div className={styles['alert__compte']}>
          <p
            className={styles['alert__message']}
            dangerouslySetInnerHTML={{
              __html: accountChangedMsg,
            }}
          ></p>
          <div className={styles['alert__cta']}>
            <button
              className={styles['alert__cta__link']}
              onClick={() => {
                history.push(syncLink);
              }}
            >
              synchroniser maintenant
            </button>
          </div>
        </div>
      </Modal>
    );
  }
  return (
    <React.Fragment>
      {User && User.compteChanged && showModalSync()}
      {!isPreviewDoc && (
        <div className={styles['homepage']}>
          <Navbar infos={User} />
          <div className={styles['cxp-select']}>
            <div className={styles['dropdown-wrapper']}>
              <Dropdown
                title="spécialité"
                list={listCexps}
                selectedItem={{ id: selectedCxp._id, label: selectedCxp.name }}
                onchange={(e: IItemDropdown) => onChangeSelectCexp(e)}
              />
            </div>
          </div>
          <section className={styles['block-prez']}>
            <Presentations presentations={presentations} selectedCxp={selectedCxp} />
            <div className={styles['illustration-container']}></div>
          </section>
          <div className={styles['block-documents']}>
            <Documents categories={categories} bindings={bindings} selectedCxp={selectedCxp} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Home;
