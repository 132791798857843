import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import ReactGA from 'react-ga4';

import { IBindings } from 'src/Redux/Doc/Types';
import { getAuthState } from 'src/Redux/Auth/Selectors';
import { authActions } from 'src/Redux/Auth/Slice';
import { getIsPreviewDoc } from 'src/Redux/Doc/Selectors';
import { getSelectedCxp } from 'src/Redux/SelectedCxp/Selectors';
import { selectedCxpActions } from 'src/Redux/SelectedCxp/Slice';
import { IItemDropdown } from 'src/Components/Dropdown/Dropdown.types';
import { syncLoadingActions } from 'src/Redux/SyncLoading/Slice';

import { IUser, IPresentation, ICategory } from 'src/db/Types';
import { db } from 'src/db';

import Home from './Home.component';

const MemoHome = React.memo(Home);

const EnhancedHome: React.FC = () => {
  const [infos, setInfos] = useState<IUser | undefined>();
  const [loading, setLoading] = useState(true);
  const [presentations, setPresentations] = useState<IPresentation[] | undefined>();
  const [categories, setCategories] = useState<ICategory[] | undefined>();
  const [bindings, setBindings] = useState<IBindings[] | undefined>();
  const [listCexps, setListCexps] = useState<IItemDropdown[] | []>([]);

  const selectedCxp = useSelector(getSelectedCxp);
  const isPreviewDoc = useSelector(getIsPreviewDoc);
  const User = useSelector(getAuthState);

  const dispatch = useDispatch();

  useEffect(() => {
    const setupData = async () => {
      const infosUser = await db.user?.limit(1).toArray();
      const presentations = await db.presentations?.toArray();
      const categories = await db.categories?.toArray();
      const bindings = await db.bindings?.toArray();
      if (infosUser) {
        setInfos(_.nth(infosUser, 0));
      }
      setPresentations(presentations ? presentations : []);
      setCategories(categories ? categories : []);
      setBindings(bindings ? bindings : []);
      setLoading(false);
    };
    setupData();
  }, []);

  useEffect(() => {
    if (infos && User) {
      if (infos?.email !== User.email) {
        dispatch(authActions.setCompteChanged(true));
        dispatch(
          syncLoadingActions.setShowAlertSync({
            showAlertSync: false,
          })
        );
      } else {
        dispatch(authActions.setCompteChanged(false));
      }
    }
  }, [infos, User, dispatch]);

  useEffect(() => {
    const formatDataSelect = () => {
      if (listCexps.length === 0) {
        if (infos && infos.defaultCexps && infos.userCexps) {
          const sortedList = _.sortBy(
            [...infos.defaultCexps, ...infos.userCexps],
            [
              function (u) {
                return u.isDefault;
              },
            ]
          );
          const cexpsFormated = _.map(sortedList, cxp => {
            return { id: cxp._id, label: cxp.name };
          });
          setListCexps(cexpsFormated);
        }
      }
    };
    formatDataSelect();
  }, [infos, listCexps.length]);

  useEffect(() => {
    if (infos) {
      const cxpIndex = _.findIndex([...infos.defaultCexps, ...infos.userCexps], function (cxp) {
        return cxp._id === selectedCxp._id;
      });
      if ((selectedCxp._id === '' && listCexps.length > 0) || cxpIndex === -1) {
        setDefaultCxp();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCexps, selectedCxp, infos]);

  // Set default Cexp
  function setDefaultCxp() {
    if (infos) {
      const cxpIndex = _.findIndex([...infos.defaultCexps, ...infos.userCexps], function (cxp) {
        return cxp._id === selectedCxp._id;
      });
      if (selectedCxp._id === '' || cxpIndex === -1) {
        if (infos && infos.defaultCexps && infos.userCexps) {
          const listCexps = [...infos.defaultCexps, ...infos.userCexps];
          dispatch(selectedCxpActions.setSelectedCxp(listCexps[0]));
        }
      }
    }
  }

  // Handle change select
  const onChangeSelectCexp = (item: IItemDropdown) => {
    if (infos && infos.defaultCexps && infos.userCexps) {
      const selectedCxp = _.find(
        [...infos.defaultCexps, ...infos.userCexps],
        cxp => cxp._id === item.id
      );
      if (selectedCxp) {
        console.log(`ReactGA:Selection Cexp:(${User?.agence}):`, selectedCxp.name);
        ReactGA.event('Selection Cexp', {
          cexp: selectedCxp.name,
          brand: User?.brand?.name,
          region: User?.region,
          zone: User?.zone,
          agence: User?.agence,
        });
        dispatch(selectedCxpActions.setSelectedCxp(selectedCxp));
      }
    }
  };

  return (
    <MemoHome
      presentations={presentations}
      categories={categories}
      bindings={bindings}
      selectedCxp={selectedCxp}
      isPreviewDoc={isPreviewDoc}
      listCexps={listCexps}
      onChangeSelectCexp={onChangeSelectCexp}
      User={User}
      loading={loading}
    />
  );
};

export default EnhancedHome;
