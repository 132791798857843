import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { db } from 'src/db';
import { ICategory, IDocument } from 'src/db/Types';

import DocByCategory from './DocByCategory.component';
import { IDocByCategory } from './DocByCategory.Types';

const MemoDocByCategory = React.memo(DocByCategory);

const EnhancedDocByCategory: React.FC<IDocByCategory> = ({
  cxp,
  handleCancelDocByCategory,
  idCategory,
}) => {
  const [category, setCategory] = useState<ICategory>();
  const [documentsFiltred, setDocumentsFiltred] = useState<IDocument[]>([]);
  const [documentsFiltredNb, setDocumentsFiltredNb] = useState<number>(0);
  const [documentsSearch, setDocumentsSearch] = useState<IDocument[]>([]);

  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [docToPreview, setdocToPreview] = useState<IDocument>();

  const dispatch = useDispatch();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCategory, cxp, dispatch]);

  const getData = async () => {
    const category = await db.categories?.where('_id').equals(idCategory).first();
    const bindings = await db.bindings?.toArray();
    if (category) {
      setCategory(category);
      const idsDocs = _.filter(
        bindings,
        item => cxp._id === item.cexp && item.category === idCategory
      ).map(item => item.document);
      const documents: IDocument[] | undefined = await db.documents
        ?.where('_id')
        .anyOf(idsDocs)
        .toArray();
      if (documents) {
        setDocumentsFiltred(documents as IDocument[]);
        setDocumentsSearch(documents as IDocument[]);
        setDocumentsFiltredNb(documents.length);
      }
    }
  };
  console.log(documentsFiltred);
  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.currentTarget.value.trim();

    if (searchValue.length > 0) {
      const documentsSearchResults = _.filter(
        documentsFiltred,
        dc => dc.title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
      );
      setDocumentsSearch(documentsSearchResults);
      setDocumentsFiltredNb(documentsSearchResults.length);
    } else {
      setDocumentsSearch(documentsFiltred);
      setDocumentsFiltredNb(documentsFiltred.length);
    }
    return;
  };
  const onClickDoc = (dc: IDocument) => {
    setdocToPreview(dc);
    setIsPreview(true);
  };

  const handleClosePreview = () => {
    setIsPreview(false);
  };
  return (
    <MemoDocByCategory
      isPreview={isPreview}
      docToPreview={docToPreview as IDocument}
      handleClosePreview={handleClosePreview}
      documentsFiltred={documentsFiltred}
      handleCancelDocByCategory={handleCancelDocByCategory}
      category={category as ICategory}
      onChangeSearchInput={onChangeSearchInput}
      documentsFiltredNb={documentsFiltredNb}
      documentsSearch={documentsSearch}
      onClickDoc={onClickDoc}
    />
  );
};

export default EnhancedDocByCategory;
