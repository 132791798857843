/* eslint-disable react-hooks/exhaustive-deps*/
import { Checkbox, DropDown } from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { Notes as IconFile } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState, useEffect } from 'react';
import { util } from '../AdminBO/AdminBO.component.ext';

import styles from './FileInput.module.scss';
import { Props, defaultProps, IFileData, IFileTarget } from './FileInput.types';

interface PropsRendreFiles {
  fileData: IFileData;
}

const FileInput = (props: Props) => {
  const [fileDataList, setFileDataList] = useState<IFileData[]>([]);
  const [drawCompletTitle, setDrawCompletTitle] = useState(false);

  useEffect(() => {
    setFileDataList(props.filesDataInput);
  }, []);

  useEffect(() => {
    props.onChangeData(fileDataList);
  }, [fileDataList, props]);

  const updateFile = (file: IFileData, target: IFileTarget | undefined) => {
    const _files: IFileData[] = [];
    for (let i = 0; i < fileDataList.length; i++) {
      _files.push(fileDataList[i]);
      if (file.file.name === fileDataList[i].file.name) {
        _files[i].target = target;
      }
    }
    setFileDataList(_files);
  };

  const addFile = (file: File) => {
    const found = fileDataList.filter(item => item.file.name === file.name);
    if (found.length !== 0) {
      console.log('file already exist:', file.name);
      return;
    }

    const s2u = util.convertFileName2SlideToUpload(file);

    setFileDataList(fileDataList => [
      ...fileDataList,
      { key: file.name, file: file, base64content: undefined, slideTitle: s2u.slideTitle },
    ]);
  };

  const removeFile = (name: string) => {
    setFileDataList(fileDataList.filter(item => item.file.name !== name));
  };

  const RendreFiles = (props2: PropsRendreFiles) => {
    return (
      <tr>
        <th className={styles.colIcon}>
          <IconFile></IconFile>
        </th>
        <th className={styles.colFileName}>
          {drawCompletTitle ? props2.fileData.file?.name : props2.fileData.slideTitle}
        </th>
        <th className={styles.colFileSize}>
          <DropDown
            items={props.fileTargets ?? []}
            canBeReset={true}
            placeholder="pas de correspondance"
            selectedItem={props2.fileData.target}
            onSelectItem={item => {
              updateFile(props2.fileData, item);
              props.onFileTargetChange(props2.fileData.file.name, item);
            }}
            keyValueExtractor={item => {
              return {
                key: item.slideId,
                value: drawCompletTitle ? item.slideCompleteTitle : item.slideTitle,
              };
            }}
          />
        </th>
        <th className={styles.colDelete}>
          <div>
            <button
              onClick={() => {
                removeFile(props2.fileData.file.name);
              }}
            >
              <Trashcan></Trashcan>
            </button>
          </div>
        </th>
      </tr>
    );
  };

  return (
    <div>
      <div className={styles.input_file_wrap}>
        <div className={styles.input_file_label}>{props.titleNode}</div>

        <input
          id={props.name}
          name={props.name}
          type="file"
          accept={props.accept}
          multiple={props.multiFiles}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.target.files) return;
            for (let i = 0; i < event.target.files.length; i++) {
              addFile(event.target.files[i]);
            }
          }}
        />
      </div>
      <Checkbox
        label={'afficher seulement les titres des slides'}
        checked={!drawCompletTitle}
        onChange={event => setDrawCompletTitle(!event.target.checked)}
      />
      {props.displayMode === 'LIST' && (
        <table className={styles.tableContainer}>
          <tbody>
            {fileDataList.map(value => (
              <RendreFiles key={value.key} fileData={value}></RendreFiles>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

FileInput.displayName = 'FileInput';
FileInput.defaultProps = defaultProps;

export default FileInput;
