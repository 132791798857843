import React, { useEffect, useRef } from 'react';
import { Router, Switch } from 'react-router-dom';
import { useAuth0 } from 'src/Providers/Auth0';
import history from 'src/Utils/history';
import { setupAPI } from './Services/API/APIConfigurator';
import Connexion from './Containers/Connexion';
import Home from './Containers/Home';
import Loading from 'src/Components/Loading';
import PresentationDetail from './Containers/PresentationDetail';
import PrivateRoute from './Components/PrivateRoute';
import Sync from './Containers/Sync';
import CentresExperts from './Containers/CentresExperts';
import FicheAgence from './Containers/FicheAgence';
import AdminBO from './Containers/Admin/AdminBO';
import useInitializeAnalytics from './Hooks/Analytics/useInitializeAnalytics';

function App() {
  useInitializeAnalytics();

  const appRef = useRef<HTMLDivElement | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { isAuthenticated, loading } = useAuth0()!;
  useEffect(() => setupAPI(), []);
  if (loading) {
    return <Loading />;
  }

  if (!isAuthenticated && !loading) {
    return <Connexion />;
  }

  return (
    <div className="App" ref={appRef}>
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute path="/presentation/:id" component={PresentationDetail} />
          <PrivateRoute path="/fiche-agence" component={FicheAgence} />
          <PrivateRoute path="/synchronisation" component={Sync} />
          <PrivateRoute path="/centres-experts" component={CentresExperts} />
          <PrivateRoute path="/admin" component={AdminBO} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
