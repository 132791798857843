import React from 'react';
import loaderIcon from 'src/Assets/loader_blue.gif';
import styles from './Loading.module.scss';

export default function Loading() {
  return (
    <div className={'App'}>
      <div className={styles['loading']}>
        <img
          src={loaderIcon}
          alt="loader"
          style={{ width: '35px', display: 'inline-block', margin: '0 auto ' }}
        />
      </div>
    </div>
  );
}
