import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from 'src/Providers/Auth0';
import { serviceOptions } from 'src/Services/API/index';
import history from 'src/Utils/history';
import { Endpoint } from 'src/Utils/constants';
import { setToken } from 'src/Utils/auth';
import { AuthState } from './Types';

const initialState: AuthState = {
  ssid: '',
  email: '',
  firstName: '',
  lastName: '',
  brand: {
    _id: '',
    code: '',
    name: '',
  },
  region: '',
  zone: '',
  agence: '',
  hasForcedBrand: false,
  useMinimalCexp: false,
  regions: [],
  zones: [],
  userCexps: [],
  defaultCexps: [],
  lastSync: '',
  lastContentUpdate: '',
  compteChanged: false,
};

/**
 * Réquperer les infos de l'utlisateur
 */
export const fetchInfosUser = createAsyncThunk('auth/fetchInfos', async () => {
  return await serviceOptions.axios?.get(`${Endpoint.userInfo}`).then(res => res.data);
});

/**
 *  Récuperation + sauvgarde token puzzle
 */
export const loadPuzzleToken = async (token: string) => {
  const myInit = {
    method: 'post',
    body: JSON.stringify({}),
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const Res = await fetch(`${process.env.REACT_APP_API_URL}${Endpoint.auth}`, myInit);
  if (Res.status === 401) {
    logout();
    history.push('/');
  }
  const result = await Res.json();
  setToken(result.token);
};

/**
 * Auth Slice(autogenarate actions)
 */
export const { reducer: authReducer, actions: authActions } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCompteChanged: (state, action: PayloadAction<boolean>) => {
      state.compteChanged = action.payload;
    },
  },
  extraReducers: {
    [fetchInfosUser.fulfilled.toString()]: (state, action: PayloadAction<AuthState>) => {
      state.ssid = action.payload.ssid;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.brand = action.payload.brand;
      state.region = action.payload.region;
      state.zone = action.payload.zone;
      state.agence = action.payload.agence;
      state.hasForcedBrand = action.payload.hasForcedBrand;
      state.useMinimalCexp = action.payload.useMinimalCexp;
      state.regions = action.payload.regions;
      state.zones = action.payload.zones;
      state.userCexps = action.payload.userCexps;
      state.defaultCexps = action.payload.defaultCexps;
      state.lastContentUpdate = action.payload.lastContentUpdate;
      state.lastSync = action.payload.lastSync;
    },
    // [fetchInfosUser.pending]: (state, action) => {},
    // [fetchInfosUser.rejected]: (state, action) => {},
  },
});

export default authReducer;
