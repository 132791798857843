import React, { useState, useEffect } from 'react';
import styles from './DropdownSearch.module.scss';
import { IDropDownSearch, IItemDropdownSearch } from './DropdownSearch.types';

const DropdownSearch = ({ title, list, onchange, emptyListCexp }: IDropDownSearch) => {
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [listFiltred, setListFiltred] = useState<Array<IItemDropdownSearch>>([]);

  useEffect(() => {
    if (list.length > 0) {
      const newList = list.filter(
        item => item.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1
      );
      setListFiltred(newList);
    }
  }, [filter, list]);

  function selectItem(item: IItemDropdownSearch) {
    setIsListOpen(false);
    onchange(item);
  }

  return (
    <div className={styles['dd-wrapper']}>
      <button
        type="button"
        data-testid="button-header"
        className={`${styles['dd-header']} ${isListOpen ? styles['dd-header-open'] : ''} `}
      >
        <div className={styles['dd-header-title']}>
          <input
            type="text"
            value={filter}
            onChange={e => {
              setFilter(e.target.value);
              if (!isListOpen) {
                setIsListOpen(true);
              }
            }}
            placeholder={title.toLowerCase()}
            onFocus={() => {
              if (!isListOpen) {
                setIsListOpen(true);
              }
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              if (!e.relatedTarget) {
                setIsListOpen(false);
                setFilter('');
              }
            }}
          />
        </div>
      </button>
      {isListOpen && (
        <div role="list" className={styles['dd-list']}>
          {list.length === 0 || listFiltred.length === 0 ? (
            <button
              data-testid="dropdownSearch-list"
              className={`${styles['dd-list-item']}`}
              onClick={() => {
                setIsListOpen(false);
                setFilter('');
              }}
            >
              {emptyListCexp}
            </button>
          ) : null}
          {listFiltred.length > 0 &&
            listFiltred.map((item, index) => (
              <button
                data-testid="dropdownSearch-list"
                type="button"
                className={`${styles['dd-list-item']}`}
                key={index}
                onClick={() => {
                  selectItem(item);
                  setFilter('');
                }}
              >
                {item.label}
              </button>
            ))}
        </div>
      )}
    </div>
  );
};

export default DropdownSearch;
