import React, { useState, useRef } from 'react';
import { Gestures } from 'react-gesture-handler';
import PDFViewer from 'mgr-pdf-viewer-react';
import { useKey } from 'src/Utils/keybordnav';

import styles from './Pdf.module.scss';
import loaderIcon from 'src/Assets/loader_blue.gif';
//import LeftArrowButton from 'src/Assets/detail/LeftArrowButton.svg';
//import RightArrowButton from 'src/Assets/detail/RightArrowButton.svg';

//import pdfjsLib from 'pdfjs-dist/build/pdf';
//import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
//import * as pdfjsLib from 'pdfjs-dist/webpack';
//import * as pdfjsLib from 'pdfjs-dist';

import arrowRightwhite from 'src/Assets/detail/arrowRightwhite.svg';

const minScale = 1;
const maxScale = 3;
const stepScale = 0.2;
interface Iprops {
  source: string;
}

function Pdf({ source }: Iprops) {
  const refButtonNext = useRef<HTMLButtonElement | null>(null);
  const refButtonPrev = useRef<HTMLButtonElement | null>(null);
  const refButtonPinchIn = useRef<HTMLButtonElement | null>(null);
  const refButtonPinchOut = useRef<HTMLButtonElement | null>(null);
  const [scale, setScale] = useState(1);
  /*
  //pdfjsLib.getDocument()
  pdfjsLib.GlobalWorkerOptions.workerSrc =
    process.env.PUBLIC_URL + '/assets/js/pdfjs/pdf.worker.min.js';

  // Load pdf
  pdfjsLib.getDocument('pdf_url').promise.then((promise: pdfjsLib.PDFDocumentProxy) => {
    console.log(promise);
  });
*/
  //const pdfDoc = pdfjsLib.getDocument(source);
  //console.log('dddddddddd', pdfDoc);
  /* 

   .then(function (pdfDoc_) {
    pdfDoc = pdfDoc_;
    document.getElementById('page_count').textContent = pdfDoc.numPages;

    // Initial/first page rendering
    renderPage(pageNum);
  });
*/
  useKey('ArrowRight', () => {
    if (refButtonNext && refButtonNext.current) {
      refButtonNext.current.click();
    }
  });

  useKey('ArrowLeft', () => {
    if (refButtonPrev && refButtonPrev.current) {
      refButtonPrev.current.click();
    }
  });
  return (
    <>
      <div className={styles['pdf__zoom']}>
        <button
          type="button"
          ref={refButtonPinchOut}
          onClick={() => {
            if (scale < maxScale) {
              setScale(scale + stepScale);
            }
          }}
        >
          +
        </button>
        <button
          type="button"
          ref={refButtonPinchIn}
          onClick={() => {
            if (scale > minScale) {
              setScale(scale - stepScale);
            }
          }}
        >
          -
        </button>
      </div>
      <div className={styles['pdf__wrapper']} data-testid="pdfWrapper">
        <Gestures
          recognizers={{
            Swipe: {
              events: {
                swipeleft: () => {
                  if (refButtonNext && refButtonNext.current) {
                    refButtonNext.current.click();
                  }
                },
                swiperight: () => {
                  if (refButtonPrev && refButtonPrev.current) {
                    refButtonPrev.current.click();
                  }
                },
              },
            },
            Pinch: {
              events: {
                pinchin: () => {
                  console.log('pinch in');
                  if (refButtonPinchIn && refButtonPinchIn.current) {
                    refButtonPinchIn.current.click();
                  }
                },
                pinchout: () => {
                  console.log('pinch out');
                  if (refButtonPinchOut && refButtonPinchOut.current) {
                    refButtonPinchOut.current.click();
                  }
                },
              },
            },
          }}
        >
          <PDFViewer
            document={{
              url: source,
            }}
            scale={scale}
            hideNavbar={false}
            css={styles['canvas_wrapper']}
            navigation={({
              page,
              pages,
              handlePrevClick,
              handleNextClick,
            }: {
              page: number;
              pages: number;
              handlePrevClick: () => void;
              handleNextClick: () => void;
            }) => {
              return (
                <>
                  <div className={styles['pdf_pageNumber']}>{`${page} / ${pages}`}</div>
                  <div className={styles['pdf_navigation']}>
                    <button
                      ref={refButtonPrev}
                      disabled={page === 1}
                      className={styles['left']}
                      //onClick={handlePrevClick}
                      onClick={() => {
                        handlePrevClick();
                      }}
                    >
                      <img src={arrowRightwhite} alt="arrow" />
                    </button>
                    <button
                      ref={refButtonNext}
                      disabled={page === pages}
                      className={styles['right']}
                      // onClick={handleNextClick}
                      onClick={() => {
                        handleNextClick();
                      }}
                    >
                      <img src={arrowRightwhite} alt="arrow" />
                    </button>
                  </div>
                </>
              );
            }}
            loader={
              <div className={styles['loader_wrapper']}>
                <img
                  src={loaderIcon}
                  alt="loader"
                  style={{ width: '35px', display: 'inline-block', margin: '0 auto ' }}
                />
              </div>
            }
          />
        </Gestures>
      </div>
    </>
  );
}

export default Pdf;
