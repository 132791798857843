/* eslint-disable @typescript-eslint/no-explicit-any*/
/* eslint-disable @typescript-eslint/no-unused-vars*/
import { Promise } from 'bluebird';

import store from 'src/Redux/store';
import { syncLoadingActions } from 'src/Redux/SyncLoading/Slice';
import { syncActions } from 'src/Redux/Sync/Slice';

import { ICxp, IFile, IPresentation } from 'src/db/Types';
import { db } from 'src/db';
import { NewISlideFile } from 'src/db/Types';
import * as _ from 'lodash';

import {
  startUpdate,
  endUpdate,
  loadInfos,
  loadCexps,
  loadPresentations,
  loadDocuments,
  loadFile,
  loadTemplates,
  loadSheets,
  downloadFileWithBlob,
} from './api';

/**
 * Steps
 */
export const steps = (step: number, count: number) => {
  const valueStep = (step / count) * 100;
  store.dispatch(syncLoadingActions.setSync({ value: parseInt(valueStep.toFixed(0)) }));
};
/*
 * 1. Start update
 */
export const startUpdateEvent = () => {
  return new Promise((resolve, reject) => {
    startUpdate()
      ?.then(_ => {
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
};
/*
 * 2. Load and then insert Cexps
 */
export const insertCxpsMetaData = () => {
  return new Promise((resolve, reject) => {
    loadCexps()
      ?.then(async res => {
        const { data: cexps } = res;
        // clear table
        await db.cexps?.clear();
        // Insert data
        db.cexps?.bulkAdd(cexps);
        resolve(cexps);
      })
      .catch(error => {
        reject(error);
      });
  });
};
/*
 * 3. Load User infos
 */
export const insertInfosUserMetaData = () => {
  return new Promise((resolve, reject) => {
    loadInfos()
      ?.then(async res => {
        const { data: infos } = res;
        // clear table
        await db.user?.clear();
        // get Cexps data
        const cexps: Array<ICxp> | undefined = await db.cexps?.toArray();
        // insert data default cxp
        const defaultCxps: Array<ICxp> = [];
        _.forEach(infos.defaultCexps, dcxp => {
          _.forEach(cexps, cxp => {
            if (cxp._id === dcxp) {
              defaultCxps.push(cxp);
            }
          });
        });
        // insert data user cxp
        const userCxps: Array<ICxp> = [];
        _.forEach(infos.userCexps, ucxp => {
          _.forEach(cexps, cxp => {
            if (cxp._id === ucxp) {
              userCxps.push(cxp);
            }
          });
        });
        const user = await db.user?.add({
          ...infos,
          defaultCexps: defaultCxps,
          userCexps: userCxps,
        });
        resolve(user);
      })
      .catch(error => {
        reject(error);
      });
  });
};
/*
 * 4. Presentations
 */
export const insertPresentationsMetaData = () => {
  return new Promise((resolve, reject) => {
    loadPresentations()
      ?.then(async res => {
        const { data: prezs } = res;
        // clear data
        await db.presentations?.clear();
        await db.slides?.clear();
        // get cexps data
        const cexps: Array<ICxp> | undefined = await db.cexps?.toArray();
        if (!cexps) {
          return;
        }
        // insert data
        interface IPrz {
          _id: string;
          updatedAt: string;
          createdAt: string;
          published: boolean;
          title: string;
          ref: string;
          description: string;
          files: Array<IFile>;
          slides: Array<string>;
          cexps: Array<string>;
        }

        const newLisPrez: Array<IPresentation> = _.map(prezs.presentations, (prez: IPrz) => {
          const listCxps: ICxp[] = [];
          _.forEach(cexps, cxp => {
            if (_.includes(prez.cexps, cxp._id)) {
              listCxps.push(cxp);
            }
          });
          return {
            ...prez,
            cexps: listCxps,
          };
        });
        await db.presentations?.bulkAdd(newLisPrez);

        // add Slides
        await db.slides?.bulkAdd(prezs.slides);
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
};
/**
 * Templates
 */
export const insertTemplatesMetaData = () => {
  return new Promise((resolve, reject) => {
    loadTemplates()
      ?.then(async res => {
        const { data: templates } = res;
        // clear table
        await db.templates?.clear();
        // add templates
        await db.templates?.bulkAdd(templates);
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
};
/**
 * Sheets
 */
export const insertSheetsMetaData = () => {
  return new Promise((resolve, reject) => {
    loadSheets()
      ?.then(async res => {
        const { data: sheets } = res;
        // clear table
        await db.sheets?.clear();
        // add sheets
        await db.sheets?.bulkAdd(sheets);
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
};
/*
 * 5. Docuemnts
 */
export const insertDocumentsMetaData = () => {
  return new Promise((resolve, reject) => {
    loadDocuments()
      ?.then(async res => {
        const { data: documents } = res;
        // clear tables
        await db.categories?.clear();
        await db.documents?.clear();
        await db.bindings?.clear();
        // add categories
        await db.categories?.bulkAdd(documents.categories);
        // add documents
        await db.documents?.bulkAdd(documents.documents);
        // add bindings
        await db.bindings?.bulkAdd(documents.bindings);
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
};
/*
 * End update
 */
export const endUpdateEvent = () => {
  return new Promise((resolve, reject) => {
    endUpdate()
      ?.then(_ => {
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
};

/**
 * Donwload file
 */

export const loadFileWithMetaData = (file: IFile) => {
  return new Promise(resolve => {
    loadFile(file.uid)
      ?.then(res => {
        const { data: base64 } = res;
        if (base64) {
          file.file = base64;
          if (file) {
            db.files
              ?.add({
                _id: file._id,
                uid: file.uid,
                mimetype: file.mimetype,
                updatedAt: file.updatedAt,
                createdAt: file.createdAt,
                size: file.size,
                file: file.file,
                filename: file.filename,
              })
              .then(_ => {
                resolve(file);
              });
          }
        } else {
          resolve(false);
          //reject('Error load file');
        }
      })
      .catch(_ => {
        resolve(false);
        //reject(error);
      });
  });
};

/**
 * load Files Data
 */

export async function loadFilesData() {
  store.dispatch(syncActions.syncEndWithError({ syncEndWithError: true }));
  store.dispatch(syncLoadingActions.updateStepSync({ step: 2 }));
  store.dispatch(syncLoadingActions.resetNumbersSync());
  store.dispatch(syncLoadingActions.setSync({ value: 0 }));
  // 1 get slides/files/presentations from indexeddb
  const presentationsTable = await db.presentations?.toArray();
  const slidesTable = await db.slides?.toArray();
  const documentsTable = await db.documents?.toArray();
  // 2 get Ids slides
  const idsFilesSlides: Array<string> = [];
  _.forEach(slidesTable, slide => {
    const file = slide.zipFile;
    if (!_.includes(idsFilesSlides, file._id)) {
      idsFilesSlides.push(file._id);
    }
  });
  // 3 get Ids files prezs
  const idsFilesPrez: Array<string> = [];
  _.forEach(presentationsTable, slide => {
    const files = slide.files;
    if (files.length > 0) {
      _.forEach(files, file => {
        if (!_.includes(idsFilesPrez, file._id)) {
          idsFilesPrez.push(file._id);
        }
      });
    }
  });
  // 4 get ids files documents
  const idsFilesDocuments: Array<string> = [];
  _.forEach(documentsTable, document => {
    const file = document.file;
    if ('_id' in file) {
      if (!_.includes(idsFilesDocuments, file._id)) {
        idsFilesDocuments.push(file._id);
      }
    }
  });

  // 4 merge tow table
  const filesToDownloadAll = [...idsFilesPrez, ...idsFilesSlides, ...idsFilesDocuments];
  console.log('To Download All: ', filesToDownloadAll);
  // 5 find element to be deleted
  const filesIDs: Array<string> = [];
  await db.files?.each(item => filesIDs.push(item._id));
  const toDeleted = _.difference(filesIDs, filesToDownloadAll);
  console.log('To Deleted: ', toDeleted);
  // 6 find file to download
  const toDownload = _.difference(filesToDownloadAll, filesIDs).filter(
    item => item !== '600022b3b9c1d606f18debc6'
  );
  console.log('To Download: ', toDownload);

  // 7 delete unnecessary files
  if (toDeleted.length > 0) {
    await db.files?.where('_id').anyOf(toDeleted).delete();
  }
  // 8 download necessary files
  const filesToDownload: Array<IFile> = [];
  if (toDownload.length > 0) {
    store.dispatch(
      syncLoadingActions.numberFilesToDownload({ numberFilesToDownload: toDownload.length })
    );
    // 8.1 get meta data files
    if (presentationsTable && presentationsTable.length > 0) {
      _.forEach(presentationsTable, prez => {
        if (prez.files && prez.files.length > 0) {
          _.forEach(prez.files, file => {
            if (_.includes(toDownload, file._id)) {
              filesToDownload.push(file);
            }
          });
        }
      });
    }
    // 8.2 get meta data from slides
    if (slidesTable && slidesTable.length > 0) {
      _.forEach(slidesTable, slide => {
        if (slide.zipFile) {
          if (_.includes(toDownload, slide.zipFile._id)) {
            filesToDownload.push(slide.zipFile);
          }
        }
      });
    }
    // 8.3 get meta data from documnts
    if (documentsTable && documentsTable.length > 0) {
      _.forEach(documentsTable, doc => {
        if (_.includes(toDownload, doc.file._id)) {
          filesToDownload.push(doc.file);
        }
      });
    }
  } else {
    steps(10, 10);
  }
  // 8.2 download files
  let counter = 0;
  const countFileToDownload = filesToDownload.length;
  const concurrency = 3;
  await Promise.map(
    filesToDownload,
    function (file: IFile, index, length) {
      steps(counter, length);
      return new Promise(resolve => {
        loadFileWithMetaData(file)
          .then(file => {
            if (file && _.isObject(file)) {
              console.log('Load File: with succes ');
              store.dispatch(syncLoadingActions.numberFilesSuccess());
              counter++;
              resolve(true);
            } else {
              console.log('Load File: with error ');
              resolve(false);
            }
          })
          .catch(error => {
            resolve(false);
            store.dispatch(syncLoadingActions.numberFilesFailed());
            console.log('Load file error:', error);
          });
      });
    },
    { concurrency: concurrency }
  );
  // 9 get count files
  return new Promise(resolve => {
    //store.dispatch(syncLoadingActions.setLoading({ loading: false })); //enable this line if useMD5 is false
    if (countFileToDownload === 0 || counter === countFileToDownload) {
      const now = new Date().toISOString();
      store.dispatch(syncActions.setLastSync({ lastSync: now, syncEndWithError: false }));
      resolve(true);
    } else {
      store.dispatch(syncActions.syncEndWithError({ syncEndWithError: true }));
      resolve(true);
    }
  });
}

export const downloadFileWithBLOB = (md5: string) => {
  const blob2Base64 = (blob: Blob): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result?.toString());
      reader.onerror = error => reject(error);
    });
  };

  //* - version md5blob
  return new Promise(resolve => {
    downloadFileWithBlob(md5)
      ?.then(response => {
        if (response) {
          const blob = new Blob([response.data], { type: 'application/octet-stream' });
          db.md5file
            ?.add({
              md5: md5,
              content: '', //base64,
              blob: blob,
              ref: 0,
            })
            .then(_ => {
              resolve('base64');
            })
            .catch(err => {
              console.log(err);
              resolve(false);
            });
        } else {
          resolve(false);
          //reject('Error load file');
        }
      })
      .catch(_ => {
        resolve(false);
        //reject(error);
      });
  });
  //version md5blob*/
};

/**
 * load Common Files Data
 */
export async function loadCommonFiles() {
  store.dispatch(syncActions.syncEndWithError({ syncEndWithError: true }));
  store.dispatch(syncLoadingActions.updateStepSync({ step: 3 }));
  store.dispatch(syncLoadingActions.resetNumbersSync());
  store.dispatch(syncLoadingActions.setSync({ value: 0 }));

  let counter = 0;

  // <md5

  const md5UsedList: string[] = []; //filesToDownloadAll
  const md5DownloadedList: string[] = []; //filesIDs
  let md5ToDownloadList: string[] = [];
  let md5ToDeleteList: string[] = [];

  await db.files?.each(item => {
    const sss: any = item.file;
    const ttt: NewISlideFile = sss as NewISlideFile;
    if (ttt) {
      for (let x = 0; x < ttt.md5All.length; x++) {
        if (!_.includes(md5UsedList, ttt.md5All[x])) {
          md5UsedList.push(ttt.md5All[x]);
        }
      }
    }
  });

  console.log('MD5 To All referenced file: ', md5UsedList);

  await db.md5file?.each(item => md5DownloadedList.push(item.md5));

  md5ToDeleteList = _.difference(md5DownloadedList, md5UsedList);

  console.log('MD5 To Deleted: ', md5ToDeleteList);
  // 6 find file to download
  md5ToDownloadList = _.difference(md5UsedList, md5DownloadedList);
  console.log('MD5 To Download: ', md5ToDownloadList);

  // 7 delete unnecessary files
  if (md5ToDeleteList.length > 0) {
    await db.md5file?.where('md5').anyOf(md5ToDeleteList).delete();
  }

  const concurrency = 3;

  const filesToDownload = md5ToDownloadList;

  const countFileToDownload = filesToDownload.length;

  store.dispatch(
    syncLoadingActions.numberFilesToDownload({ numberFilesToDownload: filesToDownload.length })
  );
  await Promise.map(
    filesToDownload,
    function (md5: string, index, length) {
      steps(counter, length);
      return new Promise(resolve => {
        downloadFileWithBLOB(md5)
          .then(base64 => {
            console.log('Load File with BLOB : ', md5);
            if (base64) {
              store.dispatch(syncLoadingActions.numberFilesSuccess());
              counter++;
            }
            resolve(true);
          })
          .catch(error => {
            resolve(true);
            store.dispatch(syncLoadingActions.numberFilesFailed());
            console.log('Load File with BLOB error:', error);
          });
      });
    },
    { concurrency: concurrency }
  );
  // 9 get count files
  return new Promise(resolve => {
    store.dispatch(syncLoadingActions.setLoading({ loading: false }));
    if (countFileToDownload === 0 || counter === countFileToDownload) {
      const now = new Date().toISOString();
      store.dispatch(syncActions.setLastSync({ lastSync: now, syncEndWithError: false }));
      resolve(true);
    } else {
      store.dispatch(syncActions.syncEndWithError({ syncEndWithError: true }));
      resolve(true);
    }
  });
}
