import React from 'react';
import { Auth0Provider } from './Auth0';
import { ReduxProvider } from './Redux';

const Providers = ({ children }: { children: React.ReactElement }) => (
  <Auth0Provider>
    <ReduxProvider>{children}</ReduxProvider>
  </Auth0Provider>
);

export default Providers;
