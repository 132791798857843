import { serviceOptions } from 'src/Services/API';
import { Endpoint } from 'src/Utils/constants';

// export const loadPuzzleToken = async () => {
//   if (res.status === 401) {
//     console.log('rest 404');
//     //logout();
//     // history.push('/');
//   }
//   const result = await res.json();
//   setToken(result.token);
//   setupAPI();
// };

export const loadInfos = () => {
  return serviceOptions.axios?.get(`${Endpoint.userInfo}`);
};

export const loadCexps = () => {
  return serviceOptions.axios?.get(`${Endpoint.cxps}`);
};

export const loadPresentations = () => {
  return serviceOptions.axios?.get(`${Endpoint.presentation}`);
};

export const loadSlide = (id: string) => {
  return serviceOptions.axios?.get(`${Endpoint.slide}/${id}`);
};

export const loadFile = (uid: string) => {
  return serviceOptions.axios?.get(`${Endpoint.loadFile}/${uid}`);
};
export const loadFiles = (uids: Array<string>) => {
  return serviceOptions.axios?.post(`${Endpoint.loadFiles}`, {
    file_uids: uids,
  });
};

export const downloadFileWithBlob = (md5: string) => {
  return serviceOptions.axios?.get(`${Endpoint.downloadFileWithBlob}/${md5}`, {
    responseType: 'blob',
  });
};

export const loadDocuments = () => {
  return serviceOptions.axios?.get(`${Endpoint.doc}`);
};

export const startUpdate = () => {
  return serviceOptions.axios?.post(`${Endpoint.startSync}`);
};
export const endUpdate = () => {
  return serviceOptions.axios?.post(`${Endpoint.endSync}`);
};

export const updateCexpsUser = (cexps: Array<string>) => {
  return serviceOptions.axios?.post(`${Endpoint.updateCexpsUser}`, cexps);
};

export const loadTemplates = () => {
  return serviceOptions.axios?.get(`${Endpoint.loadTemplates}`);
};

export const loadSheets = () => {
  return serviceOptions.axios?.get(`${Endpoint.getSheet}`);
};
