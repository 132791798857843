/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client, {
  Auth0ClientOptions,
  Auth0Client,
  PopupLoginOptions,
  RedirectLoginResult,
  getIdTokenClaimsOptions,
  IdToken,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
} from '@auth0/auth0-spa-js';

interface Auth0Context {
  isAuthenticated: boolean;
  user: Auth0RandstadUser | undefined;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup(options: PopupLoginOptions): Promise<void>;
  handleRedirectCallback(): Promise<RedirectLoginResult>;
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>;
  loginWithRedirect(o: RedirectLoginOptions): Promise<void>;
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>;
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>;
  logout(o?: LogoutOptions): void;
}
interface Auth0ProviderOptions {
  children: React.ReactElement;
  onRedirectCallback?(result: RedirectLoginResult): void;
}
interface Auth0RandstadUser {
  given_name: string;
  family_name: string;
  email: string;
  profile: { id: string; userName: string };
}

let _initOptions: Auth0ClientOptions;

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

// export let getTokenSilently: () => Promise<string>;
// export let logout: () => void;
// export let randstadUser: Auth0RandstadUser;

const getAuth0Client = (): Promise<Auth0Client> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let client;
    if (!client) {
      try {
        client = await createAuth0Client(_initOptions);
        resolve(client);
      } catch (e) {
        reject(new Error('getAuth0Client Error'));
      }
    }
  });
};
export const getTokenSilently = async (options?: GetTokenSilentlyOptions) => {
  const client = await getAuth0Client();
  return await client?.getTokenSilently(options);
};
export const logout = async () => {
  const client = await getAuth0Client();
  return await client?.logout({
    returnTo: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}`,
  });
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Auth0Context = React.createContext<Auth0Context | null>(null);

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ClientOptions & Auth0ProviderOptions) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<Auth0RandstadUser>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  // throwing inside useEffect won't be caught by Error Boundaries, hence the useState
  // see https://github.com/facebook/react/issues/14981#issuecomment-468460187 for more details
  const [_, setError] = useState();

  useEffect(() => {
    const initAuth0 = async () => {
      _initOptions = initOptions;
      try {
        const auth0FromHook = await createAuth0Client(initOptions as Auth0ClientOptions);
        setAuth0(auth0FromHook);

        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }

        const isAuthenticated = await auth0FromHook.isAuthenticated();

        setIsAuthenticated(isAuthenticated);

        if (isAuthenticated) {
          const user = await auth0FromHook.getUser();
          setUser(user);
        }

        setLoading(false);
      } catch (err) {
        setError(() => {
          throw err;
        });
      }
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client!.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client!.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    const result = await auth0Client!.handleRedirectCallback();
    const user = await auth0Client!.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
    return result;
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client!.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client!.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client!.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client!.getTokenWithPopup(...p),
        logout: (...p) => auth0Client!.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
