import React from 'react';
import { useAuth0 } from 'src/Providers/Auth0';

import styles from './Connexion.module.scss';
import logoPuzzle from 'src/Assets/icons/connexion/logo-puzzle.svg';
import logoRandstad from 'src/Assets/connexion/logo-r-white.svg';
import { HISTORY_BASENAME } from 'src/Utils/history';

function Connexion() {
  console.log('env.NODE_ENV:', process.env.NODE_ENV);
  console.log('env.REACT_APP_GIT_HASH:', process.env.REACT_APP_GIT_HASH);
  console.log('env.REACT_APP_HOMEPAGE:', process.env.REACT_APP_HOMEPAGE);
  console.log('env.PUBLIC_URL:', process.env.PUBLIC_URL);
  console.log('env.REACT_APP_PUBLIC_URL:', process.env.REACT_APP_PUBLIC_URL);
  console.log('env.REACT_APP_API_URL:', process.env.REACT_APP_API_URL);
  console.log('HISTORY_BASENAME:', HISTORY_BASENAME);

  // eslint-disable-next-line
  const { loginWithRedirect } = useAuth0()!;
  return (
    <div className={styles['intro']}>
      <div className={styles['card']}>
        <img src={logoPuzzle} className={styles['logo-puzzle']} alt="logo-app" />
        <p className={styles['text-1']}>
          Bienvenue <br />
          sur l’application puzzle
        </p>
        <p className={styles['text-2']}>votre partenaire pour tous vos rendez-vous clients !</p>
        <img src={logoRandstad} alt="logo-randstad" className={styles['logo-randstad']} />
        <button
          onClick={() =>
            loginWithRedirect({
              appState: { targetUrl: window.location.pathname },
            })
          }
        >
          se connecter
        </button>
      </div>
    </div>
  );
}
export default Connexion;
