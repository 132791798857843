import React, { useRef, useCallback, useEffect } from 'react';
import { IModalProps } from './Modal.types';

import styles from './Modal.module.scss';

const Modal = ({ onClose, children }: IModalProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape') {
        onClose(e);
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => {
      document.removeEventListener('keydown', keyPress);
    };
  }, [keyPress]);

  function handleCloseModal(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (modalRef !== null && modalRef.current && modalRef.current === e.target) {
      onClose(e);
    } else {
      return;
    }
  }

  return (
    <div
      className={styles['m-wrapper']}
      ref={modalRef}
      onClick={e => handleCloseModal(e)}
      data-testid="modalWrapper"
    >
      {children}
    </div>
  );
};
export default Modal;
