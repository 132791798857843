import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styles from './ToastUpdate.module.scss';
interface IToast {
  message: string;
  goToSync: () => void;
}
function ToastUpdate({ message, goToSync }: IToast) {
  const [closed, setClosed] = useState(false);
  const [show, setShow] = useState(false);
  useEffect(() => {
    checkShowAlert();
  }, []);

  function checkShowAlert() {
    const expireAfter = 5;
    const expireTimeStorage = localStorage.getItem('puzzle-update-toast');
    const now = moment(new Date().toISOString());
    if (expireTimeStorage) {
      const expireTime = moment(expireTimeStorage);
      if (now > expireTime) {
        setShow(true);
      }
    } else {
      setShow(true);
      localStorage.setItem('puzzle-update-toast', now.add(expireAfter, 'minutes').toISOString());
    }
  }
  const handleCancel = () => {
    const alertUpdateTime = parseInt(`${process.env.REACT_APP_SHOW_ALERT_UPDATE_FREQUENCE}`);
    const expireTime = moment().add(alertUpdateTime, 'minutes').toISOString();
    localStorage.setItem('puzzle-update-toast', expireTime);
    setClosed(true);
  };
  const handleGoSync = () => {
    goToSync();
  };
  if (closed || !show) return null;

  return (
    <div className={styles['toast']}>
      <p
        className={styles['toast__message']}
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      ></p>
      <div className={styles['toast__cta']}>
        <button className={styles['toast__cta__link']} onClick={handleCancel}>
          pas maintenant
        </button>
        <button className={styles['toast__cta__link']} onClick={handleGoSync}>
          maintenant
        </button>
      </div>
    </div>
  );
}

export default ToastUpdate;
