/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef, useState } from 'react';
import * as _ from 'lodash';

import { ICentresExperts } from './CentresExperts.types';

import { placeholderSelect, emptyListCexp } from 'src/Utils/constants';
import history from 'src/Utils/history';

import Offline from 'src/Components/Offline';
import DropdownSearch from 'src/Components/DropdownSearch';

import loaderIcon from 'src/Assets/loader_blue.gif';
import closeIcon from 'src/Assets/cxps/close.svg';
import closeWhiteIcon from 'src/Assets/cxps/closeWhite.svg';

import styles from './CentresExperts.module.scss';

function CentresExperts({
  deleteUserCxp,
  onChangeSelect,
  selectCxpList,
  cxpState,
  cexpsDefaultTickets,
  userCexpsTickets,
  handleSave,
}: ICentresExperts) {
  const refTags = useRef<HTMLDivElement | null>(null);
  const [userChanged, setuserChanged] = useState<boolean>(false);

  function handleChangeCxp() {
    if (!userChanged) {
      setuserChanged(true);
    }
  }
  function renderButton() {
    return (
      <React.Fragment>
        <ul className={styles['button__left']}>
          <li className={styles['back']}>
            <button
              onClick={() => {
                if (userChanged) {
                  handleSave();
                } else {
                  history.push('/');
                }
              }}
            >
              <img src={closeWhiteIcon} alt="close" />
            </button>
          </li>
          {/* <li className={styles['back-save']}>
            <button onClick={handleSave}>Sauvegarder</button>
          </li> */}
        </ul>
      </React.Fragment>
    );
  }
  const renderItemsCexps = () => {
    return (
      <React.Fragment>
        <div className={styles['select']}>
          <DropdownSearch
            title={placeholderSelect}
            list={selectCxpList}
            onchange={(selectedOption: { value: string; label: string }) => {
              onChangeSelect(selectedOption);
              handleChangeCxp();
              refTags.current?.scrollTo({
                top: refTags.current.scrollHeight,
              });
            }}
            emptyListCexp={emptyListCexp}
          />
        </div>

        <span className={styles['text']}>
          mes centres experts ({cxpState.userCxpNb + cxpState.defaultCxpNb})
        </span>
        <div className={styles['tags']} ref={refTags}>
          {cexpsDefaultTickets.map((item, index) => (
            <div className={styles['item']} key={index}>
              <div className={styles['item-delete-without-close']}>
                <div className={styles['item-text']}>{item.value}</div>
              </div>
            </div>
          ))}
          {userCexpsTickets.map((item, index) => (
            <div className={styles['item']} key={index}>
              <div className={styles['item-delete']}>
                <div className={styles['item-text']}>{item.value}</div>
                <div>
                  <img
                    onClick={() => {
                      deleteUserCxp(item.id);
                      handleChangeCxp();
                    }}
                    className={styles['icon-item-delete']}
                    src={closeIcon}
                    alt="suprimer"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  };
  if (!window.navigator.onLine) {
    return <Offline />;
  }

  return (
    <div className={styles['cxp']}>
      {cxpState.loading ? (
        <div className={styles['loaderCxp']}>
          <img src={loaderIcon} alt="loader" className={styles['loaderIcon']} />
        </div>
      ) : (
        <React.Fragment>
          <div className={styles['cxp_container']}>
            {renderButton()}
            <h1 className={styles['title-cxp']}>Gérer les Centres Experts</h1>
            <div className={styles['container']}>
              <div className={styles['bloc']}>
                <span className={styles['text']}>
                  nombre des centres experts disponible ({cxpState.selectCxpNb})
                </span>
                {renderItemsCexps()}
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default CentresExperts;
