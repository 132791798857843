import { CSSProperties } from 'react';

export interface Props {
  trigger?: JSX.Element | ((isOpen: boolean) => JSX.Element);
  title: string;
  titleDefaultButton?: string;
  message: React.ReactNode;
  icon: React.ReactElement;
  defaultOpen?: boolean;
  waitingMode?: boolean;
  onClickAction: () => void;
  onClose: () => void;
  contentStyle?: CSSProperties | undefined;
}

export const defaultProps = {
  defaultOpen: false,
  waitingMode: false,
  titleDefaultButton: 'valider',
};
