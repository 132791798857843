import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { presentationState } from 'src/Containers/Sync/Types';

const initialState: presentationState = {
  presentations: [],
  slides: [],
};
export const { reducer: presentationReducer, actions: presentationActions } = createSlice({
  name: 'presentation',
  initialState,
  reducers: {
    setDataPresentations: (state: presentationState, action: PayloadAction<presentationState>) => {
      state.presentations = action.payload.presentations;
      state.slides = action.payload.slides;
    },
  },
  extraReducers: {
    // [loadPresentation.fulfilled.toString()]: (
    //   state: presentationState,
    //   action: PayloadAction<presentationState>
    // ) => {
    //   const { presentations, slides } = action.payload;
    //   state.presentations = presentations;
    //   state.slides = slides;
    // },
  },
});
export default presentationReducer;
